import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
const useErrorHandler = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const customUrl = useRef<string>("");
	const errorHandler = (status: number, message: string, url: string) => {
		if (
			location.pathname !== "/login" &&
			location.pathname + location?.search !== "/orders"
		) {
			customUrl.current = location.pathname + location?.search;
		}
		if (status === 403) {
			return `Unauthorized access.`;
		} else if (status === 400 || status === 500) {
			return `${message}`;
		} else if (status === 401) {
			if (!url.endsWith("/users/current") && !url.endsWith("/login")) {
				window.location.reload();
			}
			navigate("/login", { state: { destinationPath: customUrl.current } });
			return `Not logged in.`;
		} else if (status >= 400) {
			return `Unknown error.`;
		}
	};
	return errorHandler;
};
export default useErrorHandler;
