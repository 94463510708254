import React, { useState, useContext, useMemo, useEffect } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import GlobalDispatch from "Dispatches/GlobalDispatch";

import usePageTitle from "Hooks/pageTitleHook";
import ChartComponent from "Components/Shared/ChartComponent";
import "react-datepicker/dist/react-datepicker.css";
import useDataApi from "Hooks/fetchHook";
import Loading from "Components/Shared/Loading";
import { monthsBackFrom } from "Utils/utils";

const DppStatistic: React.FunctionComponent = () => {
	const {
		user: { brand_id: brandId },
	} = useContext(GlobalDispatch);

	const useGetStatistic = useDataApi();
	const [dppStatistic, setDppStatistic] = useState<any>(null);

	usePageTitle("Dpp statistics");

	/* *************************************************************************** */

	useEffect(() => {
		const data = { brandId };
		useGetStatistic.doFetch("/dpp/stats", data, "POST");
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetStatistic;
		if (error) {
			toast.error(`Unable to get statistics. ${error}`);
		}
	}, [useGetStatistic.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetStatistic;
		if (data.status === "ok") {
			setDppStatistic(data.message);
		}
	}, [useGetStatistic.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */

	const [firstMonth, setFirstMonth] = useState<Date | null>(
		monthsBackFrom(new Date(), 2)
	);
	const [lastMonth, setLastMonth] = useState<Date | null>(new Date());

	const sortDataInDescendingOrder = (data: any) => {
		// Step 1: Convert the object to an array of entries
		const entriesArray: [string, number][] = Object.entries(data);

		// Step 2: Create a Map from the entries
		const map = new Map(entriesArray);

		// Step 3: Sort the Map by values
		const sortedEntries = Array.from(map.entries()).sort(
			(a: any, b: any) => b[1] - a[1]
		); // Sort in descending order

		// Step 4: Create a new Map from the sorted entries (optional)
		const sortedMap = new Map(sortedEntries);

		return sortedMap;
	};

	const mergeDataAfterCertainNumberOfItems = (
		map: Map<string, number>,
		itemsLimit: number,
		itemName: string
	) => {
		// Check if the Map has more than itemsLimit items
		if (map.size > itemsLimit) {
			let otherItemsSum = 0;
			const keysToRemove: string[] = []; // Array to store keys to remove

			// Iterate over the Map entries
			let index = 0;
			for (const [key, value] of map) {
				if (index < itemsLimit) {
					// Keep the first itemsLimit items
					index++;
				} else {
					// Sum the values of the remaining items
					otherItemsSum += value;
					keysToRemove.push(key); // Store the key for removal
				}
			}

			// Remove the keys for the other items
			for (const key of keysToRemove) {
				map.delete(key);
			}

			// Add the sum of other items to the Map
			map.set(itemName, otherItemsSum);
		}

		// Return the mutated Map
		return map;
	};

	const handleMonthChange = ([newFirstMonth, newLastMonth]: [
		Date | null,
		Date | null
	]) => {
		if (newFirstMonth) {
			setFirstMonth(newFirstMonth);
		} else {
			setFirstMonth(null);
		}
		if (newLastMonth) {
			setLastMonth(newLastMonth);
		} else {
			setLastMonth(null);
		}
	};

	let filteredMonthlyCountsStatistic = useMemo(() => {
		if (firstMonth && lastMonth && dppStatistic) {
			let data = dppStatistic;

			// Define the start and end months
			let startYear = firstMonth.getFullYear();
			let startMonth = firstMonth.getMonth() + 1;
			let endYear = lastMonth.getFullYear();
			let endMonth = lastMonth.getMonth() + 1;

			// Prepare the structures for processed data
			let monthlyScans: any[] = [];
			let countryAggregates = {} as any;
			let platformsAggregates = {} as any;
			let browsersAggregates = {} as any;

			// Iterate through the months range
			for (let year = startYear; year <= endYear; year++) {
				let currentStartMonth = year === startYear ? startMonth : 1;
				let currentEndMonth = year === endYear ? endMonth : 12;

				for (let month = currentStartMonth; month <= currentEndMonth; month++) {
					const monthData = data[year]?.months[month];
					const monthName = new Date(year, month - 1).toLocaleString(
						"default",
						{
							month: "long",
						}
					);

					if (monthData) {
						// Add scans count for existing month
						monthlyScans.push({
							label: `${monthName} ${year}`,
							scans: monthData.numberOfScans,
						});

						// Aggregate country data
						for (let country in monthData.countries) {
							if (countryAggregates[country]) {
								countryAggregates[country] += monthData.countries[country];
							} else {
								countryAggregates[country] = monthData.countries[country];
							}
						}
						// Aggregate platform data
						for (let platform in monthData.platforms) {
							if (platformsAggregates[platform]) {
								platformsAggregates[platform] += monthData.platforms[platform];
							} else {
								platformsAggregates[platform] = monthData.platforms[platform];
							}
						}
						// Aggregate browser data
						for (let browser in monthData.browsers) {
							if (browsersAggregates[browser]) {
								browsersAggregates[browser] += monthData.browsers[browser];
							} else {
								browsersAggregates[browser] = monthData.browsers[browser];
							}
						}
					} else {
						// Add placeholder scans count data for missing month
						monthlyScans.push({
							label: `${monthName} ${year}`,
							scans: 0,
						});
					}
				}
			}

			let countriesAggregatesSorted = mergeDataAfterCertainNumberOfItems(
				sortDataInDescendingOrder(countryAggregates),
				9,
				"Other countries"
			);

			let platformsSortedInDescendingOrder =
				sortDataInDescendingOrder(platformsAggregates);

			let browsersSortedInDescendingOrder =
				sortDataInDescendingOrder(browsersAggregates);

			let filteredData = {
				monthlyScans,
				countries: {
					labels: Array.from(countriesAggregatesSorted.keys()),
					data: Array.from(countriesAggregatesSorted.values()),
				},
				platforms: {
					labels: Array.from(platformsSortedInDescendingOrder.keys()),
					data: Array.from(platformsSortedInDescendingOrder.values()),
				},
				browsers: {
					labels: Array.from(browsersSortedInDescendingOrder.keys()),
					data: Array.from(browsersSortedInDescendingOrder.values()),
				},
			};

			return filteredData;
		}
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	// Number of Scans chart data monthly

	let chartDataMonthlyScans = useMemo(() => {
		return {
			datasets: [
				{
					label: "Scans",
					data: filteredMonthlyCountsStatistic?.monthlyScans,
				},
			],
		};
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	let chartOptionMonthlyScans = useMemo(() => {
		return {
			maintainAspectRatio: false,
			scales: {
				y: {
					beginAtZero: true,
				},
			},
			parsing: {
				xAxisKey: "label",
				yAxisKey: "scans",
			},
			plugins: {
				title: {
					display: true,
					text: "Number of monthly scans",
					color: "#343434",
					font: {
						size: "20px",
						family: "Rubik",
						weight: 400,
					},
					padding: { bottom: 26, top: 15 },
				},
				legend: {
					display: false,
				},
			},
		};
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	// Locations chart data monthly

	let chartDataMonthlyLocations = useMemo(() => {
		if (firstMonth && lastMonth) {
			return {
				labels: filteredMonthlyCountsStatistic?.countries.labels,
				datasets: [
					{
						label: "Scans",
						data: filteredMonthlyCountsStatistic?.countries.data,
					},
				],
			};
		}
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	let chartOptionsMonthlyLocations = useMemo(() => {
		return {
			plugins: {
				title: {
					display: true,
					text: "Number of scans by country",
					color: "#343434",
					font: {
						size: "20px",
						family: "Rubik",
						weight: 400,
					},
					padding: { bottom: 10, top: 5 },
				},
			},
		};
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	// Platforms - chart data monthly

	let chartDataMonthlyPlatforms = useMemo(() => {
		if (firstMonth && lastMonth) {
			return {
				labels: filteredMonthlyCountsStatistic?.platforms.labels,
				datasets: [
					{
						label: "Scans",
						data: filteredMonthlyCountsStatistic?.platforms.data,
					},
				],
			};
		}
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	let chartOptionsMonthlyPlatforms = useMemo(() => {
		return {
			plugins: {
				title: {
					display: true,
					text: "Number of scans by OS",
					color: "#343434",
					font: {
						size: "20px",
						family: "Rubik",
						weight: 400,
					},
					padding: { bottom: 10, top: 5 },
				},
			},
		};
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	// Browsers - chart data monthly

	let chartDataMonthlyBrowsers = useMemo(() => {
		if (firstMonth && lastMonth) {
			return {
				labels: filteredMonthlyCountsStatistic?.browsers.labels,
				datasets: [
					{
						label: "Scans",
						data: filteredMonthlyCountsStatistic?.browsers.data,
					},
				],
			};
		}
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	let chartOptionsMonthlyBrowsers = useMemo(() => {
		return {
			plugins: {
				title: {
					display: true,
					text: "Number of scans by browser",
					color: "#343434",
					font: {
						size: "20px",
						family: "Rubik",
						weight: 400,
					},
					padding: { bottom: 10, top: 5 },
				},
			},
		};
	}, [firstMonth, lastMonth, dppStatistic]); // eslint-disable-line react-hooks/exhaustive-deps

	const setMonthsBack = (monthsBack: number) => {
		setFirstMonth(monthsBackFrom(new Date(), monthsBack));
		setLastMonth(new Date());
	};

	return (
		<>
			{useGetStatistic.isLoading && (
				<div className="flex flex-center-both-axis" style={{ height: "100vh" }}>
					<Loading show={true} text={"Loading..."} imgClass="" divClass="" />
				</div>
			)}

			{!useGetStatistic.isLoading &&
				dppStatistic &&
				Object.keys(dppStatistic).length === 0 && (
					<div
						className="flex flex-center-both-axis"
						style={{ height: "100vh" }}
					>
						No statistics
					</div>
				)}

			{!useGetStatistic.isLoading &&
				dppStatistic &&
				Object.keys(dppStatistic).length > 0 && (
					<div className="pd--base flex flex-column ">
						<header>
							<h1 className="section__title">DPP Statistics</h1>
						</header>
						<div className="flex">
							<DatePicker
								selected={firstMonth}
								onChange={handleMonthChange}
								selectsRange
								startDate={firstMonth === null ? undefined : firstMonth}
								endDate={lastMonth === null ? undefined : lastMonth}
								dateFormat="MMM/yyyy"
								showMonthYearPicker
								className="date-picker pd--xs"
							/>
							<div>
								<button
									className="button button--primary button--sm ml--md"
									onClick={() => {
										setMonthsBack(2);
									}}
								>
									Last 3 months
								</button>
								<button
									className="button button--primary button--sm ml--md"
									onClick={() => {
										setMonthsBack(5);
									}}
								>
									Last 6 months
								</button>
								<button
									className="button button--primary button--sm ml--md"
									onClick={() => {
										setMonthsBack(11);
									}}
								>
									Last 12 months
								</button>
							</div>
						</div>
						<div className="mt--base">
							<div>
								<ChartComponent
									type={"line"}
									data={chartDataMonthlyScans}
									options={chartOptionMonthlyScans}
									chartId="scans"
									wrapperStyle={{ height: "30vh" }}
								/>
							</div>
							<div
								className="flex mt--lg"
								style={{ justifyContent: "space-around" }}
							>
								<div className="mr--sm">
									<ChartComponent
										type="pie"
										data={chartDataMonthlyLocations}
										options={chartOptionsMonthlyLocations}
										chartId="locations"
										wrapperStyle={{ height: "40vh" }}
									/>
								</div>
								<div className="mr--sm">
									<ChartComponent
										type="pie"
										data={chartDataMonthlyPlatforms}
										options={chartOptionsMonthlyPlatforms}
										chartId="platforms"
										wrapperStyle={{ height: "40vh" }}
									/>
								</div>
								<div>
									<ChartComponent
										type="pie"
										data={chartDataMonthlyBrowsers}
										options={chartOptionsMonthlyBrowsers}
										chartId="browsers"
										wrapperStyle={{ height: "40vh" }}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
		</>
	);
};

export default DppStatistic;
