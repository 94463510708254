import { useEffect } from "react";

const useOnClickOutside = (ref: any, handler: Function) => {
	useEffect(() => {
		const listener = (event: any) => {
			// Do nothing if clicking ref's element or descendent elements
			if (
				!ref.current ||
				ref.current.contains(event.target) ||
				(event.target && event.target.id === "addLanguages")
			) {
				return;
			}

			handler(event);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler]);
};

export default useOnClickOutside;
