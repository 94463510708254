import { useEffect } from "react";

const useOnKeyUp = (eventKey: string, onKeyDownHandler: any) => {
	useEffect(() => {
		const listener = (event: { key: string }) => {
			if (event.key === eventKey) {
				onKeyDownHandler();
			}
		};

		document.addEventListener("keyup", listener);

		return () => {
			document.removeEventListener("keyup", listener);
		};
	}, [eventKey, onKeyDownHandler]);
};

export default useOnKeyUp;
