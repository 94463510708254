import { IOrderState, IUnit, DEFAULT_STYLE_NAME } from "Models/OrderModels";
import {
	checkIfAnySizeIsInvalid,
	checkIfFabricContentHasFurOrigin,
	checkIfFabricContentHasInsulationOrFeathers,
	deepCopy,
} from "./utils";

export const checkOrder = (initialValues: any, order: IOrderState) => {
	const itemsWithMissingInfo = {} as any;

	const { labelTemplate, lotNumber, orderItems } = order;

	const globalErrors = [];

	if (!lotNumber || lotNumber.length === 0) {
		globalErrors.push("reference number");
	}
	// Leave this commented for Shipping order Theory implementation
	// if (!factoryId && brandId && brandId === 1) {
	// 	globalErrors.push("factory");
	// }
	if (!orderItems || orderItems.length === 0) {
		globalErrors.push("styles");
	}

	if (globalErrors.length > 0) {
		itemsWithMissingInfo["globalErrors"] = globalErrors;
	}

	orderItems.map((item, index) => {
		const errorInfo = [];

		const {
			styleNumber,
			madeIn,
			fabricContent,
			units,
			extraInfo: orderItemExtraInfo,
			selectedAdditionalComponents,
			selectedCareInstructions,
		} = item;

		const unitsWithMissingSizes = !labelTemplate?.settings?.form_settings?.units
			?.hide_size
			? units.filter((unit: IUnit) => !unit.size || unit.size === "")
			: [];

		const sizes = labelTemplate?.additional_data?.data?.sizes;

		const invalidSize = checkIfAnySizeIsInvalid(sizes, units);
		const { minimum, step } =
			order?.labelTemplate.settings.form_settings.quantity;

		const unitWithInvalidQuantity = units.find(
			(unit) => unit.quantity < minimum || unit.quantity % step !== 0
		);

		const [insulationWeightExist, hasFeathers] =
			checkIfFabricContentHasInsulationOrFeathers(
				fabricContent,
				initialValues.fabricContent
			);

		const missingInsulationWeight =
			labelTemplate?.id === 1 &&
			insulationWeightExist &&
			!orderItemExtraInfo?.insulationWeight;

		const insulationNotValid =
			orderItemExtraInfo?.insulationWeight &&
			(orderItemExtraInfo?.insulationWeight > 10000 ||
				orderItemExtraInfo?.insulationWeight < 0);

		const missingSterilizationPermitNumber =
			labelTemplate?.id === 17 &&
			hasFeathers &&
			!orderItemExtraInfo?.sterilizationPermitNumber;

		const sterilizationPermitNumberNotValid =
			orderItemExtraInfo?.sterilizationPermitNumber &&
			(orderItemExtraInfo?.sterilizationPermitNumber.length === 0 ||
				orderItemExtraInfo?.sterilizationPermitNumber.length > 20);

		const missingFurOrigin =
			labelTemplate?.id === 1 &&
			checkIfFabricContentHasFurOrigin(
				fabricContent,
				selectedAdditionalComponents,
				initialValues?.fabricContent,
				initialValues?.additionalComponents
			) &&
			!orderItemExtraInfo?.furOrigin;

		if (!styleNumber) {
			errorInfo.push("style number");
		}
		if (!madeIn || madeIn === -1) {
			errorInfo.push("made in");
		}

		if (unitsWithMissingSizes.length > 0) {
			errorInfo.push("size");
		}
		if (invalidSize) {
			errorInfo.push("invalid size");
		}
		if (unitWithInvalidQuantity) {
			errorInfo.push("invalid quantity");
		}

		if (!fabricContent || fabricContent === -1) {
			errorInfo.push("content composition");
		}
		if (selectedCareInstructions.length === 0) {
			errorInfo.push("care instructions");
		}
		if (missingInsulationWeight) {
			errorInfo.push("insulation weight");
		}
		if (insulationNotValid) {
			errorInfo.push("insulation not valid");
		}
		if (missingSterilizationPermitNumber) {
			errorInfo.push("sterilization permit number");
		}
		if (sterilizationPermitNumberNotValid) {
			errorInfo.push("sterilization permit number not valid");
		}
		if (missingFurOrigin) {
			errorInfo.push("fur origin");
		}

		if (errorInfo.length > 0) {
			itemsWithMissingInfo[index] = {};
			itemsWithMissingInfo[index].styleNumber = styleNumber
				? styleNumber
				: DEFAULT_STYLE_NAME;

			itemsWithMissingInfo[index].errorInfo = errorInfo;
		}

		return undefined;
	});

	return itemsWithMissingInfo;
};

export const prepareErrorMessage = (itemsWithMissingInfo: any) => {
	let message = "";
	let indexOfFirstItemWithError = null;
	if (Object.keys(itemsWithMissingInfo).length > 0) {
		if (itemsWithMissingInfo["globalErrors"]?.length > 0) {
			message += `Missing order information: ${itemsWithMissingInfo[
				"globalErrors"
			].join(", ")}`;
		}

		let localItemsWithMissingInfo = deepCopy(itemsWithMissingInfo);
		delete localItemsWithMissingInfo.globalErrors;
		const keys = Object.keys(localItemsWithMissingInfo);

		if (keys.length > 0) {
			indexOfFirstItemWithError = parseInt(keys[0]);
			if (message.length > 0) {
				message += "\n\n";
			}
			message +=
				"Styles with missing information found. Check styles list for more details.";
			// :\n";
			// message += keys
			// 	.map((key) => {
			// 		if (key !== "globalErrors") {
			// 			return `${
			// 				itemsWithMissingInfo[key].styleNumber
			// 			}: missing ${itemsWithMissingInfo[key].errorInfo.join(", ")}`;
			// 		}
			// 		return "";
			// 	})
			// 	.join("\n");
		}
	}

	return { message, indexOfFirstItemWithError };
};
