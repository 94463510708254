import { useTranslation } from "react-i18next";

interface IProps {
	headerRef: any;
	scrollPadding: number;
}

const PrintBatchesListHeader = ({ headerRef, scrollPadding }: IProps) => {
	const { t } = useTranslation(); // Destructure t from useTranslation

	return (
		<section
			ref={headerRef}
			className={`listing-header listing-header--print-batches`}
			style={{
				paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
			}}
		>
			<span className="listing-header__label">{t("Reference number")}</span>

			<span className="listing-header__label">{t("Template name")}</span>

			<span className="listing-header__label">{t("Color / width")}</span>

			<span className="listing-header__label">
				{t("Total (print) quantity")}
			</span>

			<span className="listing-header__label ordersStatusListItem">
				{t("Status")}
			</span>
		</section>
	);
};

export default PrintBatchesListHeader;
