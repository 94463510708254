import Icon from "Components/Shared/Icon";
import React, { FC } from "react";

const NewAppVersionModal: FC<{}> = () => {
	return (
		<>
			<div className="modal flex-center-both-axis">
				<div
					className="modal-content-order-summary show flex-center-both-axis"
					style={{ height: "140px" }}
				>
					<div>
						You are using an older version of the app. Please refresh the
						browser to load the new version.
					</div>
					<div
						className="pill pill--outline-quiet pill--toolbar mt--sm"
						title="Refresh"
						onClick={() => {
							window.location.reload();
						}}
					>
						<div className="toolbarIconContainer">
							<Icon name="refresh" className="toolbarIcon" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewAppVersionModal;
