import React from "react";

import sprite from "images/icons.svg";

interface IProps {
	name: string;
	className?: string;
}

const Icon: React.FunctionComponent<IProps> = ({ name, className }) => {
	if (!className) {
		className = "icon";
	}

	return (
		<svg className={className}>
			<use xlinkHref={`${sprite}#icon-${name}`} />
		</svg>
	);
};

export default Icon;
