import { capitalize } from "Utils/utils";
import Icon from "../Shared/Icon";
import { Dispatch, SetStateAction } from "react";
import { PrinterStatuses } from "Models/OrderModels";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface IStateProps {
	printer: any;

	setShowDrawer: Dispatch<SetStateAction<boolean>>;
	setChosenPrinter: Dispatch<SetStateAction<any>>;
}

const PrintersListItem: React.FunctionComponent<IStateProps> = ({
	printer,

	setShowDrawer,
	setChosenPrinter,
}) => {
	const { t } = useTranslation(); // Destructure t from useTranslation

	let printerStatus;
	if (printer.status_code === PrinterStatuses.READY.code) {
		printerStatus = t("Ready");
	} else if (printer.status_code === PrinterStatuses.PRINTING.code) {
		printerStatus = t("Printing");
	} else if (printer.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code) {
		printerStatus = t("Error");
	}

	let printerStatusColor;
	if (printer.status_code === PrinterStatuses.READY.code) {
		printerStatusColor = "statusCompleted";
	} else if (printer.status_code === PrinterStatuses.PRINTING.code) {
		printerStatusColor = "statusPrinting";
	} else if (printer.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code) {
		printerStatusColor = "voidOrder";
	}

	return (
		<div style={{ borderBottom: "2px solid #f4f4f4" }}>
			<div>
				<article className={`list-item list-item--printers`}>
					<div className="list-item__details">{printer.name}</div>

					<div className="list-item__details">
						{printer.settings.double_sided ? t("Double sided") : t("One sided")}{" "}
					</div>
					<div className="list-item__details">
						<div className="truncate">
							{t("Material")}: {capitalize(printer.settings.paper.material)}
						</div>
						<div className="truncate">
							{t("Width")}: {printer.settings.paper.width} mm
						</div>
						<div className="truncate">
							{t("Color")}: {capitalize(printer.settings.paper.color)}
						</div>
					</div>
					<div className="list-item__details">
						<div className="truncate">
							{t("Name")}: {capitalize(printer.settings.ribbon.name)}
						</div>
						<div className="truncate">
							{t("Width")}: {printer.settings.ribbon.width} mm
						</div>
						<div className="truncate">
							{t("Color")}: {capitalize(printer.settings.ribbon.color)}
						</div>
					</div>

					<div className="mt--xs ">
						<div
							onClick={() => {
								setShowDrawer(true);
								setChosenPrinter(printer);
							}}
							className={`labelMakerSetDataButton ${
								printerStatus === t("Printing") && "printerEditDisabled"
							} `}
							role="button"
							title={t("Edit printer")}
						>
							<Icon name="cog" />
						</div>
					</div>
					<div
						className="list-item__details ordersStatusListItem"
						style={{ alignItems: "flex-end" }}
					>
						{
							<span
								className={`statusQuiet ${printerStatusColor}`}
								style={{ width: "135px" }}
							>
								{printerStatus}
							</span>
						}
					</div>
				</article>
			</div>
		</div>
	);
};

export default PrintersListItem;
