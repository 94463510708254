import { FC } from "react";

import newErrorReporting from "./new-error-reporting.png";
import newErrorReportingContent from "./new-error-reporting-content.png";
import newSymbols from "./new-symbols.png";
import symbolsEditor from "./symbols-editor.png";

const ReleaseNov24: FC = () => {
	return (
		<>
			<article className="helpArticle">
				<h4>
					Updated import style flow{" "}
					<a href="/help/videos/1/import-styles-using-excel">▶️</a>
				</h4>
				<img
					src={newErrorReporting}
					className="helpImageSmall"
					alt="New error reporting"
				/>
				<p>
					When you have an error importing a style, you will now have a more
					clear view with error information in the first part, invalid value in
					the second, and, when applicable, suggested alternatives in the third
					part. All you need to do is click on one of the suggested values to
					use that value instead.
				</p>
				<p>
					This will make it easier to fix the error and import the style, you
					won't have to go back, edit the file, and then re-upload it.
				</p>
				<hr />

				<h4>
					Using AI to correct content format errors
					<a href="/help/videos/1/import-styles-using-excel"> ▶️</a>
				</h4>
				<img
					src={newErrorReportingContent}
					className="helpImageSmall"
					alt="New error reporting content"
				/>
				<p>
					Format for content, involving commas, colons, etc, is sometimes hard
					to get right. Especially since it's often hard for us to know what is
					the problem exactly, so we can't give a precise error. Like in the
					above example, the error message is saying that there is an extra
					colon, but the actual problem is in the missing semicolon.
				</p>
				<p>
					We now added an AI layer that will help correct the content. If the AI
					manages to correct the format, you will see the corrected value as a
					suggestion which you can accept if it's what you need. Note that we
					will never use the AI correction without your confirmation.
				</p>

				<hr />

				<h4>
					New symbols feature{" "}
					<a href="/help/videos/8/manipulating-symbols">▶️</a>
				</h4>
				<p>We added a new box in the order form, Symbols:</p>
				<img
					src={newSymbols}
					className="helpImageSmall"
					alt="New custom symbols"
				/>
				<p>
					So far, the symbols shown on the layouts were directly connected with
					the care instructions. In the above image, two care instructions were
					selected - DO NOT IRON and DO NOT DRY CLEAN. In the Current Symbols
					section, you can see the symbols for those two care instructions as
					the first choice. Current Symbols are the symbols that will be used on
					the label.
				</p>
				<p>
					Below, you have smart suggestions - based on your previous activity,
					we will suggest which symbols you should use for the entered care
					instructions combination.
				</p>
				<p>
					If you want to edit the symbol combination shown in the Current
					Symbols, click on the edit button to the right. It will open up a new
					window:
				</p>
				<img src={symbolsEditor} className="helpImage" alt="Symbols editor" />

				<p>
					In this window, you can manipulate the symbols to your liking - you
					can drag and drop to add them, you can create any combination you
					need. Once you are happy with the combination, click save, and they
					will be added to the label.
				</p>

				<hr />
			</article>
		</>
	);
};

export default ReleaseNov24;
