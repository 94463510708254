import { FC, useEffect, useRef, useState } from "react";

interface IProps {
	isOpen: boolean;
	speed?: number;
	children: any;
	className?: string;
}

const Accordion: FC<IProps> = ({
	isOpen,
	speed = 300,
	children,
	className,
}) => {
	const elementRef = useRef<any>();
	const [elementHeight, setElementHeight] = useState(0);

	useEffect(() => {
		if (!elementRef.current) return;
		const resizeObserver = new ResizeObserver((entries) => {
			const height = entries[0].target.scrollHeight;
			setElementHeight(height);
		});
		resizeObserver.observe(elementRef.current);

		return () => resizeObserver.disconnect();
	}, [isOpen, children]);

	// if speed is greater than 0 animation will be applied
	let styleSpeedGreaterThanZero = {
		maxHeight: `${isOpen ? elementHeight + "px" : 0}`,
		transition: `max-height ${speed / 1000}s ease-in-out`,
	};

	// if speed is equal to 0 no animation will be applied,
	// Accordion children will be instantly on the screen
	let styleSpeedEqualZero = {
		maxHeight: "none",
	};

	return (
		<div
			className={`accordion-component-closed ${className}`}
			style={speed === 0 ? styleSpeedEqualZero : styleSpeedGreaterThanZero}
			ref={elementRef}
		>
			{children}
		</div>
	);
};

export default Accordion;
