import { Tooltip } from "react-tooltip";

interface StylesListItemTooltipProps {
	tooltipFor: string;
	invalidInfoReason: string;
	invalidInfoSuggestions: any;
	currentItemIndex: number;
	onSuggestionSelect: (index: number, suggestion: string) => void;
	errorType?: string;
	highlightedPart?: string;
	originalValue?: string;
}

const StylesListItemTooltip = ({
	tooltipFor,
	invalidInfoReason,
	invalidInfoSuggestions,
	currentItemIndex,
	onSuggestionSelect,
	highlightedPart,
	originalValue,
}: StylesListItemTooltipProps) => {
	const highlightMatchingParts = (
		content: string | undefined,
		toPaint: string[]
	) => {
		if (!content) return null;
		if (toPaint.length === 0) return content;

		let result = content;
		toPaint.forEach((term, index) => {
			const regex = new RegExp(`(${term.trim()})`, "gi");
			result = result.replace(
				regex,
				`<span id=${index} style="color: red">$1</span>`
			);
		});
		return <span dangerouslySetInnerHTML={{ __html: result }} />;
	};

	return (
		<Tooltip
			className="styles-list-item-tooltip react-tooltip-import-styles"
			variant="light"
			id={tooltipFor}
			place="top"
			offset={3}
			clickable={true}
		>
			<div className="tooltip-content suggestion-wrap">
				<div className="error-header">Error Detected</div>
				<div className="error-message">{invalidInfoReason}</div>
				<div className="error-message">
					{invalidInfoSuggestions?.length > 0
						? "Please update the value and try again or choose a value from the suggestions below."
						: "Please update the value and try again."}
				</div>

				<div className="section">
					<div className="section-label">Invalid Input:</div>
					<div className="section-content">
						<div className="suggestion-item" style={{ pointerEvents: "none" }}>
							{highlightMatchingParts(
								originalValue,
								highlightedPart?.split(",") ?? []
							)}
						</div>
					</div>
				</div>

				{invalidInfoSuggestions?.length > 0 && (
					// Changed from suggestions-section to section
					<div className="section">
						<div className="section-label">
							Suggested Correction{invalidInfoSuggestions.length > 1 ? "s" : ""}
							:
						</div>
						{/* Changed from suggestions-list to section-content */}
						<div className="section-content">
							{invalidInfoSuggestions.map((suggestion: any, index: number) => (
								<div
									key={index}
									className="suggestion-item"
									onClick={() =>
										onSuggestionSelect(currentItemIndex, suggestion.name)
									}
									title="Click to use this suggestion"
								>
									<span>{suggestion.name}</span>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</Tooltip>
	);
};

export default StylesListItemTooltip;
