import { IUnit } from "Models/OrderModels";
import { fullTimeFormat } from "Utils/utils";

export function demoImages() {
	return [
		"https://storage.googleapis.com/cacotec_public_photos/demo/1.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/2.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/3.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/4.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/5.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/6.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/7.jpg",
		"https://storage.googleapis.com/cacotec_public_photos/demo/8.jpg",
	] as any;
}

export function firstRowInfo(
	brand_name: string,
	order_item_id: number,
	style_number: string,
	style_description: string
) {
	return [
		{
			name: "brand",
			value: brand_name,
			className: "oddRow",
		},
		{
			name: "design #",
			value: `MTC${order_item_id}`,
			className: "evenRow",
		},
		{
			name: "body name",
			value: style_description,
			className: "oddRow",
		},
		{
			name: "main material",
			value: "Neoteric",
			className: "evenRow",
		},
		{
			name: "style #",
			value: style_number,
			className: "oddRow",
		},
		{
			name: "style BOM",
			value: `BOM MTC${order_item_id}`,
			className: "evenRow",
		},
		{
			name: "season-brand",
			value: `Fall 2020 - ${brand_name}`,
			className: "oddRow",
		},
		{
			name: "division",
			value: "Men's Apparel",
			className: "evenRow",
		},
	];
}

export function secondRowInfo(
	units: IUnit[],
	lastChangeBy: string,
	lastChangeAt: string
) {
	return [
		{
			name: "line",
			value: "Mainline",
			className: "oddRow",
		},
		{
			name: "category",
			value: "Shirts",
			className: "evenRow",
		},
		{
			name: "fabrication",
			value: "Woven",
			className: "oddRow",
		},
		{
			name: "size range",
			value: "Mens/Womens XS-3XL",
			className: "evenRow",
		},
		{
			name: "sizes",
			value: units.map((unit) => unit.size).join(", "),
			className: "oddRow",
		},
		{
			name: "deafult size",
			value: "M",
			className: "evenRow",
		},
		{
			name: "modified by",
			value: lastChangeBy,
			className: "oddRow",
		},
		{
			name: "modified",
			value: lastChangeAt ? fullTimeFormat(lastChangeAt) : "---",
			className: "evenRow",
		},
	];
}

export function thirdRowInfo(
	createdBy: string,
	doneAt: any,
	fabricContentOption: any,
	madeInOption: any,
	careInstructions: any
) {
	return [
		{
			name: "created by",
			value: createdBy,
			className: "oddRow",
		},
		{
			name: "created",
			value: doneAt ? fullTimeFormat(doneAt) : "---",
			className: "evenRow",
		},
		{
			name: "fabric content",
			value: fabricContentOption ? fabricContentOption.name : "",
			className: "oddRow",
		},
		{
			name: "made in",
			value: madeInOption ? madeInOption.name : "",
			className: "evenRow",
		},
		{
			name: "care instructions",
			value: careInstructions.join(", "),
			className: "oddRow",
		},
	];
}
