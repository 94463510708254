import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import useDataApi from "Hooks/fetchHook";
import {
	IComposition,
	ICompositionInvalidMessage,
	IDefaultOption,
	IFiberItem,
	IFiberItemInvalidMessage,
	initialComposition,
} from "Models/OrderModels";
import { EndpointPrefix, isFactory } from "Models/UserModels";
import OrderDispatch from "Dispatches/OrderDispatch";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import {
	deepCopy,
	fabricContentJSONToString,
	fabricContentStringToJSON,
	isDpp,
	languagesForTranslationsCheck,
} from "Utils/utils";

import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";
import useOnClickOutside from "Hooks/outsideClickHook";
import Component from "./FabricContentForm/Component";
import Fibers from "./FabricContentForm/Fibers";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import { useTranslation } from "react-i18next";

interface IProps {
	fabricContentText: string;

	setShowFabricContentModalText: (showFabricContentModalText: string) => void;
	setFabricContentText: (fabricContentText: string) => void;
}

const FabricContentForm: React.FunctionComponent<IProps> = ({
	setShowFabricContentModalText,
	fabricContentText,
	setFabricContentText,
}) => {
	const { t } = useTranslation();
	const {
		missingTranslations,
		setMissingTranslations,
		user: { account_type_id },
	} = useContext(GlobalDispatch);
	const { orderItem, setOrderItem, order } = useContext(OrderDispatch);
	const orderBrandId = order.brandId;
	const { allLabelLanguages, chosenLabelLanguages, translations } = orderItem;
	const { qr_phase } = order.labelTemplate?.settings?.default_values;

	const { initialValues } = useFetchInitialValues(order, orderBrandId);

	const componentNames = initialValues.components.map(
		(componentItem: IDefaultOption) => componentItem.name
	);
	const fibersListNames = initialValues.fibers.map(
		(fibersListItem: IDefaultOption) => fibersListItem.name
	);

	const useSaveFabricContent = useDataApi();

	const [addedCompositions, setAddedCompositions] = useState([]) as any;
	const [compositionsWithoutComponent, setCompositionsWithoutComponent] =
		useState([]) as any;
	const [invalidMessages, setInvalidMessages] = useState([]) as any;
	const [scrollToLastComposition, setScrollToLastComposition] = useState(false);
	const [maxHeightOfComposition, setMaxHeightOfComposition] = useState(
		0
	) as any;

	const fabricContentFormRef = useRef(null) as any;
	const compositionsRef = addedCompositions.map((composition: any) =>
		React.createRef()
	);
	const newCompositionIsAdded = useRef(false);

	const onCloseModal = useCallback(
		() => {
			// Do nothing if missing translations modal is open, otherwise close fabric content modal
			const isEmpty = Object.values(missingTranslations).every(
				(x) => Object.keys(x).length === 0
			);

			if (
				!missingTranslations ||
				Object.values(missingTranslations).length === 0 ||
				isEmpty
			) {
				const compositionsToString =
					fabricContentJSONToString(addedCompositions);
				if (
					(compositionsToString !== fabricContentText &&
						window.confirm(
							t("Fabric content not saved. Are you sure you want to leave?")
						)) ||
					compositionsToString === fabricContentText
				) {
					setShowFabricContentModalText("");

					if (!fabricContentText) {
						setFabricContentText("");
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			addedCompositions,
			fabricContentText,
			setShowFabricContentModalText,
			missingTranslations,
		]
	);

	useOnClickOutside(
		fabricContentFormRef,
		useCallback(onCloseModal, [onCloseModal])
	);

	useEffect(() => {
		if (compositionsRef.length > 0) {
			const heights = [...compositionsRef].map(
				(ref: any) => ref.current?.clientHeight
			);
			const currentMaxHeight = Math.max(...heights);
			if (currentMaxHeight > maxHeightOfComposition) {
				setMaxHeightOfComposition(currentMaxHeight);
			}
		}
	}, [compositionsRef]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const compositions = [] as any;
		addedCompositions.map((composition: IComposition, index: number) => {
			if (!composition.component) {
				compositions.push({ ...composition, compositionIndex: index });
			}
			return undefined;
		});
		setCompositionsWithoutComponent(compositions);
		if (newCompositionIsAdded.current) {
			setScrollToLastComposition(true);
		}
	}, [addedCompositions]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (scrollToLastComposition) {
			compositionsRef[addedCompositions.length - 1]?.current?.scrollIntoView();
			newCompositionIsAdded.current = false;
			setScrollToLastComposition(false);
		}
	}, [scrollToLastComposition]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useSaveFabricContent;
		if (data.message) {
			const {
				fabricContentId,
				translations: fabricContentTranslations,
				missingTranslations: missingContentComposition,
			} = data.message;
			if (fabricContentTranslations && !missingContentComposition) {
				const fabricContentString =
					fabricContentJSONToString(addedCompositions);
				setFabricContentText(fabricContentString);

				setShowFabricContentModalText("");
				setOrderItem({
					translations: { ...translations, fabricContentTranslations },
					fabricContent: fabricContentId,
				});
				const fabricContentExist = initialValues.fabricContent.find(
					(fabricContentItem: IDefaultOption) =>
						fabricContentItem.id === fabricContentId
				);
				if (!fabricContentExist) {
					queryClient.invalidateQueries({
						queryKey: [
							QUERY_KEYS.INITIAL_VALUES,
							account_type_id,
							orderBrandId,
						],
					});
				}
			} else if (missingContentComposition) {
				setMissingTranslations({ missingContentComposition });
				const filteredAddedCompositions = [...addedCompositions];
				for (const composition of filteredAddedCompositions) {
					for (const translation of Object.keys(missingContentComposition)) {
						if (composition.component === translation) {
							composition.component = "";
						}
						for (const fiberItem of composition.fibers) {
							if (translation === fiberItem.fiber) {
								fiberItem.fiber = "";
							}
						}
					}
				}
				setAddedCompositions(deepCopy(filteredAddedCompositions));
			}
		}
	}, [useSaveFabricContent.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveFabricContent;
		if (error) {
			toast.error(t("Unable to save fabric content. {{error}}", { error }));
		}
	}, [useSaveFabricContent.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (fabricContentText) {
			const fabricContentJSON = fabricContentStringToJSON(fabricContentText);
			if (fabricContentJSON) {
				setAddedCompositions(fabricContentJSON);
			}
		} else {
			setAddedCompositions([initialComposition]);
		}
		if (fabricContentFormRef.current) {
			fabricContentFormRef.current.focus();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const changeComponentInvalidMessages = (
		invalidMessage: string,
		index: number
	) => {
		const invalidMessagesCopy = [...invalidMessages];
		if (!invalidMessagesCopy[index]) {
			invalidMessagesCopy[index] = {
				component: "",
				fibers: [],
			};
		}
		invalidMessagesCopy[index].component = invalidMessage;
		setInvalidMessages(invalidMessagesCopy);
	};

	const changeFibersInvalidMessages = (
		itemPart: string,
		invalidMessage: string,
		compositionIndex: number,
		fiberItemIndex: number
	) => {
		const invalidMessagesCopy = [...invalidMessages];
		if (!invalidMessagesCopy[compositionIndex]) {
			invalidMessagesCopy[compositionIndex] = {
				component: "",
				fibers: [],
			};
		}

		const invalidFibers = invalidMessagesCopy[compositionIndex].fibers;
		const invalidFibersCopy = [...invalidFibers] as any;
		if (!invalidFibersCopy[fiberItemIndex]) {
			invalidFibersCopy[fiberItemIndex] = {
				fiber: "",
				percentage: "",
			};
		}
		invalidFibersCopy[fiberItemIndex][itemPart] = invalidMessage;
		invalidMessagesCopy[compositionIndex].fibers = invalidFibersCopy;
		setInvalidMessages(invalidMessagesCopy);
	};

	const checkIfCompositionIsInvalid = (composition: any, index: number) => {
		const { component, fibers, showPercentage } = composition;

		if (
			!component &&
			addedCompositions.length > 1 &&
			compositionsWithoutComponent.length > 1 &&
			compositionsWithoutComponent[0].compositionIndex !== index
		) {
			changeComponentInvalidMessages(t("You must add a component!"), index);
		} else if (
			invalidMessages[index]?.component === t("You must add a component!")
		) {
			changeComponentInvalidMessages("", index);
		}

		if (fibers.length === 1 && !fibers[0].fiber) {
			changeFibersInvalidMessages(
				"fiber",
				t("You must add a fiber!"),
				index,
				0
			);
		}

		let fiberItemIndex = 0;
		const nonExistingFiber = fibers.find(
			(fiberItem: IFiberItem, itemIndex: number) => {
				fiberItemIndex = itemIndex;
				return !fibersListNames.includes(fiberItem.fiber);
			}
		);

		if (nonExistingFiber) {
			changeFibersInvalidMessages(
				"fiber",
				t("You must add a valid fiber!"),
				index,
				fiberItemIndex
			);
		} else if (
			invalidMessages[index]?.component === t("You must add a valid fiber!")
		) {
			changeFibersInvalidMessages("fiber", "", index, fiberItemIndex);
		}

		const percentages = fibers.map(
			(fiberItem: IFiberItem) => fiberItem.percentage
		);
		const sumOfPercentages = Number(
		  percentages.reduce((a: number, b: number) => a + b, 0).toFixed(1)
		);
		const fiberWithMissingPercentage =
			fibers.length > 1 &&
			fibers.find((fiberItem: IFiberItem, itemIndex: number) => {
				fiberItemIndex = itemIndex;
				return !fiberItem.percentage;
			});
		if (
			(Math.abs(sumOfPercentages - 100) > 0.01 && showPercentage) ||
			fiberWithMissingPercentage
		) {
			changeFibersInvalidMessages(
				"percentage",
				t("Sum of percentages must equal 100!"),
				index,
				0
			);
		} else {
			changeFibersInvalidMessages("percentage", "", index, 0);
		}
	};

	const onSaveContent = () => {
		addedCompositions.map((composition: IComposition, index: number) =>
			checkIfCompositionIsInvalid(composition, index)
		);

		let invalidMessagesToShowOnSave = [] as any;
		invalidMessages?.map(
			(invalidMessage: ICompositionInvalidMessage, index: number) => {
				if (invalidMessage?.component) {
					invalidMessagesToShowOnSave.push(index);
				}

				invalidMessage?.fibers?.map(
					(invalidFiberMessage: IFiberItemInvalidMessage) => {
						if (invalidFiberMessage?.fiber) {
							invalidMessagesToShowOnSave.push(index);
						}
						if (invalidFiberMessage?.percentage) {
							invalidMessagesToShowOnSave.push(index);
						}
						return undefined;
					}
				);
				return undefined;
			}
		);

		if (invalidMessagesToShowOnSave.length > 0) {
			if (compositionsRef[invalidMessagesToShowOnSave[0]]?.current) {
				compositionsRef[
					invalidMessagesToShowOnSave[0]
				]?.current.scrollIntoView();
			}
		} else {
			const fabricContentString = fabricContentJSONToString(addedCompositions);
			let languagesArrayForTranslationsCheck = languagesForTranslationsCheck(
				allLabelLanguages,
				chosenLabelLanguages,
				isDpp(qr_phase)
			);
			const brandIdForFactory = isFactory(account_type_id)
				? `?brandId=${orderBrandId}`
				: "";
			useSaveFabricContent.doFetch(
				`/${EndpointPrefix[account_type_id]}/translations/fabricContent${brandIdForFactory}`,
				{
					fabricContent: fabricContentString,
					languages: languagesArrayForTranslationsCheck,
				},
				"POST"
			);
		}
	};

	const onAddNewComposition = () => {
		setAddedCompositions([...addedCompositions, initialComposition]);
		newCompositionIsAdded.current = true;
	};

	const onDeleteComposition = (
		compostionText: string,
		compositionIndex: number
	) => {
		const compositions = addedCompositions.filter(
			(composition: IComposition, index: number) =>
				fabricContentJSONToString([composition]) !== compostionText ||
				compositionIndex !== index
		);
		setAddedCompositions(compositions);

		if (invalidMessages[compositionIndex]) {
			delete invalidMessages[compositionIndex];
		}
	};

	const onChangeAddedComposition = (composition: any, index: number) => {
		let compositions = [...addedCompositions];
		compositions[index] = { ...addedCompositions[index], ...composition };
		setAddedCompositions(compositions);
	};

	const handleOnDragEnd = (result: any) => {
		if (!result.destination) return;

		const items = Array.from(addedCompositions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		setAddedCompositions(items);
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCloseModal();
		}
	};

	const onClickCapture = (e: any) => {
		if (e.defaultPrevented) {
			return;
		}
		if (e.currentTarget !== e.target && e.target.tabIndex >= 0) {
			e.target.focus();
		} else {
			e.currentTarget.focus();
		}
	};

	return (
		<div className="modal" id="fabricContentModalContainer">
			<div
				className="modal-content show"
				tabIndex={-1}
				ref={fabricContentFormRef}
				id="fabricContentModal"
				onKeyDown={onKeyDown}
			>
				<Loading
					show={useSaveFabricContent.isLoading}
					text={t("Loading...")}
					imgClass="block-center"
					divClass="fabricContentLoading"
				/>

				{!useSaveFabricContent.isLoading && (
					<>
						<div>
							<header className="drawer__header" id="fabricContentModalHeader">
								<h1 className="section__title">{`${
									fabricContentText.length > 0 ? t("Edit ") : t("Create a New")
								} ${t("Content Composition")}`}</h1>

								<button
									className="drawer__close btn-no-style"
									data-testid="fabricContentForm-closeModal"
									id="fabricContentModalCloseButton"
									data-dismiss="drawer"
									aria-label={t("Close")}
									onClick={onCloseModal}
								>
									<Icon name="cross-rounded" />
								</button>
							</header>

							<div className="fabricContentPreview">
								<div className="upcase fabricContentPreviewText">
									{t("Preview")}
								</div>
								{fabricContentJSONToString(addedCompositions)
									.split(";")
									.map((composition: string, i: number) => {
										return <div key={`composition${i}`}>{composition}</div>;
									})}
							</div>

							<div style={{ paddingTop: "20px" }}>
								<button
									className="button button--outline-dark button--sm"
									id="blueBorderButton"
									onClick={() => onAddNewComposition()}
								>
									{t("Add Component")}
								</button>
							</div>

							<div style={{ height: maxHeightOfComposition + 80 }}>
								<DragDropContext onDragEnd={handleOnDragEnd}>
									<Droppable droppableId="compositions" direction="horizontal">
										{(provided) => (
											<div
												className="compositionList"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{addedCompositions.map(
													(
														composition: IComposition,
														compositionIndex: number
													) => {
														const { component, fibers, showPercentage } =
															composition;
														return (
															<Draggable
																key={`${component}-${compositionIndex}`}
																draggableId={`${component}${compositionIndex}`}
																index={compositionIndex}
															>
																{(provided) => (
																	<fieldset
																		className="box box--bordered fabricComponentForm"
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																		onClickCapture={onClickCapture}
																	>
																		<figure
																			className="uploaded-images__composition"
																			id="deleteCompositionButton"
																			ref={compositionsRef[compositionIndex]}
																		>
																			<Component
																				compositionIndex={compositionIndex}
																				component={component}
																				componentsList={
																					initialValues.components
																				}
																				componentNames={componentNames}
																				onChangeAddedComposition={
																					onChangeAddedComposition
																				}
																				changeComponentInvalidMessages={
																					changeComponentInvalidMessages
																				}
																				invalidMessages={invalidMessages}
																			/>

																			<Fibers
																				compositionIndex={compositionIndex}
																				fibersList={initialValues.fibers}
																				fibers={fibers}
																				onChangeAddedComposition={
																					onChangeAddedComposition
																				}
																				changeFibersInvalidMessages={
																					changeFibersInvalidMessages
																				}
																				showPercentage={showPercentage}
																				invalidMessages={invalidMessages}
																			/>

																			<div
																				className="uploaded-images__remove_composition"
																				onClick={() =>
																					onDeleteComposition(
																						fabricContentJSONToString([
																							composition,
																						]),
																						compositionIndex
																					)
																				}
																			>
																				<Icon name="cross-rounded-filled" />
																			</div>
																		</figure>
																	</fieldset>
																)}
															</Draggable>
														);
													}
												)}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</div>
						</div>
						<div className="saveFabricContentButton">
							<div
								data-tooltip-id="saveNewOrEditContentComposition"
								style={{
									display: "inline-block",
									borderRadius: "50px",
								}}
							>
								<div
									className={`button ${
										addedCompositions.length > 0
											? "button--primary"
											: "button--disabled"
									} `}
									onClick={onSaveContent}
								>
									{t("Apply")}
								</div>
							</div>
							{addedCompositions.length === 0 && (
								<Tooltip
									id="saveNewOrEditContentComposition"
									className="reactTooltip box--shadowed"
									float
									offset={10}
								>
									<span>{t("Start by adding components.")}</span>
								</Tooltip>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default FabricContentForm;
