import React from "react";

import reportMissingTranslations from "./images/report-missing-translations.png";
import usePageTitle from "Hooks/pageTitleHook";

const MissingTranslationsHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Missing translations`);

	return (
		<>
			<h2 className="helpTitle">Missing translations</h2>
			<article className="helpArticle">
				<p>
					If you have added your information and there are no translations for
					some of the options you chose, a popup will appear as below:
				</p>
				<img
					src={reportMissingTranslations}
					className="helpImageLarge"
					alt="Report missing translations"
				/>
				<p>
					In the above example, we detected that you used CLOSE FASTENERS BEFORE
					WASHING care instruction, for which we don’t have translation to
					English, French and Chinese Simplified. If you have the translations,
					you can enter them in the text field and submit. Someone from our team
					will contact you as soon as the translations are processed.
				</p>

				<p>
					<b>Note:</b> items with missing translations will be automatically
					removed from the order. If you submit the missing translations, we
					will notify you once they are added to the system so that you can go
					back and continue with the order.
				</p>
			</article>
		</>
	);
};

export default MissingTranslationsHelp;
