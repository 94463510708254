import React, { useEffect, useRef, useState } from "react";

import sprite from "images/icons.svg";

interface IStateProps {
	style: any;
	refVar: any;

	setListWidth: (listWidth: number) => void;
}

const StylesListItem: React.FunctionComponent<IStateProps> = ({
	style,
	refVar,

	setListWidth,
}) => {
	const {
		style_number,
		style_description,
		season,
		made_in,
		fabric_content,
		care_instructions,
		additional_components,
		include_qr_code,
	} = style;

	const [accordionIsOpen, setAccordionIsOpen] = useState<boolean>(false);

	// calculate heading right padding depending on the thickness of the scroll bar  === START

	const listItem = useRef<any>(null);

	const observer = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;
			setListWidth(width);
		})
	);

	useEffect(() => {
		if (listItem.current) {
			observer.current.observe(listItem.current);
		}

		let el = listItem.current;
		let obs = observer.current;
		return () => {
			obs.unobserve(el);
		};
	}, [listItem, observer]);

	// calculate heading right padding depending on the thickness of the scroll bar  === END

	return (
		<>
			{
				<div
					style={{
						borderBottom: "2px solid #f4f4f4",
						fontSize: "14px",
						cursor: "pointer",
					}}
					ref={refVar}
				>
					<article
						className={`list-item list-item--styles accordion  ${
							accordionIsOpen
								? "accordion-animation-open"
								: accordionIsOpen === undefined
								? ""
								: "accordion-animation-close"
						}
              `}
						onClick={() => {
							setAccordionIsOpen(!accordionIsOpen);
						}}
						ref={listItem}
					>
						<div
							className={`${!style_number && "missing--info"} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{style_number ?? ""}
						</div>

						<div
							className={`  ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{(style_description ?? "") + "\n"}
						</div>

						<div
							className={` ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{(season ?? "") + "\n"}
						</div>

						<div
							className={`${!made_in && "missing--info"} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{(made_in ?? "") + "\n"}
						</div>

						<div
							className={`${!fabric_content && "missing--info"} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{fabric_content && (
								<>
									{fabric_content
										.split(";")
										.map((value: any, index: number) => {
											return (
												<span key={index}>
													{!value ? <br /> : value + "\n"}
												</span>
											);
										})}
								</>
							)}
						</div>

						<div
							className={`${
								(!care_instructions || care_instructions.length === 0) &&
								"missing--info"
							} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{care_instructions?.map((value: any, index: number) => {
								return (
									<span style={{ wordBreak: "break-word" }} key={index}>
										{value + "\n"}
									</span>
								);
							})}
						</div>

						<div
							className={`${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{additional_components?.map((value: any, index: number) => {
								return <span key={index}>{value + "\n"}</span>;
							})}
						</div>

						<div className="list-item__details">
							{include_qr_code ? "YES" : "NO"}
						</div>
						<div className="list-item__details">
							<div>
								<svg
									style={{
										height: "25px",
										width: "25px",
										fill: "black",
									}}
									className={`accordion-arrow ${
										accordionIsOpen ? "rotate-90" : "rotate-270"
									}`}
								>
									<use xlinkHref={`${sprite}#icon-double-left-arrow`} />
								</svg>
							</div>
						</div>
					</article>
				</div>
			}
		</>
	);
};

export default StylesListItem;
