import React from "react";

import orderApproveMenu from "./images/order-approve-menu.png";
import orderRejection from "./images/order-rejection.png";
import orderApproved from "./images/order-approved.png";
import usePageTitle from "Hooks/pageTitleHook";

const OrderApprovalHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Order approval`);

	return (
		<>
			<h2 className="helpTitle">Order approval</h2>
			<article className="helpArticle">
				<p>
					Once a request for an order approval is sent to you, you will receive
					an email notification with the message and a link to the order. Once
					opened, you will be greeted with the following interface:
				</p>
				<img
					src={orderApproveMenu}
					className="helpImageSmall"
					alt="Order actions"
				/>
				<p>
					You can scroll down through all of the layouts and quantities and
					check if all the information is correct.
				</p>
				<p className="helpSubtitle">Rejecting the order</p>

				<p>
					If you choose to reject the order, you will be able to enter the
					reason for rejection. The reason would then be sent to the person who
					sent the approval request, so that they could act on it.
				</p>
				<img
					src={orderRejection}
					className="helpImageSmall"
					alt="Order rejection"
				/>

				<p className="helpSubtitle">Approving the order</p>

				<p>
					If you approve the order, the person who sent the approval request
					will get a notification, and then they can proceed with placing the
					order.
				</p>

				<img
					src={orderApproved}
					className="helpImageSmall"
					alt="Order approved"
				/>

				<p>
					<b>Note:</b> If someone changes and then saves an order that has
					already been approved, the approval is cancelled and the whole process
					has to be repeated.
				</p>
			</article>
		</>
	);
};

export default OrderApprovalHelp;
