import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import Loading from "../Shared/Loading";

const AdminFactories: React.FunctionComponent = () => {
	const useAddFactory = useDataApi();
	const useUpdateFactory = useDataApi();
	const useGetFactories = useDataApi();

	const [newFactoryName, setNewFactoryName] = useState("");
	const [updateFactoryId, setUpdateFactoryId] = useState("");
	const [updatedFactoryName, setUpdatedFactoryName] = useState("" as any);
	const [factories, setFactories] = useState([] as any);

	useEffect(() => {
		useGetFactories.doFetch("/maker/admin/factories");
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */
	useEffect(() => {
		const { error } = useAddFactory;
		if (error) {
			toast.error(`Unable to add factory. ${error}`);
		}
	}, [useAddFactory.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useAddFactory;
		if (data.status === "ok") {
			toast.success("Factory added successfully.");
			setNewFactoryName("");
		}
	}, [useAddFactory.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */
	useEffect(() => {
		const { error } = useUpdateFactory;
		if (error) {
			toast.error(`Unable to update factory. ${error}`);
		}
	}, [useUpdateFactory.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useUpdateFactory;
		if (data.status === "ok") {
			toast.success("Factory updated successfully.");
			setUpdateFactoryId("");
			setUpdatedFactoryName("");
			useGetFactories.doFetch("/maker/admin/factories");
		}
	}, [useUpdateFactory.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */
	useEffect(() => {
		const { error } = useGetFactories;
		if (error) {
			toast.error(`Unable to get factories. ${error}`);
		}
	}, [useGetFactories.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetFactories;

		if (data.status === "ok") {
			const serverFactories = data.message;

			for (let i = 0; i < serverFactories.length; i++) {
				const { id, name } = serverFactories[i];
				factories[id] = name;
			}

			setFactories((factories: any) => ({ ...factories }));
		}
	}, [useGetFactories.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */

	const onAddFactory = () => {
		if (!newFactoryName) {
			alert("Please enter a factory name.");
		} else {
			const data = { name: newFactoryName };
			useAddFactory.doFetch("/maker/admin/factories", data, "POST");
		}
	};

	const onUpdateFactory = () => {
		if (!updatedFactoryName) {
			alert("Please enter a factory name.");
		} else {
			const data = { name: updatedFactoryName };
			useUpdateFactory.doFetch(
				`/maker/admin/factories/${updateFactoryId}`,
				data,
				"PUT"
			);
		}
	};

	const onFactorySelected = (selectedFactoryId: any) => {
		setUpdateFactoryId(selectedFactoryId);
		setUpdatedFactoryName(factories[selectedFactoryId]);
	};

	return (
		<div
			className="tab-pane fade in active show settingsTab"
			role="tabpanel"
			id="tab-general"
			aria-labelledby="tab-toggle-general"
		>
			<fieldset
				className="box box--light mb--md form-group"
				style={{ padding: "40px 60px" }}
			>
				<h3 className="drawer__title">Add Factory</h3>
				<div className="form-text">
					Please enter the name of the new factory:
				</div>
				<input
					type="text"
					value={newFactoryName}
					onChange={(e) => setNewFactoryName(e.target.value)}
					className="form-control"
				/>
				<div
					className="form-actions mt--md"
					style={{ justifyContent: "flex-end" }}
				>
					{!useAddFactory.isLoading && (
						<button
							className="button button--primary"
							onClick={onAddFactory}
							data-testid="addFactoryButton"
						>
							Add Factory
						</button>
					)}
					<Loading
						show={useAddFactory.isLoading}
						text={"Loading..."}
						imgClass="saveOrderLoading"
						divClass=""
					/>
				</div>
			</fieldset>

			<fieldset
				className="box box--light mb--md"
				style={{ padding: "40px 60px" }}
			>
				<h3 className="drawer__title">Update Factory</h3>
				<div className="form-text">Please select a factory to update:</div>
				<select
					value={updateFactoryId}
					onChange={(e) => {
						onFactorySelected(e.target.value);
					}}
					className="form-control"
				>
					{Object.keys(factories).map((key: any) => {
						return (
							<option style={{ padding: "5px" }} key={key} value={key}>
								{factories[key]}
							</option>
						);
					})}
				</select>
				<div className="form-text">
					Please enter the new name for the factory:
				</div>
				<input
					type="text"
					value={updatedFactoryName}
					onChange={(e) => setUpdatedFactoryName(e.target.value)}
					className="form-control"
				/>
				<div
					className="form-actions mt--md"
					style={{ justifyContent: "flex-end" }}
				>
					{!useUpdateFactory.isLoading && (
						<button
							className="button button--primary"
							onClick={onUpdateFactory}
							data-testid="updateFactoryButton"
						>
							Update Factory
						</button>
					)}
					<Loading
						show={useUpdateFactory.isLoading}
						text={"Loading..."}
						imgClass="saveOrderLoading"
						divClass=""
					/>
				</div>
			</fieldset>
		</div>
	);
};

export default AdminFactories;
