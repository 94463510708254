import React, { useEffect, useRef, useState } from "react";

import StylesListItemTooltip from "./Components/StylesListItemTooltip";

interface IStateProps {
	key: string;
	style: any;
	invalidInfo: any;
	invalidStylesArrayIndexes: number[];

	updateStyle: (styleNumber: string, updatedFields: any) => void;
	setInvalidStylesArrayIndexes: React.Dispatch<React.SetStateAction<number[]>>;
	setListWidth: (listWidth: number) => void;
}

const ImportStylesListItem: React.FunctionComponent<IStateProps> = ({
	style,
	invalidInfo,
	invalidStylesArrayIndexes,

	updateStyle = () => {},
	setInvalidStylesArrayIndexes,
	setListWidth,
}) => {
	const {
		style_number,
		style_description,
		season,
		made_in,
		fabric_content,
		care_instructions,
		additional_components,
		include_qr_code,
		id: styleId,
	} = style;

	const [invalidStyle, setInvalidStyle] = useState(false);

	const [applyAnimation, setApplyAnimation] = useState(false);

	useEffect(() => {
		setInvalidStyle(
			!!(
				invalidStyleNumberReason ||
				invalidStyleDescriptionReason ||
				invalidSeasonReason ||
				invalidMadeInReason ||
				invalidFabricContentReason ||
				invalidCareInstructions ||
				invalidAdditionalComponents
			)
		);
	}, [invalidInfo, style]);

	useEffect(() => {
		if (invalidInfo) {
			if (invalidStyle) {
				let styleId: number = style.id;
				setInvalidStylesArrayIndexes(
					(prevInvalidStylesArrayIndexes: number[]) => {
						let newArr: number[] = [...prevInvalidStylesArrayIndexes, styleId];
						return newArr;
					}
				);
			} else {
				setApplyAnimation(true);
			}
		}
	}, [invalidStyle]); // eslint-disable-line react-hooks/exhaustive-deps

	//**************************************** */
	// Individual invalid reasons for each field
	const invalidStyleNumberReason =
		invalidInfo?.invalid_style_numbers?.[style_number];

	const invalidStyleDescriptionReason =
		invalidInfo?.invalid_style_descriptions?.[style_description];

	const invalidSeasonReason = invalidInfo?.invalid_season?.[season];

	const invalidMadeInReason = invalidInfo?.invalid_made_ins?.[made_in]?.message;
	const similarMadeIns =
		invalidInfo?.invalid_made_ins?.[made_in]?.similarMadeIns;

	const invalidFabricContentReason =
		invalidInfo?.invalid_fabric_contents?.[fabric_content]?.message;
	const similarFabricContents =
		invalidInfo?.invalid_fabric_contents?.[fabric_content]
			?.similarFabricContents;

	const invalidCareInstructions =
		care_instructions?.filter(
			(instruction: any) =>
				invalidInfo?.invalid_care_instructions?.[instruction]
		).length > 0;

	const invalidAdditionalComponents =
		additional_components?.filter(
			(component: any) =>
				invalidInfo?.invalid_additional_components?.[component]
		).length > 0;

	//**************************************** */

	const listItem = useRef<any>(null);

	const observer = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;
			setListWidth(width);
		})
	);

	useEffect(() => {
		if (listItem.current) {
			observer.current.observe(listItem.current);
		}

		let el = listItem.current;
		let obs = observer.current;
		return () => {
			obs.unobserve(el);
		};
	}, [listItem, observer]);

	const handleMadeInChange = (_index: number, newMadeIn: string) => {
		updateStyle(style.style_number, { made_in: newMadeIn });
	};

	const handleFabricContentChange = (
		_index: number,
		newFabricContent: string
	) => {
		updateStyle(style.style_number, { fabric_content: newFabricContent });
	};

	const handleCareInstructionChange = (
		index: number,
		newInstruction: string
	) => {
		const updatedCareInstructions = [...style.care_instructions];
		updatedCareInstructions[index] = newInstruction;
		updateStyle(style.style_number, {
			care_instructions: updatedCareInstructions,
		});
	};

	const handleAdditionalComponentsChange = (
		index: number,
		newComponent: string
	) => {
		const updatedAdditionalComponents = [...style.additional_components];
		updatedAdditionalComponents[index] = newComponent;
		updateStyle(style.style_number, {
			additional_components: updatedAdditionalComponents,
		});
	};

	return (
		<>
			{(!invalidInfo || invalidStylesArrayIndexes?.includes(styleId)) && (
				<div
					style={{
						borderBottom: "2px solid #f4f4f4",
						fontSize: "14px",
					}}
					className={`${applyAnimation && "animation-on-fix-style"}`}
					onAnimationEnd={(event) => {
						if (event.animationName === "fadeOutAnimation") {
							setApplyAnimation(false);
							// Remove fixed style from invalid styles array
							setInvalidStylesArrayIndexes(
								(prevInvalidStylesArrayIndexes: any) => {
									const newArray = prevInvalidStylesArrayIndexes.filter(
										(invalidStyleId: any) => invalidStyleId !== styleId
									);
									return newArray;
								}
							);
						}
					}}
				>
					<article
						className={`list-item list-item--styles`}
						style={
							applyAnimation
								? {
										backgroundColor: "rgba(157, 251, 200, 0.5)",
										overflowX: "hidden",
								  }
								: {}
						}
						ref={listItem}
					>
						{applyAnimation && (
							<div className="check-mark-on-fix-style">
								<svg
									className="checkmark"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 52 52"
									style={{
										width: "48px",
										height: "48px",
										margin: "0",
									}}
								>
									<circle
										className="checkmark__circle"
										cx="26"
										cy="26"
										r="25"
										fill="none"
									/>
									<path
										className="checkmark__check"
										fill="none"
										d="M14.1 27.2l7.1 7.2 16.7-16.8"
									/>
								</svg>
							</div>
						)}
						{/* Style Number */}
						<div
							className={`${!style_number && "missing--info"} ${
								invalidStyleNumberReason && "link--danger invalidValue"
							} ${"list-item__details"}`}
							data-tooltip-id={`styleNumber${style_number}`}
							data-iscapture="true"
						>
							{style_number ?? ""}
						</div>

						{/* Style Description */}
						<div
							className={` ${
								invalidStyleDescriptionReason && "link--danger invalidValue"
							} ${"list-item__details"}`}
							data-tooltip-id={`styleDesc${style_number}`}
							data-iscapture="true"
						>
							{(style_description ?? "") + "\n"}
						</div>

						{/* Season */}
						<div
							className={` ${
								invalidSeasonReason && "link--danger invalidValue"
							} ${"list-item__details"}`}
							data-tooltip-id={`season-${style_number}`}
							data-iscapture="true"
						>
							{(season ?? "") + "\n"}
						</div>

						{/* Made In */}
						<div
							className={` ${
								!made_in && "missing--info"
							} ${"list-item__details"}`}
						>
							<span
								className={`${
									invalidMadeInReason && "link--danger invalidValue"
								}`}
								data-tooltip-id={`madeIn${style_number}`}
								data-iscapture="true"
							>
								{made_in ?? ""}
							</span>
							{invalidMadeInReason && (
								<StylesListItemTooltip
									tooltipFor={`madeIn${style_number}`}
									invalidInfoReason={invalidMadeInReason}
									invalidInfoSuggestions={similarMadeIns}
									currentItemIndex={0}
									onSuggestionSelect={handleMadeInChange}
                  originalValue={made_in}
								/>
							)}
						</div>

						{/* Fabric Content */}
						<div
							className={` ${
								invalidFabricContentReason && "link--danger invalidValue"
							} ${!fabric_content && "missing--info"} ${"list-item__details"}`}
						>
							{fabric_content && (
								<div
									data-tooltip-id={`fabricContent${style_number}`}
									data-iscapture="true"
								>
									{fabric_content
										.split(";")
										.map((value: any, index: number) => {
											return (
												<span key={index}>
													{!value ? <br /> : value + "\n"}
												</span>
											);
										})}
								</div>
							)}
							{invalidFabricContentReason && (
								<StylesListItemTooltip
									tooltipFor={`fabricContent${style_number}`}
									invalidInfoReason={invalidFabricContentReason || ""}
									invalidInfoSuggestions={similarFabricContents}
									currentItemIndex={0}
									onSuggestionSelect={handleFabricContentChange}
                  errorType={invalidInfo?.invalid_fabric_contents?.[fabric_content]?.errorType}
									highlightedPart={invalidInfo?.invalid_fabric_contents?.[fabric_content]?.highlightedPart}
									originalValue={fabric_content}
								/>
							)}
						</div>

						{/* Care Instructions */}
						<div
							className={`${
								(!care_instructions || care_instructions.length === 0) &&
								"missing--info"
							} ${"list-item__details"}`}
						>
							{care_instructions?.map((value: any, index: number) => {
								const invalidCareInstructionReason =
									invalidInfo?.invalid_care_instructions?.[value]?.message;

								const similarCareInstructions =
									invalidInfo?.invalid_care_instructions?.[value]
										?.similarCareInstructions;

								return (
									<>
										<div
											key={index}
											data-tooltip-id={`care${style_number}${index}`}
											data-iscapture="true"
											className={`${
												invalidCareInstructionReason &&
												"link--danger invalidValue"
											}`}
											style={{
												wordBreak: "break-word",
											}}
										>
											{value}
										</div>
										{invalidCareInstructionReason && (
											<StylesListItemTooltip
												tooltipFor={`care${style_number}${index}`}
												invalidInfoReason={invalidCareInstructionReason}
												invalidInfoSuggestions={similarCareInstructions}
												currentItemIndex={index}
												onSuggestionSelect={handleCareInstructionChange}
												originalValue={value}
											/>
										)}
									</>
								);
							})}
						</div>

						{/* Additional Components */}
						<div className={`${"list-item__details"}`}>
							{additional_components?.map((component: any, index: number) => {
								const invalidComponentReason =
									invalidInfo?.invalid_additional_components?.[component]
										?.message;

								const similarComponents =
									invalidInfo?.invalid_additional_components?.[component]
										?.similarAdditionalComponents;

								return (
									<div
										key={index}
										className={`${
											invalidComponentReason && "link--danger invalidValue"
										}`}
										data-tooltip-id={`additionalComponent${style_number}${index}`}
										data-iscapture="true"
									>
										{component}
										{invalidComponentReason && (
											<StylesListItemTooltip
												tooltipFor={`additionalComponent${style_number}${index}`}
												invalidInfoReason={invalidComponentReason}
												invalidInfoSuggestions={similarComponents}
												currentItemIndex={index}
												onSuggestionSelect={handleAdditionalComponentsChange}
												originalValue={component}
											/>
										)}
									</div>
								);
							})}
						</div>

						{/* Include QR Code */}
						<div className="list-item__details">
							{include_qr_code ? "YES" : "NO"}
						</div>
					</article>
				</div>
			)}
		</>
	);
};

export default ImportStylesListItem;
