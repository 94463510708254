import { FC } from "react";
import Loading from "Components/Shared/Loading";
import { useTranslation } from "react-i18next";

interface IProps {
	isLoadingApproval: boolean;
	orderItems: any;

	onApprove: () => void;
}

const OrderApproveButton: FC<IProps> = ({
	isLoadingApproval,
	orderItems,
	onApprove,
}) => {
	const { t } = useTranslation();
	return (
		<div className="flex">
			{!isLoadingApproval ? (
				<>
					<div data-tooltip-id="requestApproval">
						<button
							className={
								orderItems?.length > 0
									? "button button--primary ml--base"
									: "button button--disabled ml--base"
							}
							type="button"
							form="orderItemForm"
							onClick={onApprove}
							data-testid="orderHeader-requestApproval"
						>
							{t("Approve")}
						</button>
					</div>
				</>
			) : (
				<Loading
					show
					text={`Loading...`}
					imgClass="requestApproveOrderLoading"
					divClass="requestApproveOrderLoadingWrapper"
				/>
			)}
		</div>
	);
};

export default OrderApproveButton;
