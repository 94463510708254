import React from "react";
import { Link } from "react-router-dom-v5-compat";

import chooseABrand from "./images/choose-a-brand.png";
import usePageTitle from "Hooks/pageTitleHook";

const FactoriesIntroHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Basic information`);

	return (
		<>
			<h2 className="helpTitle">Basic information</h2>
			<article className="helpArticle">
				<p>
					You will be handling multiple brands from your dashboard at the same
					time. This means you will see orders for all brands in the orders
					list. Also, when creating a new order, you will have to choose a brand
					for which you are creating the order:
				</p>
				<img
					src={chooseABrand}
					className="helpImageXSmall"
					alt="Choose a brand"
				/>
				<p>
					Once you select a brand, all settings and options for that brand will
					be loaded and you can continue with adding the new order. Have in mind
					that different brands might have a slightly different order forms.
				</p>
				<p>
					<b>Note: </b>If your customer is using{" "}
					<Link className="link" to="/help/articles/importStyles">
						import styles via excel feature
					</Link>
					, you can use{" "}
					<Link className="link" to="/help/articles/addingExistingStyle">
						add existing styles
					</Link>{" "}
					option to add styles into the order, avoiding manual entry of the
					information. You only need to adjust quantities per style.
				</p>
				<p>
					<b>Note: </b>If your customer is using{" "}
					<Link className="link" to="/help/articles/apiIntegration">
						data transfer via API
					</Link>
					, you won't need to add the orders manually. Contact your account
					executive if you want to use this feature.
				</p>
			</article>
		</>
	);
};

export default FactoriesIntroHelp;
