import React, { useCallback, useRef } from "react";

import useOnClickOutside from "Hooks/outsideClickHook";
import LabelMakerEditStatusModal from "Components/Shared/LabelMakerEditStatusModal";

interface IProps {
	labelMakerData: any;

	setLabelMakerData: (data: any) => void;
	forceRefreshOrders: () => void;
}

const LabelMakerActions: React.FunctionComponent<IProps> = ({
	labelMakerData,
	setLabelMakerData,
	forceRefreshOrders,
}) => {
	const dateModalRef = useRef(null) as any;

	useOnClickOutside(
		dateModalRef,
		useCallback(() => {
			setLabelMakerData({});
		}, [setLabelMakerData])
	); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{labelMakerData?.status > 0 && (
				<LabelMakerEditStatusModal
					labelMakerData={labelMakerData}
					setLabelMakerData={setLabelMakerData}
					onChangeStatus={forceRefreshOrders}
				/>
			)}
		</>
	);
};

export default LabelMakerActions;
