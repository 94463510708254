export interface IUser {
	id: number;
	name: string;
	email: string;
	account_type_id: USER_ACCOUNT_TYPE_VALUES;
	brand_id: number;
	org_name: string;
	org_logo: string;
	external_id?: number;
	brands?: any;
	org_settings?: any;
	whats_new_seen?: boolean;
}

export const UserAccountType = Object.freeze({
	BRAND_ADMIN: 1,
	BRAND_MEMBER: 2,
	LABEL_MAKER_ADMIN: 6,
	DEMO: 7,
	FACTORY: 8,
});

export type USER_ACCOUNT_TYPE_KEYS = keyof typeof UserAccountType;

export type USER_ACCOUNT_TYPE_VALUES =
	(typeof UserAccountType)[USER_ACCOUNT_TYPE_KEYS];

export const isBrand = (accountTypeId: number) =>
	accountTypeId === UserAccountType.BRAND_ADMIN ||
	accountTypeId === UserAccountType.BRAND_MEMBER ||
	accountTypeId === UserAccountType.DEMO;

export const isFactory = (accountTypeId: number) =>
	accountTypeId === UserAccountType.FACTORY;

export const isLabelMaker = (accountTypeId: number) =>
	accountTypeId === UserAccountType.LABEL_MAKER_ADMIN;

export const EndpointPrefix = Object.freeze({
	[UserAccountType.BRAND_ADMIN]: "brand",
	[UserAccountType.BRAND_MEMBER]: "brand",
	[UserAccountType.FACTORY]: "factory",
	[UserAccountType.LABEL_MAKER_ADMIN]: "maker",
	[UserAccountType.DEMO]: "brand",
});
