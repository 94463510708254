import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import { isLabelMaker, isFactory, isBrand } from "Models/UserModels";

interface IProps {
	headerRef: any;
	scrollPadding: number;
}

const OrdersListHeader: React.FC<IProps> = ({ headerRef, scrollPadding }) => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	return (
		<section
			ref={headerRef}
			className={`listing-header ${
				isLabelMaker(account_type_id)
					? "listing-header--label-makers-orders"
					: isFactory(account_type_id)
					? "listing-header--factory"
					: "listing-header--orders"
			}`}
			style={{
				paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
			}}
		>
			{!isLabelMaker(account_type_id) && (
				<span className="listing-header__label"></span>
			)}
			<span className="listing-header__label">{t("Reference number")}</span>
			{!isBrand(account_type_id) && (
				<span className="listing-header__label">{t("Brand")}</span>
			)}
			<span className="listing-header__label">{t("Last edit")}</span>

			{isLabelMaker(account_type_id) && (
				<span className="listing-header__label">{t("Ship to")}</span>
			)}

			<span className="listing-header__label">
				{isLabelMaker(account_type_id) ? t("Actions") : t("Ship to")}
			</span>
			<span className="listing-header__label ordersStatusListItem">
				{t("Status")}
			</span>
		</section>
	);
};

export default OrdersListHeader;
