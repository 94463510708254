import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { isLabelMaker } from "Models/UserModels";
import {
	ILabelImage,
	IOrderState,
	IUnit,
	DEFAULT_STYLE_NAME,
	OrderStatusesFlow,
} from "Models/OrderModels";

interface IProps {
	drawOne?: boolean;
	order: IOrderState;
	currentOrderItemIndex?: number;
	reorderStyleNumber: (styleNumber: string) => void;
}

const LabelImagesPreview: React.FunctionComponent<IProps> = ({
	drawOne = false,
	order,
	currentOrderItemIndex = 0,
	reorderStyleNumber,
}) => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const { orderItems, status, labelTemplate } = order;
	const labelOrientation =
		labelTemplate?.settings?.default_values?.label_orientation;

	let showOrderItems = orderItems;
	if (drawOne) {
		showOrderItems = [orderItems[currentOrderItemIndex]];
	}

	return (
		<>
			{showOrderItems && showOrderItems.length > 0 && (
				<div className="labelImagesContainer">
					{showOrderItems.map((item: any, index: number) => {
						const { units, styleNumber, labelImages } = item;

						let totalQuantityWhite = 0;
						let totalQuantityBlack = 0;
						const imagesPerUnit = labelImages?.length / units.length;

						return (
							<div
								key={`${item.orderItemId}-${index}`}
								className="labelImagesContent"
							>
								<div className="labelImages">
									<div className="row labelImagesInfoContainer">
										<div
											className="pretitle col-sm-6"
											id="reorderStyle"
											style={
												isLabelMaker(account_type_id)
													? { flexDirection: "row" }
													: { flexDirection: "column" }
											}
										>
											{styleNumber ? styleNumber : t(DEFAULT_STYLE_NAME)}
											{status && status >= OrderStatusesFlow.PLACED.code && (
												<div className="reorderStyleButton">
													{!isLabelMaker(account_type_id) && (
														<button
															className="btn-no-style btn-link"
															style={{ letterSpacing: "0.1em" }}
															onClick={() =>
																reorderStyleNumber(item.styleNumber)
															}
														>
															{t("Reorder")}
														</button>
													)}
												</div>
											)}
										</div>

										<div className="col-sm-6" id="sizeInfoContainer">
											{units.map((unit: IUnit, i: number) => {
												const { size, quantity, blackLabel } = unit;
												if (blackLabel) {
													totalQuantityBlack += Number(quantity);
												} else {
													totalQuantityWhite += Number(quantity);
												}

												return (
													<div key={i}>
														<div id="flexElement">
															<div>
																{size ? `${t("Size")}: ${size} ;` : ""}
																{`${
																	blackLabel
																		? t("Black ground label: ")
																		: t("White ground label: ")
																}`}
															</div>
															<div className="orderPreviewQuantity ml--xs">
																{quantity}
															</div>
														</div>
													</div>
												);
											})}
										</div>
										<div
											className={`totalQuantityContainer "totalQuantityContainerWithSizes"
												}`}
										>
											<>
												<div className="totalQuantity" id="totalQuantityWhite">
													{t("Total quantity white:")} {totalQuantityWhite}
												</div>
												<div className="totalQuantity" id="totalQuantityBlack">
													{t("Total quantity black:")} {totalQuantityBlack}
												</div>
											</>
											<div
												className="totalQuantity strong"
												id="totalQuantityAll"
											>
												{t("Total quantity:")}{" "}
												{totalQuantityWhite + totalQuantityBlack}
											</div>
										</div>
									</div>
									{labelImages?.map(
										(imageObject: ILabelImage, index: number) => (
											<div
												key={`${imageObject.name}-${index}`}
												style={{
													width: `${
														!imageObject.image ||
														labelOrientation === "horizontal"
															? "100%"
															: "49.5%"
													}`,
												}}
											>
												<img
													className="labelContainer"
													alt={t("Label")}
													src={imageObject.image}
													style={{
														maxWidth: "100%",
														marginBottom:
															(index + 1) % imagesPerUnit === 0
																? "60px"
																: "10px",
													}}
												/>
											</div>
										)
									)}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</>
	);
};

export default LabelImagesPreview;
