import { deepCopy } from "Utils/utils";
import { useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../react-query/constants";
import { toast } from "react-toastify";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import {
	EndpointPrefix,
	USER_ACCOUNT_TYPE_VALUES,
} from "../../Models/UserModels";
import { IOrderState, OrderStatusesFlow } from "Models/OrderModels";

import useErrorHandler from "Hooks/queryHooks/useErrorHandler";
import { appEnvSymbolsStorageName } from "Models/GlobalModels";

let currentSymbolsStorageName =
	appEnvSymbolsStorageName[
		import.meta.env.VITE_APP_ENV as keyof typeof appEnvSymbolsStorageName
	];

const reformattedInitialValues = (initialValues: any) => {
	let allSymbols = deepCopy(initialValues.careInstructionSymbols);
	const allSymbolsById: any = {};
	for (const symbolSet in allSymbols) {
		allSymbolsById[symbolSet as keyof typeof allSymbolsById] = {}; // Initialize the symbolSet object

		for (const symbolGroup in allSymbols[symbolSet]) {
			const symbolsInGroup = allSymbols[symbolSet][symbolGroup];

			symbolsInGroup.forEach((symbol: any) => {
				let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
				const img = new Image();
				img.src = symbolUrlStorage; // Browser will preload each image

				allSymbolsById[symbolSet][symbol.symbol_id] = symbol; // Assign symbol object to its symbol_id
			});
		}
	}

	return { ...initialValues, allSymbolsById };
};

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getInitialValues(
	account_type_id: USER_ACCOUNT_TYPE_VALUES,
	currentBrandId: any
): Promise<any> {
	url = `${URL_BASE.address}/${EndpointPrefix[account_type_id]}/data/initialValues?brandId=${currentBrandId}`;
	const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
	let data: { status: string; message: any } = await result.json();
	if (data.status === "ok") {
		data.message = reformattedInitialValues(data.message);
	}
	return data;
}

export function useFetchInitialValues(order: IOrderState, brandId: any): any {
	const { setError } = useContext(GlobalDispatch);
	const navigate = useNavigate();

	const fallback: any = {
		madeIns: [],
		fabricContent: [],
		fibers: [],
		careInstructions: [],
		translationLanguages: [],
	};

	const errorHandler = useErrorHandler();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const enabledCriteria = !!(
		brandId &&
		(order.status === OrderStatusesFlow.OPEN.code ||
			order.status === OrderStatusesFlow.CHANGE_REQUESTED.code ||
			order.status === OrderStatusesFlow.APPROVED.code)
	);

	const {
		isLoading,
		data = fallback,
		fetchStatus,
		isError,
		error,
	} = useQuery({
		queryKey: [QUERY_KEYS.INITIAL_VALUES, account_type_id, brandId],
		queryFn: () => getInitialValues(account_type_id, brandId),
		...{
			enabled: enabledCriteria,
			gcTime: 604800000, // 7 days
			staleTime: 86400000, // 1 day
		},
	});

	if (isError) {
		let customError = error as CustomError;
		let errorMessage = errorHandler(
			customError.status,
			customError.message,
			url
		);
		toast.error(`Unable to get initial values. ${errorMessage}`);
		setError({
			title: "Something Went Wrong",
			text: "Please try again later",
			primary: {
				text: "Back to orders",
				action: () => navigate(`/orders`),
			},
			secondary: {
				text: "",
				action: () => {},
			},
		});
	}

	return {
		initialValues: data.message,
		isLoadingInitialValues: isLoading && fetchStatus !== "idle",
	};
}
