import React, { useEffect, useRef, useState } from "react";

import Icon from "./Icon";

interface IProps {
	searchTextValue: string;
	placeholderText: string;

	setSearchText: (searchText: string) => void;
}

const SearchFilter: React.FunctionComponent<IProps> = ({
	searchTextValue,
	placeholderText,

	setSearchText,
}) => {
	const timer = useRef(null) as any;

	const [inputValue, setInputValue] = useState(searchTextValue);

	const searchText = (searchTerm: string) => {
		setSearchText(searchTerm);
	};

	useEffect(() => {
		setInputValue(searchTextValue);
	}, [searchTextValue]);

	const onSearch = (searchTerm: string) => {
		setInputValue(searchTerm);
		clearTimeout(timer.current);

		timer.current = setTimeout(() => {
			searchText(searchTerm);
		}, 500);
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Enter") {
			event.preventDefault();
			searchText(event.target.value);
		}
	};

	return (
		<div className="listing-filters__search">
			<div className="form-group-search form-group-search--light">
				<Icon name="loupe" />
				<input
					className="form-control"
					data-testid="searchFilter"
					type="search"
					name="search"
					placeholder={placeholderText}
					onChange={(event: any) => onSearch(event.target.value)}
					onKeyDown={(event: any) => onKeyDown(event)}
					value={inputValue}
				/>
			</div>
		</div>
	);
};

export default SearchFilter;
