import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { IUser } from "Models/UserModels";
import Loading from "Components/Shared/Loading";
import Logo from "images/logo.svg";
import Popup from "Components/Shared/Popup";
import useDataApi from "Hooks/fetchHook";
import sprite from "images/icons.svg";

interface IProps {
	setUser: (user: IUser) => void;
}

const Login: React.FunctionComponent<IProps> = ({ setUser }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [invalidEmailMessage, setInvalidEmailMessage] = useState("");
	const [invalidPasswordMessage, setInvalidPasswordMessage] = useState("");
	const [viewPassword, setViewPassword] = useState(false);

	const passwordRef = useRef(null) as any;
	const emailRef = useRef(null) as any;

	const useLogin = useDataApi();

	const navigate = useNavigate();
	const location = useLocation() as any;

	useLayoutEffect(() => {
		document.title = "Cacotec labels ordering";
	}, []);

	useEffect(() => {
		if (useLogin.data) {
			const { message: user } = useLogin.data;
			if (user) {
				setUser(user);
				if (location?.state?.destinationPath) {
					navigate(location?.state?.destinationPath);
				} else {
					navigate("/");
				}
			}
		}
	}, [useLogin.data]); // eslint-disable-line react-hooks/exhaustive-deps

	const onLogin = (email: string, password: string) => {
		useLogin.doFetch("/login", { email, password }, "POST");
	};

	const onKeyDown = (e: any) => {
		if (e.key === "Enter") {
			e.preventDefault();
			onSubmit(e);
		}
	};

	const onChangeEmail = (e: any) => {
		setEmail(e.target.value);
		if (emailRef.current.validity.valid) {
			setInvalidEmailMessage("");
		}
	};
	const onChangePassword = (e: any) => {
		setPassword(e.target.value);
		if (e.target.value) {
			setInvalidPasswordMessage("");
		}
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		if (!email) {
			setInvalidEmailMessage("You must enter an email address.");
			setInvalidPasswordMessage("");
		} else if (!emailRef.current.validity.valid) {
			setInvalidEmailMessage("You must enter a valid email address.");
			setInvalidPasswordMessage("");
		} else if (!password) {
			setInvalidPasswordMessage("You must enter a password.");
			setInvalidEmailMessage("");
		} else {
			setInvalidEmailMessage("");
			setInvalidPasswordMessage("");
			onLogin(email.trim(), password);
		}
	};

	return (
		<>
			<Loading
				show={useLogin.isLoading}
				text={"Loading..."}
				imgClass="block-center"
				divClass="col-sm-12"
			/>

			{!useLogin.isLoading && (
				<div className="auth-canvas">
					<main className="auth-content">
						<div className="auth-content__box">
							<form>
								<header>
									<h1 data-testid="loginTitle" className="auth-content__title">
										Log In
									</h1>
								</header>
								{useLogin.error && (
									<p data-testid="loginError" className="text--danger">
										{useLogin.error}
									</p>
								)}
								<div className="form-group">
									<Popup
										show={invalidEmailMessage.length > 0}
										text={invalidEmailMessage}
										testId="login-email"
									/>
									<input
										className="form-control"
										ref={emailRef}
										type="email"
										name="email"
										id="email"
										value={email}
										pattern='^(([^<>\(\)\[\]\.,;:\s@"]+(\.[^<>\(\)\[\]\.,;:\s@"]+)*)|(\.+))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
										onChange={(e) => onChangeEmail(e)}
										onKeyDown={onKeyDown}
										data-empty={!email}
										autoFocus
									/>
									<label className="form-control-label" htmlFor="email">
										Email address
									</label>
								</div>
								<div className="form-group">
									<Popup
										show={invalidPasswordMessage.length > 0}
										text={invalidPasswordMessage}
										testId="login-password"
									/>
									<input
										ref={passwordRef}
										className="form-control"
										type={viewPassword ? "text" : "password"}
										name="password"
										id="password"
										value={password}
										onChange={onChangePassword}
										data-empty={!password}
										onKeyDown={onKeyDown}
										style={{ paddingRight: "28px" }}
									/>
									<label className="form-control-label" htmlFor="password">
										Password
									</label>

									{password.length > 0 && (
										<svg
											className="flex-center-secondary-axis pos-absolute hide-show-password"
											onClick={() =>
												setViewPassword(
													(prevViewPassword: boolean) => !prevViewPassword
												)
											}
											role="button"
										>
											<use
												xlinkHref={`${sprite}#icon-${
													viewPassword ? "eye-hide" : "eye-view"
												}`}
											/>
										</svg>
									)}
								</div>

								{/* <div className="form-toggle mb--base">
                <Field
                  component="input"
                  className="form-check__input"
                  type="checkbox"
                  name="stay-signed-in"
                  id="stay-signed-in-yes"
                  value="yes"
                />
                <label className="form-check__label" htmlFor="stay-signed-in-yes">
                  Stay signed in
                </label>
              </div>
              <div className="form-group mb--md">
                <Link to="/reset-password">
                  <small>Forgot your password?</small>
                </Link>
              </div> */}
								<div className="form-actions mt--md">
									<button
										data-testid="loginButton"
										className="button button--primary button--square button--block button--lg"
										onClick={onSubmit}
									>
										Log In
									</button>
								</div>
							</form>
						</div>

						<br />
						<br />
						<br />

						<figure className="txtc mt-md">
							<img
								className="semi-transparent"
								src={Logo}
								alt=""
								width="100"
								height=""
							/>
						</figure>
					</main>
				</div>
			)}
		</>
	);
};

export default Login;
