import { useContext } from "react";

import { OrderStatusesFlow } from "Models/OrderModels";
import { isBrand } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { useTranslation } from "react-i18next";

interface IProps {
	filteredBrand: any;
	filteredStatus: any;
	filteredAccountExecutive?: any;
	accountExecutivesInfo?: any[];
	accountExecutiveBrands?: any[];

	setFilteredBrand: (brand: any) => void;
	setFilteredStatus: (filterStatus: any) => void;
	setFilteredAccountExecutive: (filterAccountExecutive: any) => void;
	setSelectedOrdersForBulkAction: (selectedOrders: any) => void;
}

const OrdersHeaderFilters = ({
	filteredBrand,
	filteredStatus,
	filteredAccountExecutive,
	accountExecutivesInfo = [],
	accountExecutiveBrands = [],

	setFilteredBrand,
	setFilteredStatus,
	setFilteredAccountExecutive,
	setSelectedOrdersForBulkAction,
}: IProps) => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	return (
		<section className="listing-filters" id="filters">
			<div className="listing-filters__options" id="filtersOptions">
				{!isBrand(account_type_id) && (
					<>
						<div className="form-group" id="filterContainer">
							<label
								className="listing-header__label filterLabel"
								htmlFor="brandFilter"
								style={{ flexShrink: 0 }}
							>
								{t("Account executive")}
							</label>
							<select
								className="form-control"
								id="brandFilter"
								value={
									filteredAccountExecutive ? filteredAccountExecutive : "All"
								}
								style={{ padding: "0px 5px 1px 5px" }}
								onChange={(event) => {
									setFilteredAccountExecutive(event.target.value);
									setFilteredBrand(undefined);
								}}
							>
								<option value="">{t("All")}</option>
								{accountExecutivesInfo &&
									accountExecutivesInfo.map((accExec: any) => (
										<option key={accExec.user_id} value={accExec.user_id}>
											{accExec.user_name}
										</option>
									))}
							</select>
						</div>
						<div className="form-group" id="filterContainer">
							<label
								className="listing-header__label filterLabel white-space-no-wrap"
								htmlFor="brandFilter"
							>
								{t("Brand")}
							</label>
							<select
								className="form-control"
								id="brandFilter"
								value={filteredBrand ? filteredBrand : "All"}
								style={{ padding: "0px 5px 1px 5px" }}
								onChange={(event) => {
									setSelectedOrdersForBulkAction({});
									setFilteredBrand(event.target.value);
								}}
							>
								<option value="">{t("All")}</option>
								{accountExecutiveBrands &&
									accountExecutiveBrands.map((brand: any) => (
										<option key={brand.brand_id} value={brand.brand_id}>
											{brand.brand_name}
										</option>
									))}
							</select>
						</div>
					</>
				)}

				<div className="form-group" id="filterContainer">
					<label
						className="listing-header__label filterLabel white-space-no-wrap"
						htmlFor="statusFilter"
					>
						{t("Status")}
					</label>
					<select
						className="form-control "
						data-testid="ordersStatusFilter"
						id="statusFilter"
						value={filteredStatus ? filteredStatus : "All"}
						style={{ padding: "0px 5px 1px 5px" }}
						onChange={(event) => {
							setSelectedOrdersForBulkAction({});
							setFilteredStatus(event.target.value);
						}}
					>
						<optgroup label={t("General")}>
							<option value=""> {t("All")}</option>
							{Object.values(OrderStatusesFlow)
								.slice(0, 4)
								.map((status: any) => (
									<option key={status.code} value={status.code}>
										{t(status.name)}
									</option>
								))}
						</optgroup>
						<optgroup label={t("Shipping Orders")}>
							{Object.values(OrderStatusesFlow)
								.slice(4, 10)
								.map((status: any) => (
									<option key={status.code} value={status.code}>
										{t(status.name)}
									</option>
								))}
						</optgroup>
						<optgroup label={t("Print Orders")}>
							{Object.values(OrderStatusesFlow)
								.slice(10, 19)
								.map((status: any) => (
									<option key={status.code} value={status.code}>
										{t(status.name)}
									</option>
								))}
						</optgroup>
					</select>
				</div>
			</div>
		</section>
	);
};
export default OrdersHeaderFilters;
