import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom-v5-compat";

import { EndpointPrefix, IUser } from "Models/UserModels";
import useDataApi from "Hooks/fetchHook";
import {
	demoImages,
	firstRowInfo,
	secondRowInfo,
	thirdRowInfo,
} from "./demoUtils";
import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";
import "./styles/Demo.css";

interface IProps {
	user: IUser;

	onLogout: () => void;
}

const ORDER_ID = 958;

const Demo: React.FunctionComponent<IProps> = ({ user, onLogout }) => {
	const useGetOrder = useDataApi();
	const useGetInitialValues = useDataApi();
	const useReorder = useDataApi();

	const [garments, setGarments] = useState([]);
	const [log, setLog] = useState({
		createdBy: "",
		doneAt: "",
		lastChangeBy: "",
		lastChangeAt: "",
	});
	const [currentGarmentId, setCurrentGarmentId] = useState(undefined) as any;

	const [initialValues, setInitialValues] = useState({
		madeIns: [],
		fabricContent: [],
		fibers: [],
		careInstructions: [],
		translationLanguages: [],
	});

	const { account_type_id, brand_id } = user;

	useEffect(() => {
		if (brand_id)
			useGetInitialValues.doFetch(
				`/${EndpointPrefix[account_type_id]}/data/initialValues?brandId=${brand_id}`
			);
		useGetOrder.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/${ORDER_ID}`
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetInitialValues;
		if (data && data.message) {
			setInitialValues(data.message);
		}
	}, [useGetInitialValues.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetOrder;
		if (data.message && data.message.order_items) {
			const garmentsWithPhotos = data.message.order_items.map(
				(garment: any, i: number) => ({ ...garment, photo: demoImages()[i] })
			);
			setGarments(garmentsWithPhotos);
		}
		if (
			data.message &&
			data.message.log &&
			data.message.log[0] &&
			data.message.log[1]
		) {
			const { name: createdBy, done_at: doneAt } = data.message.log[1];
			const { name: lastChangeBy, done_at: lastChangeAt } = data.message.log[0];
			setLog({
				createdBy,
				doneAt,
				lastChangeBy,
				lastChangeAt,
			});
		}
	}, [useGetOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	const sendToCacotec = (styleNumber: string, order_item_id: number) => {
		useReorder.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/copy`,
			{ order_id: ORDER_ID, style_numbers: [styleNumber] },
			"POST"
		);
		setCurrentGarmentId(order_item_id);
	};

	const { madeIns, fabricContent, careInstructions } = initialValues;
	const { org_logo, org_name } = user;
	const { createdBy, doneAt, lastChangeBy, lastChangeAt } = log;

	return (
		<div style={{ width: "100%", backgroundColor: " #F5FAFA" }}>
			<Loading
				show={useGetOrder.isLoading || useGetInitialValues.isLoading}
				text={`Loading...`}
				imgClass="block-center"
				divClass="col-sm-12"
			/>

			<header className="demoHeader">
				<figure className="mb--base" style={{ margin: "10px" }}>
					<img src={org_logo} alt="" width="150" />
				</figure>
				<Link className="logoutDemo" onClick={onLogout} to="/login">
					<Icon name="off" className="logoutDemoIcon" />
				</Link>
			</header>

			<div className="garmentsContainer">
				{garments.map((garment: any) => {
					const {
						order_item_id,
						photo,
						style_number,
						units,
						fabric_content,
						made_in,
						care_instructions,
						style_description,
					} = garment;
					const madeInOption = madeIns.find(
						(madeInObject: any) => madeInObject.id === made_in
					);
					const fabricContentOption = fabricContent.find(
						(fabricContentObject: any) =>
							fabricContentObject.id === fabric_content
					);
					const careInstructionsList = care_instructions.map((id: any) => {
						const careInstruction = careInstructions.find(
							(careInstruction: any) => careInstruction.id === id
						) as any;
						let careName = "";
						if (careInstruction) {
							careName = careInstruction.name;
						}
						return careName;
					});

					return (
						<div className="row" id="garmentContainer" key={order_item_id}>
							<div
								className="col-sm-12"
								id="garmentName"
							>{`MTC${order_item_id} - ${style_description}`}</div>
							<div className="col-sm-3">
								<div>
									<img src={photo} alt="" width="100%" />
								</div>
							</div>
							<div className="col-sm-3" id="infoContainer">
								{firstRowInfo(
									org_name,
									order_item_id,
									style_number,
									style_description
								).map((info: any, i: number) => {
									return (
										<div
											className="row"
											id={info.className}
											key={`${style_number}${i}`}
										>
											<div className="col-sm-5" id="infoName">
												{info.name}
											</div>
											<div className="col-sm-7">{info.value}</div>
										</div>
									);
								})}
							</div>

							<div className="col-sm-3" id="infoContainer">
								{secondRowInfo(units, lastChangeBy, lastChangeAt).map(
									(info: any) => {
										return (
											<div className="row" id={info.className} key={info.name}>
												<div className="col-sm-6" id="infoName">
													{info.name}
												</div>
												<div className="col-sm-6">{info.value}</div>
											</div>
										);
									}
								)}
							</div>
							<div className="col-sm-3" id="infoContainerWithButton">
								<div>
									{thirdRowInfo(
										createdBy,
										doneAt,
										fabricContentOption,
										madeInOption,
										careInstructionsList
									).map((info: any) => {
										return (
											<div className="row" id={info.className} key={info.name}>
												<div className="col-sm-5" id="infoName">
													{info.name}
												</div>
												<div className="col-sm-7">{info.value}</div>
											</div>
										);
									})}
								</div>

								<div id="sendToCacotecContainer">
									<Loading
										show={
											useReorder.isLoading && currentGarmentId === order_item_id
										}
										text={`Loading...`}
										imgClass="saveOrderLoading"
										divClass=""
									/>

									{useReorder.data &&
										useReorder.data.message &&
										currentGarmentId === order_item_id && (
											<div>
												<svg
													className="checkmark"
													style={{ margin: "10px auto" }}
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 52 52"
												>
													<circle
														className="checkmark__circle"
														cx="26"
														cy="26"
														r="25"
														fill="none"
													/>
													<path
														className="checkmark__check"
														fill="none"
														d="M14.1 27.2l7.1 7.2 16.7-16.8"
													/>
												</svg>
												<h3 className="txtc">Garment sent to Cacotec!</h3>
											</div>
										)}

									{currentGarmentId !== order_item_id && (
										<button
											className="sendToCacotecButton"
											onClick={() => sendToCacotec(style_number, order_item_id)}
										>
											{" "}
											Send to cacotec
										</button>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Demo;
