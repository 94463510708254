import React from "react";

import placeOrderSummary from "./images/place-order-summary.png";
import placeOrderSummary2 from "./images/place-order-summary-2.png";
import orderPlacedConfirmation from "./images/order-placed-confirmation.png";
import usePageTitle from "Hooks/pageTitleHook";

const PlacingTheOrderHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Placing the order`);

	return (
		<>
			<h2 className="helpTitle">Placing the order</h2>
			<article className="helpArticle">
				<p>
					Once you are ready to place the order, you can click on the{" "}
					<b>Place</b> button on top. This action will bring up a wizzard where
					you can select shipping and billing information.
				</p>
				<img
					src={placeOrderSummary}
					className="helpImageSmall"
					alt="Place order summary"
				/>
				<p>
					Once you select all the information, you will be able to move to the
					next section where you will see the overview.
				</p>
				<img
					src={placeOrderSummary2}
					className="helpImageSmall"
					alt="Place order summary"
				/>
				<p>
					If you need to change something, click on <b>Back</b> to go back and
					update the information.
				</p>
				<p>
					If you are ready to place, go ahead and click on the <b>Place</b>{" "}
					button.
				</p>
				<img
					src={orderPlacedConfirmation}
					className="helpImageSmall"
					alt="Order placed"
				/>
				<p>
					Everyone involved in this order will get an email notification that
					order has been placed by you.
				</p>
				<p>
					<b>Note: </b>You will also get email notifications for each status
					update of the order you participated in (by creating or updating).
				</p>
			</article>
		</>
	);
};

export default PlacingTheOrderHelp;
