import { useRef, useCallback, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Icon from "Components/Shared/Icon";
import useOnClickOutside from "Hooks/outsideClickHook";
import useOnKeyDown from "Hooks/onKeyDownHook";

interface IProps {
	showPrinterEditModal: any;

	setShowPrinterEditModal: React.Dispatch<React.SetStateAction<any>>;
	setChosenPrinterForEditing: React.Dispatch<React.SetStateAction<any>>;
}

const PrinterEditModalForPrintBatchOrOrder: FC<IProps> = ({
	showPrinterEditModal,

	setShowPrinterEditModal,
	setChosenPrinterForEditing,
}) => {
	const { t } = useTranslation(); // Destructure t from useTranslation
	const modalRef = useRef(null) as any;

	const [newPrinterSettings, setNewPrinterSettings] = useState(
		showPrinterEditModal.info
	);

	const onCancelPrinterEditModal = () => {
		// if (window.confirm("Are you sure?")) {
		setShowPrinterEditModal(undefined);
		// }
	};

	const onSavePrinterEditModal = () => {
		setChosenPrinterForEditing((prevChosenPrinter: any) => {
			let settings = prevChosenPrinter.settings;
			let newSettings = {
				...settings,
				[showPrinterEditModal.item]: newPrinterSettings,
			};

			return {
				...prevChosenPrinter,
				settings: newSettings,
			};
		});
		setShowPrinterEditModal(undefined);
	};

	/* *******************************************************************************/

	useOnKeyDown("Escape", onCancelPrinterEditModal);

	/* *******************************************************************************/
	useOnClickOutside(
		modalRef,
		useCallback(onCancelPrinterEditModal, [setShowPrinterEditModal])
	); // eslint-disable-line react-hooks/exhaustive-deps

	const widthInputRef = useRef<HTMLInputElement>(null);

	let isEnabled = widthInputRef?.current?.validity.valid;

	return (
		<div className="modal flex-center-both-axis">
			<div
				className="modal-content-order-summary show"
				style={{ width: "600px" }}
				ref={modalRef}
				tabIndex={1}
			>
				<div className="flex row reverse" style={{ height: "40px" }}>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label={t("Close")}
						onClick={onCancelPrinterEditModal}
						style={{ position: "relative" }}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>
				<div className="flex-center-both-axis">
					<h1>
						{t("Edit")} {t(showPrinterEditModal.item)} {t("info")}
					</h1>
				</div>
				<div className="pd--base">
					<div className="mb--base">
						{showPrinterEditModal.item === "paper" && (
							<div className="flex-1">
								<div className="upcase strong">{t("Material")}</div>
								<select
									value={newPrinterSettings.material}
									className="form-control"
									onChange={(e) => {
										setNewPrinterSettings((prevInfo: any) => {
											return {
												...prevInfo,
												material: e.target.value,
											};
										});
									}}
								>
									<option value="nylon">{t("Nylon")}</option>
									<option value="silk">{t("Silk")}</option>
									<option value="polyester">{t("Polyester")}</option>
								</select>
							</div>
						)}

						{showPrinterEditModal.item === "ribbon" && (
							<div className="flex-1">
								<div className="upcase strong">{t("Name")}</div>
								<input
									type="text"
									defaultValue={showPrinterEditModal.info.name}
									className="form-control"
									onChange={(e) => {
										setNewPrinterSettings((prevInfo: any) => {
											return { ...prevInfo, name: e.target.value };
										});
									}}
								/>
							</div>
						)}
					</div>
					<div className="flex">
						<div className="flex-1" style={{ paddingRight: "10px" }}>
							<div className="upcase strong">{t("Width (mm)")}</div>
							<input
								type="number"
								className="form-control"
								ref={widthInputRef}
								step={0.1}
								defaultValue={showPrinterEditModal.info.width}
								onChange={(e) => {
									setNewPrinterSettings((prevInfo: any) => {
										return { ...prevInfo, width: parseFloat(e.target.value) };
									});
								}}
							/>
						</div>
						<div className="flex-1" style={{ paddingLeft: "10px" }}>
							<div className="upcase strong">{t("Color")}</div>
							<select
								value={newPrinterSettings.color}
								className="form-control"
								style={{ marginTop: "3px" }}
								onChange={(e) => {
									setNewPrinterSettings((prevInfo: any) => {
										return { ...prevInfo, color: e.target.value };
									});
								}}
							>
								<option value="white">{t("White")}</option>
								<option value="black">{t("Black")}</option>
								<option value="silver">{t("Silver")}</option>
							</select>
						</div>
					</div>
				</div>
				<div
					className="flex mt--base"
					style={{ justifyContent: "space-evenly" }}
				>
					<button
						className={`button button--primary button--lg mb--lg ${
							!isEnabled && "button--disabled"
						}`}
						onClick={onSavePrinterEditModal}
					>
						{t("Save")}
					</button>
					<button
						className="button button--primary button--lg mb--lg"
						onClick={onCancelPrinterEditModal}
					>
						{t("Cancel")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default PrinterEditModalForPrintBatchOrOrder;
