import { Draggable, Droppable } from "@hello-pangea/dnd";
import { t } from "i18next";
import Icon from "Components/Shared/Icon";
import { capitalize } from "Utils/utils";
import { appEnvSymbolsStorageName } from "Models/GlobalModels";

interface IProps {
	dragStarted: any;
	groupSelectedSymbols: any;
	symbolGroupName: string;
	groupDefaultSymbol: any;
	symbolSet: string;

	removeSymbol: (symbol: any) => void;
	onSelectDefaultSymbol: (symbolGroupName: string) => void;
}

function SelectedSymbolGroup({
	dragStarted,
	groupSelectedSymbols,
	symbolGroupName,
	groupDefaultSymbol,
	symbolSet,

	removeSymbol,
	onSelectDefaultSymbol,
}: IProps) {
	let disableSelectedSymbolGroup = () => {
		if (dragStarted?.started) {
			if (dragStarted?.result?.source?.droppableId.includes("all")) {
				return (
					dragStarted?.result?.source?.droppableId !== `${symbolGroupName}-all`
				);
			} else {
				return dragStarted?.result?.source?.droppableId !== symbolGroupName;
			}
		}
	};

	let isOnlyDefaultSymbolChosen = () => {
		return (
			groupSelectedSymbols.length === 1 &&
			groupSelectedSymbols[0].symbol_id === groupDefaultSymbol.symbol_id
		);
	};

	let currentSymbolsStorageName =
		appEnvSymbolsStorageName[
			import.meta.env.VITE_APP_ENV as keyof typeof appEnvSymbolsStorageName
		];

	return (
		<div>
			<Droppable
				droppableId={symbolGroupName}
				direction="horizontal"
				isDropDisabled={disableSelectedSymbolGroup()}
			>
				{(provided) => (
					<div
						className={`flex flex-column box pd--xs box--bordered ${
							disableSelectedSymbolGroup() &&
							"selected-care-instruction-symbols-container-droppable"
						}`}
						style={{
							width: "180px",
							height: "95px",
							cursor: disableSelectedSymbolGroup() ? "no-drop" : "inherit",
						}}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						<div className="flex-center-primary-axis" style={{ color: "#999" }}>
							<div>{t(capitalize(symbolGroupName))}</div>
						</div>
						<div className="flex-1 flex-center-both-axis">
							{groupSelectedSymbols.map((symbol: any, index: number) => {
								let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
								return (
									<Draggable
										key={symbol.symbol_name + index}
										draggableId={symbol.symbol_name}
										index={index}
									>
										{(provided) => (
											<div
												className=""
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<div
													key={symbol.symbol_name}
													className="symbol-item"
													style={{ cursor: "move" }}
												>
													<img
														src={symbolUrlStorage}
														width={32}
														height={43}
														style={{
															margin: "2px 5px",
														}}
														title={symbol.display_name}
													/>
													<button
														className="symbol-item-remove btn-no-style"
														onClick={() => removeSymbol(symbol)}
													>
														<Icon name="cross-rounded-filled" />
													</button>
												</div>
											</div>
										)}
									</Draggable>
								);
							})}
						</div>

						{provided.placeholder}
					</div>
				)}
			</Droppable>
			<div className="flex-center-primary-axis">
				<img
					src={`https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${groupDefaultSymbol.symbol_name}`}
					width={32}
					height={43}
					style={{
						margin: "2px 5px",
						opacity: isOnlyDefaultSymbolChosen() ? 0.2 : 1,
						cursor: isOnlyDefaultSymbolChosen() ? "default" : "pointer",
					}}
					title={`${
						isOnlyDefaultSymbolChosen()
							? t("Default symbol chosen")
							: t("Choose only default symbol")
					}: ${groupDefaultSymbol.display_name}`}
					onClick={() => {
						onSelectDefaultSymbol(symbolGroupName);
					}}
				/>
			</div>
		</div>
	);
}

export default SelectedSymbolGroup;
