import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import Icon from "Components/Shared/Icon";

interface IProps {
	detail: any;
	inputLabelText: string;
	options: any;
	detailName: string;

	setDetail: (detail: any) => void;
	setShowAddressForm: (showAddressForm: boolean) => void;
}

const ChooseDetailsOption: React.FunctionComponent<IProps> = ({
	detail,
	inputLabelText,
	options,
	detailName,

	setDetail,
	setShowAddressForm,
}) => {
	const { t } = useTranslation();
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);
	const [showOptionName, setShowOptionName] = useState(false);
	const [showOptionInput, setShowOptionInput] = useState(false);
	const [chosenOption, setChosenOption] = useState(undefined) as any;

	const convertedOptionsForIncremental = options
		? options.map((optionObject: any, index: number) => ({
				...optionObject,
				id: `${optionObject.name}${index}`,
		  }))
		: [];

	const incrementalSearchHookProps = useIncrementalSearchHook(
		convertedOptionsForIncremental
	);

	const { setInputText, showList, setShowList } = incrementalSearchHookProps;

	useEffect(() => {
		if (detail) {
			setShowOptionInput(false);
			setShowOptionName(true);
			setChosenOption({ ...detail, id: detail.name });
			setInputText(detail.name);
		} else {
			setChosenOption(undefined);
		}
	}, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

	const onEditOption = () => {
		setInputText("");
		setShowOptionInput(true);
		setShowOptionName(false);
	};

	const onChangeInputText = () => {
		setDetail(undefined);
		setChosenOption(undefined);
	};

	const onChooseItemCallback = (item: any) => {
		setInputText(item.name);
		setChosenOption(item);
		setDetail(item);
		setShowOptionInput(false);
		setShowOptionName(true);
	};

	const onChooseItemFromList = (item: any) => {
		onChooseItemCallback(item);
		setShowList(false);
	};

	const onSaveOption = (item: any) => {
		if (!item && !showList) {
			setDetail(undefined);
			setShowOptionInput(false);
			setChosenOption(undefined);
		} else if (item?.name) {
			onChooseItemCallback(item);
		}
	};

	return (
		<>
			{!showOptionInput && !chosenOption && (
				<div className="input--choose-option">
					<div className="upcase text--quiet heading--choose-option">
						{inputLabelText}
					</div>
					<button
						className="btn-no-style btn-link"
						onClick={() => {
							return options.length > 0
								? onEditOption()
								: setShowAddressForm(true);
						}}
					>
						{options.length > 0 ? (
							<span>{inputLabelText}</span>
						) : (
							<div className="flex mt--xs">
								<div className="blueIcon">
									<Icon
										className="icon mr--xs valign-middle"
										name="plus-rounded"
									/>
								</div>
								<div>{t("Add New Address")}</div>
							</div>
						)}
					</button>
				</div>
			)}

			{showOptionInput && (
				<div className="form-group mb-sm incremental--choose-option">
					<IncrementalSearchInput
						labelText={inputLabelText}
						name={detailName}
						setFocusOnSearchList={setFocusOnSearchList}
						onChangeInputText={onChangeInputText}
						onInputBlur={onSaveOption}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>

					<IncrementalSearchResults
						focusOnSearchList={focusOnSearchList}
						testId={detailName}
						onChooseItemCallback={onChooseItemFromList}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>
				</div>
			)}

			{detail && showOptionName && (
				<div className="input--choose-option">
					<div className="upcase text--quiet heading--choose-option">
						{inputLabelText}
					</div>
					<div className="flex between-lg">
						<small className="result--choose-option shipping-modal-address">
							{chosenOption
								? chosenOption.name
										.split("; ")
										.map((addressPart: any, index: number) => (
											<div key={`${addressPart}-${index}`}>{addressPart}</div>
										))
								: ""}
						</small>
						<small className="flex">
							<button
								className="btn-no-style btn-link white-space-no-wrap"
								onClick={onEditOption}
							>
								{t("Change")}
							</button>
						</small>
					</div>
				</div>
			)}
		</>
	);
};

export default ChooseDetailsOption;
