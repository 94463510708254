import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import sprite from "images/icons.svg";
import { EndpointPrefix } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import useDataApi from "Hooks/fetchHook";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { PrinterStatuses } from "Models/OrderModels";
import { capitalize } from "Utils/utils";

interface IProps {
	chosenPrinter: any;

	setShowPrinterEditModal: React.Dispatch<React.SetStateAction<any>>;
	fetchPrinters: () => void;
	setShowDrawer: React.Dispatch<React.SetStateAction<any>>;
}

const PrintersListInfo: FC<IProps> = ({
	chosenPrinter,

	setShowPrinterEditModal,
	fetchPrinters,
	setShowDrawer,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);
	const { t } = useTranslation(); // Destructure t from useTranslation

	const [isLoadingConfirm, setIsLoadingConfirm] = useState<any>(undefined);

	const useGetPrinter = useDataApi();
	const useCheckIsPrinterOnline = useDataApi();
	const useConfirmPrinterMaterialChange = useDataApi();
	const usePrintTestLabel = useDataApi();
	const useRemoveStackerError = useDataApi();
	const useClearPrinterError = useDataApi();

	// Check if printer is online START **************************************

	useEffect(() => {
		const { data } = useCheckIsPrinterOnline;
		if (data && data.message) {
			toast.success(t("Printer is back online."));
			fetchPrinters();
			setShowDrawer(false);
		}
	}, [useCheckIsPrinterOnline.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useCheckIsPrinterOnline;

		if (error && error === t("Printer is offline")) {
			toast.error(t("Printer is still offline!"));
		} else if (error) {
			toast.error(
				t("Unable to check if printer is online. {{error}}", { error })
			);
		}
	}, [useCheckIsPrinterOnline.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const checkIsPrinterOnline = () => {
		useCheckIsPrinterOnline.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/checkOnline`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Check if printer is online END **************************************

	// Confirm printer material change START **************************************

	useEffect(() => {
		const { data } = useConfirmPrinterMaterialChange;
		if (data && data.message) {
			setIsLoadingConfirm(undefined);
			fetchPrinters();
			setShowDrawer(false);
			toast.success(t("Printer material successfully changed."));
		}
	}, [useConfirmPrinterMaterialChange.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useConfirmPrinterMaterialChange;

		if (error) {
			setIsLoadingConfirm(undefined);
			toast.error(
				t("Unable to confirm printer material change. {{error}}", { error })
			);
		}
	}, [useConfirmPrinterMaterialChange.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const onConfirmMaterialChange = (item: string) => {
		setIsLoadingConfirm(item);
		useConfirmPrinterMaterialChange.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/confirmChange`,
			{
				printerId: chosenPrinter.id,
				settings: chosenPrinter.settings,
				item,
			},
			"POST"
		);
	};

	// Confirm printer material change END **************************************

	// Print test label START **************************************

	useEffect(() => {
		const { data } = usePrintTestLabel;
		if (data && data.message) {
			let printerInfo = data.message;
			if (printerInfo?.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code) {
				toast.error(t("Unable to print test label."));
			} else {
				toast.success(t("Test label successfully printed."));
			}
		}
	}, [usePrintTestLabel.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = usePrintTestLabel;

		if (error) {
			toast.error(t("Unable to print test label. {{error}}", { error }));
		}
	}, [usePrintTestLabel.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const printTestLabel = () => {
		usePrintTestLabel.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printTestLabel`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Print test label END **************************************

	// Remove stacker full START **************************************

	useEffect(() => {
		const { data } = useRemoveStackerError;
		if (data && data.message) {
			let printerInfo = data.message;
			if (printerInfo?.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code) {
				toast.error(t("Unable to remove stacker error status."));
			} else {
				toast.success(t("Stacker error status successfully removed."));
			}
			fetchPrinters();
			setShowDrawer(false);
		}
	}, [useRemoveStackerError.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useRemoveStackerError;

		if (error) {
			toast.error(
				t("Unable to remove stacker error status. {{error}}", { error })
			);
		}
	}, [useRemoveStackerError.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const removeStackerFull = () => {
		useRemoveStackerError.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/removeStackerError`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Remove stacker full END **************************************

	// Remove unknown error START **************************************
	useEffect(() => {
		const { data } = useClearPrinterError;
		if (data && data.message) {
			let printerInfo = data.message;
			if (printerInfo?.status_code !== PrinterStatuses.READY.code) {
				toast.error(t("Unable to clear printer error."));
			} else {
				toast.success(t("Printer error successfully cleared."));
			}
			fetchPrinters();
			setShowDrawer(false);
		}
	}, [useClearPrinterError.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useClearPrinterError;

		if (error) {
			toast.error(t("Unable to clear printer error. {{error}}", { error }));
		}
	}, [useClearPrinterError.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const clearPrinterError = () => {
		useClearPrinterError.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/clearPrinterError`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Remove unknown error END **************************************

	const printerReady =
		chosenPrinter?.status_code === PrinterStatuses.READY.code;

	const printerPrinting =
		chosenPrinter?.status_code === PrinterStatuses.PRINTING.code ||
		usePrintTestLabel.isLoading;

	const printerHasError =
		chosenPrinter?.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code;

	const printerOffline =
		chosenPrinter?.status_code === PrinterStatuses.ERROR_OFFLINE.code;

	const printerOutOfRibbon =
		chosenPrinter?.status_code === PrinterStatuses.ERROR_OUT_OF_RIBBON.code;

	const printerOutOfPaper =
		chosenPrinter?.status_code === PrinterStatuses.ERROR_OUT_OF_PAPER.code;

	const printerInternalErrors = [
		PrinterStatuses.ERROR_QUANTITY.code,
		PrinterStatuses.ERROR_SYNTAX.code,
		PrinterStatuses.ERROR_UNKNOWN.code,
	].includes(chosenPrinter?.status_code);

	const printerIconColor = () => {
		if (printerHasError) {
			return "red";
		} else if (printerPrinting) {
			return "#2f80ed";
		} else if (printerReady) {
			return "rgb(0 194 0)";
		}
	};

	const printerIconTitle = () => {
		if (printerOffline) {
			return t("Printer offline");
		} else if (!printerOffline && usePrintTestLabel.isLoading) {
			return t("Printing");
		} else if (!printerOffline && !usePrintTestLabel.isLoading) {
			return Object.values(PrinterStatuses).find((printerStatus: any) => {
				return printerStatus.code === chosenPrinter?.status_code;
			})?.name;
		}
	};

	let animatePrinterIcon =
		(usePrintTestLabel.isLoading ||
			useCheckIsPrinterOnline.isLoading ||
			printerPrinting) &&
		"printer-info-print-icon-printing";

	return (
		<div
			style={{
				height: "100%",
				overflow: "auto",
			}}
		>
			<div
				style={{ height: "112px" }}
				className="flex flex-center-secondary-axis ml--base text--lg"
			>
				<>{t("Printer info")}</>
			</div>

			<div
				style={{
					height: "100%",
					overflow: "auto",
				}}
			>
				<Loading
					show={useGetPrinter.isLoading}
					imgClass={""}
					divClass={"flex flex-center-both-axis"}
				/>

				{!useGetPrinter.isLoading && (
					<>
						<div style={{ borderBottom: "5px solid #DFDFDF" }}>
							<div className="flex flex-center-both-axis ">
								<h1
									className="pos-relative flex flex-center-both-axis "
									style={{ height: "60px" }}
								>
									<span>{chosenPrinter?.name}</span>
									<span
										title={printerIconTitle()}
										className="pos-absolute"
										style={{ right: "-100px" }}
									>
										<svg
											className={`printer-info-print-icon ${animatePrinterIcon}`}
											style={{
												fill: `${printerIconColor()}`,
											}}
										>
											<use xlinkHref={`${sprite}#icon-printer-online`} />
										</svg>
									</span>
								</h1>
								{printerOffline && (
									<div
										className={`mb--sm ml--base flex flex-center-secondary-axis`}
										style={{ marginLeft: "120px" }}
										onClick={
											!useCheckIsPrinterOnline.isLoading
												? checkIsPrinterOnline
												: () => {}
										}
									>
										{!useCheckIsPrinterOnline.isLoading && (
											<div
												title={t("Reconnect")}
												className="flex check-network cursor-pointer"
											>
												<span>
													<Icon name="refresh" className="toolbarIcon" />
												</span>
												<span className="ml--xs">{t("Reconnect")}</span>
											</div>
										)}
										{useCheckIsPrinterOnline.isLoading && (
											<span style={{ width: "100px" }}>{t("Checking...")}</span>
										)}
									</div>
								)}
							</div>

							<div className="flex flex-center-both-axis">
								<h3
									style={{
										color: `${
											chosenPrinter?.status_code <
											PrinterStatuses.ERROR_OUT_OF_PAPER.code
												? "initial"
												: "red"
										}`,
									}}
								>
									{t("Printer status:")}{" "}
									{usePrintTestLabel.isLoading
										? t("Printing")
										: t(
												Object.values(PrinterStatuses).find(
													(printerStatus: any) => {
														return (
															printerStatus.code === chosenPrinter?.status_code
														);
													}
												)?.name || ""
										  )}
								</h3>
							</div>
							{printerInternalErrors && (
								<div className="flex flex-center-both-axis mb--base">
									<div style={{ color: "red" }}>
										{t("Printer internal error")}
									</div>
								</div>
							)}
							{chosenPrinter?.status_code ===
								PrinterStatuses.ERROR_CONNECTION_LOST.code && (
								<div className="flex flex-center-both-axis mb--base">
									<div style={{ color: "red" }}>
										{t("Please contact Cacotec support at support@cacotec.com")}
									</div>
								</div>
							)}
							{printerOffline && (
								<div className="flex flex-center-both-axis mb--base">
									<div style={{ color: "red" }}>
										{t(
											"Please check printer internet connection and click Reconnect"
										)}
									</div>
								</div>
							)}
							<div className="flex flex-center-both-axis mb--base">
								{usePrintTestLabel.isLoading ? (
									<Loading
										show={true}
										text={t("Loading...")}
										imgClass="printerInfoLoading"
										divClass=""
									/>
								) : (
									<div
										className={`button button--primary button--md  ${
											(printerHasError || printerPrinting) && "button--disabled"
										}`}
										onClick={printTestLabel}
									>
										{t("Print test label")}
									</div>
								)}
							</div>
							{chosenPrinter?.status_code >=
								PrinterStatuses.ERROR_STACKER_FULL.code && (
								<div className="flex flex-center-both-axis">
									<div
										className="button button--primary button--md mb--base"
										onClick={removeStackerFull}
									>
										{chosenPrinter?.status_code ===
										PrinterStatuses.ERROR_STACKER_FULL.code
											? t("Remove stacker full")
											: t("Remove cutter cover open")}
									</div>
								</div>
							)}
							{printerInternalErrors && (
								<div className="flex flex-center-both-axis">
									<div
										className="button button--primary button--md mb--base"
										onClick={clearPrinterError}
									>
										{t("Clear printer error")}
									</div>
								</div>
							)}
						</div>
						<div className="pd--base">
							<span className="strong upcase text--sm">
								{t("Printing type")}:
							</span>
							<span className="ml--base">
								{chosenPrinter?.settings?.double_sided
									? t("Double sided")
									: t("One sided")}
							</span>

							{/* Ribbon START *************************** */}
							<p
								className="mt--md"
								style={{ color: `${printerOutOfRibbon ? "red" : "inherit"}` }}
							>
								<span className="strong upcase text--sm">{t("Ribbon")}</span>
								{printerOutOfRibbon && (
									<span className="ml--base">
										{t("Please change ribbon and click Confirm")}
									</span>
								)}
							</p>
							<div className="flex">
								<div className="flex-4">
									<div className="flex pd--xs strong">
										<div className="flex-2">{t("Name")}</div>
										<div className="flex-2">{t("Width (mm)")}</div>
										<div className="flex-2">{t("Color")}</div>
									</div>
									<div className="flex" style={{ padding: "0 5px" }}>
										<div className="flex-2">
											{chosenPrinter?.settings?.ribbon.name}
										</div>
										<div className="flex-2">
											{chosenPrinter?.settings?.ribbon.width}
										</div>
										<div className="flex-2">
											{capitalize(chosenPrinter?.settings?.ribbon?.color)}
										</div>
									</div>
								</div>
								<div className="flex-1 flex-center-secondary-axis">
									{isLoadingConfirm === "ribbon" ? (
										<Loading
											show={true}
											text={t("Loading...")}
											imgClass="printerInfoLoading ml--base"
											divClass=""
										/>
									) : (
										<div
											title={t("Confirm ribbon change")}
											className="button button--primary button--sm"
											onClick={() => onConfirmMaterialChange("ribbon")}
										>
											{t("Confirm")}
										</div>
									)}
								</div>
								<div
									className="flex flex-center-secondary-axis"
									style={{ justifyContent: "end" }}
								>
									<span
										onClick={() => {
											setShowPrinterEditModal({
												item: "ribbon",
												info: chosenPrinter.settings.ribbon,
											});
										}}
										className="cursor-pointer"
										title={t("Enter ribbon info")}
									>
										<svg className="edit-printer-icon-blue">
											<use xlinkHref={`${sprite}#icon-edit`} />
										</svg>
									</span>
								</div>
							</div>
							{/* Ribbon END *************************** */}

							{/* Paper START *************************** */}
							<p
								className="mt--md"
								style={{ color: `${printerOutOfPaper ? "red" : "inherit"}` }}
							>
								<span className="strong upcase text--sm">{t("Paper")}</span>
								{printerOutOfPaper && (
									<span className="ml--base">
										{t("Please change paper and click Confirm")}
									</span>
								)}
							</p>
							<div className="flex">
								<div className="flex-4">
									<div className="flex pd--xs strong">
										<div className="flex-2">{t("Material")}</div>
										<div className="flex-2">{t("Width (mm)")}</div>
										<div className="flex-2">{t("Color")}</div>
									</div>
									<div className="flex" style={{ padding: "0 5px" }}>
										<div className="flex-2">
											{capitalize(chosenPrinter?.settings?.paper?.material)}
										</div>
										<div className="flex-2">
											{chosenPrinter?.settings?.paper?.width}
										</div>
										<div className="flex-2">
											{capitalize(chosenPrinter?.settings?.paper?.color)}
										</div>
									</div>
								</div>
								<div className="flex-1 flex-center-secondary-axis">
									{isLoadingConfirm === "paper" ? (
										<Loading
											show={true}
											text={t("Loading...")}
											imgClass="printerInfoLoading ml--base"
											divClass=""
										/>
									) : (
										<div
											title={t("Confirm paper change")}
											className="button button--primary button--sm"
											onClick={() => onConfirmMaterialChange("paper")}
										>
											{t("Confirm")}
										</div>
									)}
								</div>
								<div
									className="flex flex-center-secondary-axis"
									style={{ justifyContent: "end" }}
								>
									<span
										onClick={() => {
											setShowPrinterEditModal({
												item: "paper",
												info: chosenPrinter.settings.paper,
											});
										}}
										className="cursor-pointer"
										title={t("Enter new paper info")}
									>
										<svg className="edit-printer-icon-blue">
											<use xlinkHref={`${sprite}#icon-edit`} />
										</svg>
									</span>
								</div>
							</div>
							{/* Paper END *************************** */}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PrintersListInfo;
