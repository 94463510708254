import { FC } from "react";
import Loading from "Components/Shared/Loading";
import { useTranslation } from "react-i18next";

interface IProps {
	sharePill?: boolean;
	orderItems: any;
	isLoading: boolean;

	onPrintOrder: () => void;
}

const OrderPrintButton: FC<IProps> = ({
	sharePill,
	orderItems,
	isLoading,
	onPrintOrder,
}) => {
	const { t } = useTranslation();
	return (
		<div data-tooltip-id="printOrder">
			{!isLoading && (
				<button
					className={`button ${
						orderItems?.length > 0 ? "button--primary" : "button--disabled"
					} ${sharePill && "button-custom-print-order"}
        
        `}
					type="button"
					form="orderItemForm"
					onClick={onPrintOrder}
					data-testid="orderHeader-printOrder"
				>
					{t("Print")}
				</button>
			)}
			{isLoading && (
				<div style={{ width: "107px" }} className="flex-center-both-axis">
					<Loading
						show={true}
						text={`Loading...`}
						imgClass=""
						divClass="createPrintDataLoader"
					/>
				</div>
			)}
		</div>
	);
};

export default OrderPrintButton;
