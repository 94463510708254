import { TemplateStatuses } from "Models/OrderModels";

interface IProps {
	filteredStatus: any;

	setFilteredStatus: (filterStatus: any) => void;
}

const OrdersHeaderFilters = ({
	filteredStatus,

	setFilteredStatus,
}: IProps) => {
	return (
		<section className="listing-filters" id="filters">
			<div className="listing-filters__options" id="filtersOptions">
				<div className="form-group" id="filterContainer">
					<label
						className="listing-header__label filterLabel"
						htmlFor="statusFilter"
					>
						Status
					</label>
					<select
						className="form-control"
						data-testid="ordersStatusFilter"
						id="statusFilter"
						value={filteredStatus ? filteredStatus : "All"}
						style={{ padding: "0px 5px 1px 5px" }}
						onChange={(event) => {
							setFilteredStatus(event.target.value);
						}}
					>
						<optgroup label="Template status">
							<option value=""> All</option>
							{Object.values(TemplateStatuses)
								.slice(0, 4)
								.map((status: any) => (
									<option key={status.code} value={status.code}>
										{status.name}
									</option>
								))}
						</optgroup>
					</select>
				</div>
			</div>
		</section>
	);
};
export default OrdersHeaderFilters;
