import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { DragDropContext } from "@hello-pangea/dnd";
import { t } from "i18next";

import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";
import useOnClickOutside from "Hooks/outsideClickHook";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import { deepCopy, deepIsEqual } from "Utils/utils";
import { ISelectedSymbols, symbolsGroupArray } from "Models/OrderModels";
import SelectedSymbolGroup from "./CareInstructionsSymbolsEditComponents.tsx/SelectedSymbolGroup";
import AllSymbolGroup from "./CareInstructionsSymbolsEditComponents.tsx/AllSymbolGroup";
import OrderDispatch from "Dispatches/OrderDispatch";

interface IProps {
	setShowCareInstructionsSymbolsEdit: any;
	selectedSymbols: any;
	symbolSet: string;

	getSymbolIdsArrayForOneSymbolSet: (
		selectedSymbols: ISelectedSymbols
	) => number[];
}

const CareInstructionsSymbolsEdit: React.FunctionComponent<IProps> = ({
	setShowCareInstructionsSymbolsEdit,
	selectedSymbols,
	symbolSet,

	getSymbolIdsArrayForOneSymbolSet,
}) => {
	const { order, setOrderItem, orderItem } = useContext(OrderDispatch);

	const {
		initialValues: { careInstructionSymbols, defaultCareInstructionSymbols },
	} = useFetchInitialValues(order, order.brandId);

	const [dragStarted, setDragStarted] = useState<any>(undefined);

	// customSelectedSymbols, help state for isDirty, initially set to selectedSymbols
	// selectedSymbols is derived from (care instructions) or (order item extra info)
	// selectedSymbols is dependent on symbolSet
	// selectedSymbols and customSelectedSymbols are in format of
	// {
	//  washing: [{symbol_id: 1,...}],
	//  bleaching: [{symbol_id: 2,...}],
	//  drying: [{symbol_id: 3,...}],
	//  ironing: [],
	//  "professional textile care": []
	// }

	const [customSelectedSymbols, setCustomSelectedSymbols] =
		useState(selectedSymbols);

	let initialAllSymbols = careInstructionSymbols[symbolSet];
	let defaultSymbols = defaultCareInstructionSymbols[symbolSet];

	const [allSymbols, setAllSymbols] = useState(filterAllSymbolsOnModalMount());

	const fabricContentFormRef = useRef(null) as any;

	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		setIsDirty(!deepIsEqual(selectedSymbols, customSelectedSymbols));
	}, [customSelectedSymbols]);

	const onCloseModal = () => {
		if (isDirty) {
			if (
				confirm(
					`${t(
						"You have unsaved changes that will be discarded. Do you want to proceed?"
					)}`
				)
			) {
				setShowCareInstructionsSymbolsEdit(false);
			} else return;
		}
		setShowCareInstructionsSymbolsEdit(false);
	};

	useOnClickOutside(
		fabricContentFormRef,
		useCallback(onCloseModal, [onCloseModal])
	);

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCloseModal();
		}
	};

	const onSaveSymbolsCombination = () => {
		let selectedSymbolsIdsInArray = getSymbolIdsArrayForOneSymbolSet(
			customSelectedSymbols
		);

		let prevExtraInfo = deepCopy(orderItem.extraInfo);

		let newCareInstructionsSymbols = {
			...prevExtraInfo?.careInstructionsSymbols,
			[symbolSet]: selectedSymbolsIdsInArray,
		};

		let newExtraInfo = {
			...prevExtraInfo,
			careInstructionsSymbols: newCareInstructionsSymbols,
		};

		setOrderItem({ ...orderItem, extraInfo: newExtraInfo });
		setShowCareInstructionsSymbolsEdit(false);
	};

	// ***************************** DnD START *****************************************
	const onDragStart = (result: any) => {
		setDragStarted({ started: true, result });
	};

	const onDragEnd = (result: any) => {
		setDragStarted({ started: false, result });

		if (!result.destination) return;

		// add selected symbol from all symbols
		if (
			result.source.droppableId.includes("all") &&
			result.source.droppableId.split("-")[0] === result.destination.droppableId
		) {
			let currentAllCareInstructionsSymbols = deepCopy(
				allSymbols[result.destination.droppableId]
			);
			const [itemToMove] = currentAllCareInstructionsSymbols.splice(
				result.source.index,
				1
			);

			let currentCustomSelectedSymbolsGroup = deepCopy(
				customSelectedSymbols[result.destination.droppableId]
			);

			currentCustomSelectedSymbolsGroup.splice(
				result.destination.index,
				0,
				itemToMove
			);

			setCustomSelectedSymbols((prevCustomSelectedSymbols: any) => ({
				...prevCustomSelectedSymbols,
				[result.destination.droppableId]: currentCustomSelectedSymbolsGroup,
			}));

			// remove dragged item from all symbol group
			setAllSymbols((prevAllCareInstructionsSymbols: any) => ({
				...prevAllCareInstructionsSymbols,
				[result.destination.droppableId]: currentAllCareInstructionsSymbols,
			}));
		}

		// rearrange selected symbols group
		if (
			!result.source.droppableId.includes("all") &&
			result.source.droppableId === result.destination.droppableId
		) {
			let currentCustomSelectedSymbolsGroup = deepCopy(
				customSelectedSymbols[result.source.droppableId]
			);

			let [itemToMove] = currentCustomSelectedSymbolsGroup.splice(
				result.source.index,
				1
			);

			currentCustomSelectedSymbolsGroup.splice(
				result.destination.index,
				0,
				itemToMove
			);

			setCustomSelectedSymbols((prevCustomSelectedSymbols: any) => ({
				...deepCopy(prevCustomSelectedSymbols),
				[result.source.droppableId]: deepCopy(
					currentCustomSelectedSymbolsGroup
				),
			}));
		}
	};
	// ***************************** DnD END *****************************************

	// on modal mount remove symbols that are already selected from all symbols groups
	function filterAllSymbolsOnModalMount() {
		let filteredCurrentInitialAllSymbolsGroup: Record<string, any> = {};
		symbolsGroupArray.forEach((groupName: string) => {
			filteredCurrentInitialAllSymbolsGroup[groupName] =
				filterAllSymbolSingleGroup(
					deepCopy(customSelectedSymbols[groupName]),
					groupName
				);
		});

		return filteredCurrentInitialAllSymbolsGroup;
	}

	// remove symbols that are already selected from all symbols single group
	function filterAllSymbolSingleGroup(
		customSelectedSymbolsGroup: any,
		symbolGroupName: string
	) {
		let currentInitialAllSymbolsGroup = deepCopy(
			initialAllSymbols[symbolGroupName]
		);

		// Create a Set of symbol_ids from the currentCustomSelectedSymbolsGroup array
		const currentCustomSelectedSymbolsGroupSet = new Set(
			customSelectedSymbolsGroup.map((symbol: any) => symbol.symbol_id)
		);

		// Filter the currentInitialAllSymbolsGroup array
		const filteredCurrentInitialAllSymbolsGroup =
			currentInitialAllSymbolsGroup.filter(
				(symbol: any) =>
					!currentCustomSelectedSymbolsGroupSet.has(symbol.symbol_id)
			);

		return filteredCurrentInitialAllSymbolsGroup;
	}

	// delete from selected symbols group and populate all symbols group with deleted symbol
	const removeSymbol = (symbolToRemove: any) => {
		let currentCustomSelectedSymbolsGroup = deepCopy(
			customSelectedSymbols[symbolToRemove.symbol_type]
		);

		let filteredCurrentCustomSelectedSymbolsGroup =
			currentCustomSelectedSymbolsGroup.filter(
				(symbol: any) => symbol.symbol_id !== symbolToRemove.symbol_id
			);

		setCustomSelectedSymbols((prevCustomSelectedSymbols: any) => ({
			...prevCustomSelectedSymbols,
			[symbolToRemove.symbol_type]: [
				...filteredCurrentCustomSelectedSymbolsGroup,
			],
		}));

		let newAllSymbolsGroup = filterAllSymbolSingleGroup(
			filteredCurrentCustomSelectedSymbolsGroup,
			symbolToRemove.symbol_type
		);

		setAllSymbols((prevAllSymbols: any) => ({
			...prevAllSymbols,
			[symbolToRemove.symbol_type]: newAllSymbolsGroup,
		}));
	};

	const onSelectDefaultSymbol = (symbolGroupName: string) => {
		setCustomSelectedSymbols((prevCustomSelectedSymbols: any) => ({
			...prevCustomSelectedSymbols,
			[symbolGroupName]: [defaultSymbols[symbolGroupName]],
		}));

		let currentAllSymbolGroup = initialAllSymbols[symbolGroupName];

		let currentDefaultSymbol = defaultSymbols[symbolGroupName];

		let newCurrentAllSymbolGroup = currentAllSymbolGroup.filter(
			(symbol: any) => symbol.symbol_id !== currentDefaultSymbol.symbol_id
		);

		setAllSymbols((prevAllSymbols: any) => ({
			...prevAllSymbols,
			[symbolGroupName]: newCurrentAllSymbolGroup,
		}));
	};

	const onClickSymbol = (symbol: any, index: number) => {
		let currentAllCareInstructionsSymbols = deepCopy(
			allSymbols[symbol.symbol_type]
		);
		const [itemToMove] = currentAllCareInstructionsSymbols.splice(index, 1);

		let currentCustomSelectedSymbolsGroup = deepCopy(
			customSelectedSymbols[symbol.symbol_type]
		);

		currentCustomSelectedSymbolsGroup.push(itemToMove);

		setCustomSelectedSymbols((prevCustomSelectedSymbols: any) => ({
			...prevCustomSelectedSymbols,
			[symbol.symbol_type]: currentCustomSelectedSymbolsGroup,
		}));

		// remove clicked item from all symbol group
		setAllSymbols((prevAllCareInstructionsSymbols: any) => ({
			...prevAllCareInstructionsSymbols,
			[symbol.symbol_type]: currentAllCareInstructionsSymbols,
		}));
	};

	return (
		<div className="modal" id="fabricContentModalContainer">
			<div
				className="modal-content show"
				tabIndex={-1}
				ref={fabricContentFormRef}
				id="fabricContentModal"
				onKeyDown={onKeyDown}
			>
				<Loading
					show={false}
					text={"Loading..."}
					imgClass="block-center"
					divClass="fabricContentLoading"
				/>
				{/* true because Loading needs to be implemented */}
				{true && (
					<>
						<div>
							<header className="drawer__header" id="fabricContentModalHeader">
								<h1 className="section__title">{t("Symbols editor")}</h1>
								<button
									className="drawer__close btn-no-style"
									data-testid="fabricContentForm-closeModal"
									id="fabricContentModalCloseButton"
									data-dismiss="drawer"
									aria-label="Close"
									onClick={onCloseModal}
								>
									<Icon name="cross-rounded" />
								</button>
							</header>
							<div className="flex flex-column" style={{ minHeight: "400px" }}>
								<DragDropContext
									onDragEnd={onDragEnd}
									onDragStart={onDragStart}
								>
									{/* Selected symbols */}
									<div className="selected-symbols-container">
										<div className="mb--sm pretitle">
											{t("Selected symbols")}
										</div>
										<div className="flex flex-wrap">
											{symbolsGroupArray.map((symbolGroupName: any) => {
												return (
													<div className="mr--sm" key={symbolGroupName}>
														<SelectedSymbolGroup
															groupSelectedSymbols={
																customSelectedSymbols[symbolGroupName]
															}
															symbolGroupName={symbolGroupName}
															groupDefaultSymbol={
																defaultSymbols[symbolGroupName]
															}
															symbolSet={symbolSet}
															dragStarted={dragStarted}
															removeSymbol={removeSymbol}
															onSelectDefaultSymbol={onSelectDefaultSymbol}
														/>
													</div>
												);
											})}
										</div>
									</div>
									{/* All care instructions symbols */}
									<div
										className="flex-1"
										style={{ border: "1px solid #000", padding: "10px" }}
									>
										<div className="mb--sm pretitle">{t("All symbols")}</div>
										{symbolsGroupArray.map((symbolGroupName: any) => {
											return (
												<div className="mr--sm" key={symbolGroupName}>
													<AllSymbolGroup
														symbolGroupName={symbolGroupName}
														allSymbols={allSymbols}
														symbolSet={symbolSet}
														onClickSymbol={onClickSymbol}
													/>
												</div>
											);
										})}
									</div>
								</DragDropContext>
							</div>

							{/* Buttons */}
							<div className="saveFabricContentButton">
								<div className="flex justify-center">
									<div
										className={`button mr--lg ${
											true ? "button--primary" : "button--disabled"
										} `}
										onClick={onCloseModal}
									>
										{t("Cancel")}
									</div>
									<div
										data-tooltip-id="saveCareInstructionsSymbols"
										className=" ml--lg"
										style={{ display: "inline-block" }}
									>
										<div
											className={`button button--primary`}
											onClick={onSaveSymbolsCombination}
										>
											{t("Save")}
										</div>
									</div>
								</div>
								{/* {!isDirty && (
									<Tooltip
										id="saveCareInstructionsSymbols"
										className="reactTooltip box--shadowed"
										float
										offset={10}
									>
										<span>{t("Start by adding/deleting symbols.")}</span>
									</Tooltip>
								)} */}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CareInstructionsSymbolsEdit;
