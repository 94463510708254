import React from "react";
import { Link } from "react-router-dom-v5-compat";

import addExistingStyleButton from "./images/add-existing-style-button.png";
import addExistingStyleModal from "./images/add-existing-style-modal.png";
import usePageTitle from "Hooks/pageTitleHook";

const AddExistingStyleHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Adding an existing style to an order`);

	return (
		<>
			<h2 className="helpTitle">Adding an existing style to an order</h2>
			<article className="helpArticle">
				<p>
					Besides adding style numbers manually, you can also add styles that
					were previously{" "}
					<Link className="link" to="/help/articles/importStyles">
						imported
					</Link>
					. This is probably the best way to add styles to an order, because you
					are using styles that were previously checked, so you don't have to
					worry about typos, whether you entered valid information, etc.
				</p>
				<p>To do this, click on the Add Existing button:</p>
				<img
					src={addExistingStyleButton}
					className="helpImageSmall"
					alt="Add existing style button"
				/>
				<p>This will open the modal window:</p>
				<img
					src={addExistingStyleModal}
					className="helpImageSmall"
					alt="Add existing style modal"
				/>
				<p>
					To the left, you have a text input where you can type search term.
					Search results will be shown beneath it. To the right, you have styles
					which are already added to the order.
				</p>
				<p>
					To start the search, type at least 3 letters in the{" "}
					<b>Search style number</b> section.
				</p>
				<p>
					Once you get results, you can add them to the order with a click. They
					will be moved to the <b>Selected styles</b> section.
				</p>
				<p>
					If you want to remove a style from the order, hover it and click on
					the X button that will appear to the right.
				</p>
			</article>
		</>
	);
};

export default AddExistingStyleHelp;
