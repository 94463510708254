import React, {
	useEffect,
	useContext,
	useRef,
	useCallback,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "react-tooltip";

import sprite from "images/icons.svg";

import OrderDispatch from "Dispatches/OrderDispatch";

import { IOrderState, ORDER_PREVIEW_STATES } from "Models/OrderModels";
import LabelImagesPreview from "Components/Shared/LabelImagesPreview";
import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";
import useOnClickOutside from "Hooks/outsideClickHook";
import BackendDrawing from "./Drawing/BackendDrawing";

interface IProps {
	order: IOrderState;
	currentOrderItemIndex: number;

	setOrder: (order: IOrderState) => void;
}

const QuickPreview: React.FunctionComponent<IProps> = ({
	order,
	currentOrderItemIndex,
	setOrder,
}) => {
	const { t } = useTranslation();
	const { showPreviewOrder, setShowPreviewOrder } = useContext(OrderDispatch);

	// Should we draw one or all order items
	const [drawOne, setDrawOne] = useState(true);
	const [isDrawOneDisabled, setIsDrawOneDisabled] = useState(false);

	const quickPreviewRef = useRef(null) as any;

	useEffect(() => {
		if (quickPreviewRef.current && showPreviewOrder) {
			setTimeout(() => quickPreviewRef.current.focus(), 50);
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	}, [showPreviewOrder, quickPreviewRef]);

	const onClosePreview = useCallback(() => {
		setShowPreviewOrder(ORDER_PREVIEW_STATES.HIDDEN);
		setDrawOne(true);
	}, [setShowPreviewOrder, order]); // eslint-disable-line react-hooks/exhaustive-deps

	useOnClickOutside(
		quickPreviewRef,
		useCallback(
			() => showPreviewOrder && onClosePreview(),
			[onClosePreview, showPreviewOrder]
		)
	);

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onClosePreview();
		}
	};

	return (
		<>
			<aside
				className={`drawer drawer--right ${showPreviewOrder && `show`}`}
				id="showQuickPreview"
				role="menu"
				aria-label={t("Preview")}
				tabIndex={-1}
				ref={quickPreviewRef}
				onKeyDown={onKeyDown}
			>
				<div style={{ display: "none" }}>
					<BackendDrawing
						setIsDrawOneDisabled={setIsDrawOneDisabled}
						drawOne={drawOne}
						order={order}
						setOrder={setOrder}
						currentOrderItemIndex={currentOrderItemIndex}
					/>
				</div>
				<span
					role="button"
					className="preview-slider-button preview-slider-button-quick-preview"
					onClick={() => {
						onClosePreview();
					}}
				>
					<svg style={{ height: "15px", width: "15px", margin: "10px 2px" }}>
						<use
							style={{ color: "white" }}
							xlinkHref={`${sprite}#icon-double-right-arrow`}
						/>
					</svg>
					{t("PREVIEW")}
				</span>

				<>
					<>
						<button
							className="drawer__close btn-no-style"
							data-dismiss="drawer"
							aria-label={t("Close")}
							onClick={() => onClosePreview()}
						>
							<Icon name="cross-rounded" />
						</button>

						<div className="quickPreviewPlaceOrder">
							<h1>
								{t("Viewing")}{" "}
								{drawOne
									? `${t("style")} ${
											order?.orderItems[currentOrderItemIndex]?.styleNumber
									  }`
									: `${t("order")} ${order?.lotNumber}`}
							</h1>
							<div>
								{isDrawOneDisabled ? (
									<div>
										<div
											className="simulate-disabled-pill"
											data-tooltip-id="previewAllOrOneStyle"
											style={{
												width: "250px",
												height: "50px",
											}}
										>
											<span>
												{drawOne
													? t("Preview All Styles")
													: t("Preview Current Style")}
											</span>
										</div>
									</div>
								) : (
									<button
										disabled={isDrawOneDisabled}
										style={{
											width: "250px",
											height: "50px",
										}}
										className={"pill pill--info cursor-pointer"}
										onClick={() => {
											setIsDrawOneDisabled(true);
											setShowPreviewOrder(
												ORDER_PREVIEW_STATES.GENERATING_LAYOUTS
											);
											setDrawOne(!drawOne);
										}}
									>
										{drawOne
											? t("Preview All Styles")
											: t("Preview Current Style")}
									</button>
								)}
							</div>
						</div>

						<Loading
							show={
								showPreviewOrder === ORDER_PREVIEW_STATES.GENERATING_LAYOUTS
							}
							text={t("Loading...")}
							imgClass="block-center"
							divClass="col-sm-12"
						/>
						{showPreviewOrder === ORDER_PREVIEW_STATES.SHOW &&
							order?.orderItems.length > 0 && (
								<div className="quickPreviewContainer">
									<LabelImagesPreview
										drawOne={drawOne}
										order={order}
										currentOrderItemIndex={currentOrderItemIndex}
										reorderStyleNumber={() => 0}
									/>
								</div>
							)}
					</>
				</>
				<Tooltip
					id="previewAllOrOneStyle"
					className="reactTooltip box--shadowed"
					offset={10}
				>
					<span>{t("Drawing in progress.")}</span>
				</Tooltip>
			</aside>
		</>
	);
};

export default QuickPreview;
