import React, { useRef, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";
import WizardDispatch from "Dispatches/WizardDispatch";

import { IUnit, OrderStatusesFlow } from "Models/OrderModels";
import { EndpointPrefix } from "Models/UserModels";

import { addressJSONToString } from "Utils/utils";

import useDataApi from "Hooks/fetchHook";
import { useTranslation } from "react-i18next";

interface IProps {
	isLoadingSaveOrder: boolean;
	saveOrder: (shouldShowSaveResultNotification: boolean) => void;
}

const OrderPlaceWizardSummary: React.FunctionComponent<IProps> = ({
	isLoadingSaveOrder,
	saveOrder,
}) => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
		setConfirmationProps,
	} = useContext(GlobalDispatch);

	const { order, setOrder, saveOrderFinishedSuccessfully } =
		useContext(OrderDispatch);

	const { setOnFinish, setIsLoadingNextOrFinishButton, wizardState } =
		useContext(WizardDispatch);

	const navigate = useNavigate();

	const usePlaceOrder = useDataApi();

	const setOrderInProgressRef = useRef(false);

	// Define custom function for onFinish event
	useEffect(() => {
		const onFinish = () => {
			return () => {
				onSetOrder();
			};
		};
		setOnFinish(onFinish);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [totalQuantity, setTotalQuantity] = useState<number>(0);

	const { lotNumber, note, orderItems, orderId } = order;

	/* ******************** Handle update status API call ***************************/

	useEffect(() => {
		setIsLoadingNextOrFinishButton(usePlaceOrder.isLoading);
	}, [usePlaceOrder.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = usePlaceOrder;
		if (data.status === "ok") {
			toast.success(t("Order successfully placed!"));
			setConfirmationProps({
				message: t("Order successfully placed!"),
				link: `../order/preview/${orderId}`,
			});
			navigate("/confirmation");
		}
	}, [usePlaceOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = usePlaceOrder;
		if (error) {
			toast.error(`${t("Unable to place the order.")} ${error}`);
		}
	}, [usePlaceOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	/* ******************** Sum Total Quantity ***************************/
	useEffect(() => {
		setTotalQuantity(0);

		orderItems.forEach((item: any) => {
			const { units } = item;

			let currentStyleQuantity = units.reduce(
				(total: number, unit: IUnit) => total + unit?.quantity,
				0
			);

			setTotalQuantity((prevTotalQuantity) => {
				return (prevTotalQuantity += currentStyleQuantity);
			});
		});
	}, [orderItems]);
	/* *******************************************************************************/

	const onSetOrder = () => {
		setOrderInProgressRef.current = true;
		setOrder((prevOrder: any) => {
			return {
				...prevOrder,
				billingAddress: wizardState.billingAddress.value,
				shippingAddress: wizardState.shippingAddress.value,
				preferredShippingMethod: wizardState.shippingMethod.name,
			};
		});
	};

	let depArray = [
		order.billingAddress,
		order.shippingAddress,
		order.preferredShippingMethod,
	];
	useEffect(() => {
		if (
			order.billingAddress &&
			order.shippingAddress &&
			order.preferredShippingMethod &&
			setOrderInProgressRef.current
		) {
			setOrderInProgressRef.current = false;
			saveOrder(false);
		}
	}, depArray); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			saveOrderFinishedSuccessfully.current &&
			!isLoadingSaveOrder &&
			order.orderId
		) {
			placeOrder();
			saveOrderFinishedSuccessfully.current = false;
		}
	}, [order]); // eslint-disable-line react-hooks/exhaustive-deps

	const placeOrder = () => {
		const { orderId } = order;

		usePlaceOrder.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/status`,
			{
				status: OrderStatusesFlow.PLACED.code,
				order_ids: [orderId],
			},
			"PUT"
		);
	};

	/* *******************************************************************************/

	return (
		<div style={{ height: "500px" }}>
			<div className="mt--base flex-center-both-axis">
				<h1>{t("Summary for order #{{lotNumber}}", { lotNumber })}</h1>
			</div>

			<div className="mt--sm">
				<b>{t("Note")}:</b> {note ? note : "/"}
			</div>

			<div className="flex mt--base">
				<div style={{ flex: 1 }}>
					<b>{t("Shipping address:")}</b>{" "}
					{addressJSONToString(wizardState.shippingAddress.value)
						.split("; ")
						.map((addressPart: any, index: number) => (
							<div key={`${addressPart}-${index}`}>{addressPart}</div>
						))}
				</div>
				<div style={{ flex: 1 }}>
					<b>{t("Billing address:")}</b>{" "}
					{addressJSONToString(wizardState.billingAddress.value)
						.split("; ")
						.map((addressPart: any, index: number) => (
							<div key={`${addressPart}-${index}`}>{addressPart}</div>
						))}
				</div>
			</div>

			<div className="mt--base">
				<b>{t("Shipping method:")}</b> {wizardState.shippingMethod.name}
			</div>

			<div className="mt--base">
				<b>{t("Total quantity:")}</b> {totalQuantity}
			</div>
		</div>
	);
};

export default OrderPlaceWizardSummary;
