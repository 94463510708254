import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import translationZH from "./locales/zh-Hans/translation.json";

const resources = {
	en: {
		translation: translationEN,
	},
	"zh-Hans": {
		translation: translationZH,
	},
};

async function initI18n() {
	await i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			resources,
			fallbackLng: "en",
			debug: false,
			interpolation: {
				escapeValue: false,
			},
		});
}

initI18n();

export default i18n;
