import { useState } from "react";

const useIncrementalSearchHook: any = (
	list: any,
	combinationList: any = [],
	searchFromBeginning: boolean = false
) => {
	const [inputText, setInputText] = useState("");
	const [showList, setShowList] = useState(false);

	const splitWords: string[] = inputText ? inputText.trim().split(/[ ,]+/) : [];

	const filterList = (list: any) => {
		if (!searchFromBeginning && (!splitWords || splitWords.length === 0)) {
			return list;
		}

		let filteredList = [];
		if (Array.isArray(list)) {
			filteredList = list;
			if (inputText) {
				filteredList = list.filter((searchListItem: any) => {
					if (searchFromBeginning) {
						return (
							searchListItem.name
								.toLowerCase()
								.indexOf(inputText.toLowerCase()) === 0
						);
					} else {
						const searchListItemSplitWords = searchListItem.name
							? searchListItem.name.split(/[ ,]+/)
							: [];
						return splitWords.every((word: string) =>
							searchListItemSplitWords.some(
								(searchListItemWord: string) =>
									searchListItemWord
										.toLowerCase()
										.indexOf(word.toLowerCase()) === 0
							)
						);
					}
				});
			}
		}
		return filteredList;
	};

	const filteredList = filterList(list);

	const filteredCombinationList = filterList(combinationList);

	return {
		inputText,
		setInputText,
		showList,
		setShowList,
		filteredList,
		filteredCombinationList,
	};
};

export default useIncrementalSearchHook;
