import {
	IFiberItem,
	IOrderState,
	IUnit,
	IDefaultOption,
	IOrderItemState,
} from "Models/OrderModels";

/* ************************************************************************************************* */
export function dateFormat(fullDate: any) {
	return `${fullDate.getFullYear()}-${
		fullDate.getMonth() + 1 < 10
			? `0${fullDate.getMonth() + 1}`
			: fullDate.getMonth() + 1
	}-${fullDate.getDate() < 10 ? `0${fullDate.getDate()}` : fullDate.getDate()}`;
}

/* ************************************************************************************************* */
function timeFormat(fullDate: any) {
	const hours = fullDate.getHours();
	if (Number.isInteger(hours) && hours >= 0 && hours < 24) {
		return `${hours < 10 ? `0${hours}` : hours}:${
			fullDate.getMinutes() < 10
				? `0${fullDate.getMinutes()}`
				: fullDate.getMinutes()
		}`;
	} else {
		return "---";
	}
}

/* ************************************************************************************************* */
export function fullTimeFormat(date: any) {
	if (date) {
		return `${dateFormat(new Date(date))} ${timeFormat(new Date(date))}`;
	} else return "---";
}

/* ************************************************************************************************* */
export function prepareOrderItemForIsDirtyCompare(object: any) {
	const removeProp = (obj: any, prop: any) => {
		let { [prop]: omit, ...res } = obj;
		return res;
	};

	let item = removeProp(object, "labelImages");

	return item;
}

/* ************************************************************************************************* */
export function orderToCompareForDirty(order: IOrderState) {
	const orderItemsForCompare = order.orderItems.map((orderItem) =>
		prepareOrderItemForIsDirtyCompare(orderItem)
	);
	return { ...order, orderItems: orderItemsForCompare };
}

/* ************************************************************************************************* */
export function deepCopy(inObject: any) {
	let outObject: any;

	// If inObject is not an object
	if (typeof inObject !== "object" || inObject === null) {
		return inObject;
	}

	// Create an array or object to hold the values
	outObject = Array.isArray(inObject) ? [] : {};

	for (let key in inObject) {
		let value;
		value = inObject[key];

		// Deep copy for nested objects, including arrays
		outObject[key] = deepCopy(value);
	}

	return outObject;
}

/* ************************************************************************************************* */
export function deepIsEqual(first: any, second: any) {
	// If strings or other primitive types are compared
	if (first === second) {
		return true;
	}

	if (first == null || second == null) {
		return false;
	}

	// If arrays are compared
	if (Array.isArray(first) && Array.isArray(second)) {
		if (
			first.length === second.length &&
			first.every((value: any, index: number) =>
				deepIsEqual(value, second[index])
			)
		) {
			return true;
		} else {
			return false;
		}
	}

	// Quick compare by seeing if the length of properties are the same
	let firstProps = Object.getOwnPropertyNames(first);
	let secondProps = Object.getOwnPropertyNames(second);

	// Check different amount of properties
	if (firstProps.length !== secondProps.length) {
		return false;
	}
	for (var i = 0; i < firstProps.length; i++) {
		let prop = firstProps[i];
		switch (typeof first[prop]) {
			case "object":
				if (!deepIsEqual(first[prop], second[prop])) {
					return false;
				}
				break;
			case "number":
				if (
					isNaN(first[prop]) ||
					isNaN(second[prop]) ||
					first[prop] !== second[prop]
				) {
					return false;
				}
				break;
			default:
				if (first[prop] !== second[prop]) {
					return false;
				}
		}
	}

	return true;
}

/* ************************************************************************************************* */
export const loadImage = (src: string) => {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () => {
			resolve(img);
		};
		img.onerror = reject;
		img.src = src;
	});
};

/* ************************************************************************************************* */
export const isCanvasSupported = (canvasRef: any) => {
	if (!canvasRef || !canvasRef.current) return true;
	return canvasRef.current.getContext && canvasRef.current.getContext("2d");
};

/* ************************************************************************************************* */
export const fabricContentStringToJSON = (fabricContentText: string) => {
	let fabricContentJSON = [];
	const componentGroups = fabricContentText.split("; ");
	for (const group of componentGroups) {
		const groupParts = group.split(": ");
		let component,
			fibersArray = "" as any;
		if (groupParts.length === 1) {
			fibersArray = groupParts[0].split(", ");
		} else {
			component = groupParts[0];
			fibersArray = groupParts[1].split(", ");
		}
		let fibers_obj = [];
		for (const item of fibersArray) {
			let percentage, fiber;
			if (item.includes("%")) {
				[percentage, fiber] = item.split("% ");
			} else {
				fiber = item;
			}
			if (fiber) {
				fibers_obj.push({ percentage: Number(percentage), fiber: fiber });
			}
		}
		fabricContentJSON.push({
			component: component,
			fibers: fibers_obj,
			showPercentage: fibers_obj[0]?.percentage ? true : false,
		});
	}

	return fabricContentJSON;
};

/* ************************************************************************************************* */
export const fabricContentJSONToString = (fabricContentJSON: any) => {
	let components = [];
	for (const item of fabricContentJSON) {
		const componentString = item.component ? `${item.component}: ` : "";
		const fibers = item.showPercentage
			? item.fibers
			: [{ fiber: item.fibers[0].fiber, percentage: 0 }];
		const fibersString = fibers
			.map(
				(obj: any) =>
					`${obj.percentage ? `${obj.percentage}% ` : ""}${obj.fiber}`
			)
			.join(", ");
		components.push(`${componentString}${fibersString}`);
	}
	const fabricContentString = components.join("; ");
	return fabricContentString;
};

/* ************************************************************************************************* */
export const lastIndexOfRegex = (str: string, regex: any) => {
	const match = str.match(regex);
	return match ? str.lastIndexOf(match[match.length - 1]) + 1 : -1;
};

/* ************************************************************************************************* */
export const removeItemsWithMissingTranslations = (
	selectedItemsTranslations: any,
	missingItems: any,
	selectedItems: any,
	list: any
) => {
	const filteredItemsTranslations = selectedItemsTranslations;

	const missingItemsIds = Object.keys(missingItems).map(
		(missingItemName: string) => {
			const id = list.find(
				(listItem: any) => listItem.name === missingItemName
			)?.id;
			if (filteredItemsTranslations && filteredItemsTranslations[id]) {
				delete filteredItemsTranslations[id];
			}
			return id;
		}
	);

	const filteredItems = selectedItems.filter(
		(selectedItemId: number) => !missingItemsIds.includes(selectedItemId)
	);

	return { filteredItems, filteredItemsTranslations };
};

/* ************************************************************************************************* */
export const capitalize = (string: string) => {
	if (!string) return "";
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/* ************************************************************************************************* */
export const capitalizeEveryWord = (string: string) => {
	if (!string) return "";
	return string
		.toLowerCase()
		.replace(/(^[^\d]{1})|(\s+[^\d]{1})/g, (letter) => {
			return letter.toUpperCase();
		});
};

/* ************************************************************************************************* */
const DPI = 1200;

export const mmToPixel = (mm: number) => (DPI * mm) / 25.4;

export const pixelToMM = (pixels: number) => (pixels * 25.4) / DPI;

export const ptToPixel = (pt: number) => (DPI * pt) / 72;

/* ************************************************************************************************* */
export const truncateString = (length: number, str?: string) => {
	if (!str) return "";
	else if (str.length <= length) return str;
	else {
		const existingSpacePosition = str
			.slice(length - 3, length + 3)
			.indexOf(" ");
		return `${str.substring(
			0,
			existingSpacePosition !== -1
				? str.indexOf(" ", existingSpacePosition + length - 3)
				: length
		)}...`;
	}
};

/* ************************************************************************************************* */

export const checkIfFabricContentHasInsulationOrFeathers = (
	fabricContentId: number,
	fabricContentList: any
) => {
	const fibersThatNeedWeight = [
		"DOWN",
		"GREY DUCK",
		"GREY GOOSE",
		"WHITE DUCK",
		"WHITE GOOSE",
	];
	const fibersWithFeathers = ["GOOSE DOWN", "FEATHER"];

	const fabricContentString = fabricContentList.find(
		(fabricContentItem: any) => fabricContentItem.id === fabricContentId
	)?.name;

	if (fabricContentString) {
		const fabricContentCompositions = fabricContentStringToJSON(
			fabricContentString
		) as any;

		const insulationWeightExist = fabricContentCompositions.some(
			(composition: any) =>
				composition.component === "INSULATION" &&
				composition.fibers.some((fiberItem: IFiberItem) =>
					Object.values(fibersThatNeedWeight).includes(fiberItem.fiber)
				)
		);

		const hasFeathers = fabricContentCompositions.some(
			(composition: any) =>
				composition.component === "FILLING" &&
				composition.fibers.some((fiberItem: IFiberItem) =>
					fibersWithFeathers.includes(fiberItem.fiber)
				)
		);

		return [insulationWeightExist, hasFeathers];
	}
	return [false, false];
};

/* ************************************************************************************************* */

export const checkIfFabricContentHasFurOrigin = (
	fabricContentId: number,
	additionalComponents: any,
	fabricContentList: any,
	additionalComponentsList: any
) => {
	const fabricContentString = fabricContentList.find(
		(fabricContentItem: any) => fabricContentItem.id === fabricContentId
	)?.name;
	const furOriginId = additionalComponentsList.find(
		(additionalComponent: any) => additionalComponent.name === "REAL FUR"
	)?.id;

	return (
		additionalComponents?.includes(furOriginId) &&
		fabricContentString?.includes("SHEARLING")
	);
};

/* ************************************************************************************************* */

export const hotjar = (id: any, sv: any) => {
	(function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
		h.hj =
			h.hj ||
			function () {
				(h.hj.q = h.hj.q || []).push(arguments);
			};
		h._hjSettings = { hjid: id, hjsv: sv };
		h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
		if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
			a = o.getElementsByTagName("head")[0];
			r = o.createElement("script");
			r.async = 1;
			r.src = h._scriptPath;
			a.appendChild(r);
		}
	})(window, document, "//static.hotjar.com/c/hotjar-", ".js?sv=");
};

/* ************************************************************************************************* */

export const blockFloatingPointNumbers = (e: any) =>
	["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const addressJSONToString = (address: any) => {
	if (!address) return "";
	const {
		company_name,
		full_name,
		phone_number,
		line1,
		line2,
		postal_code,
		city,
		state,
		country,
	} = address;
	return `${company_name ? `${company_name};` : ""} ${full_name}; ${
		phone_number ? `Phone: ${phone_number}; ` : ""
	}${line1}; ${line2 ? `${line2}; ` : ""}${city}, ${
		state ? `${state}, ` : ""
	}${postal_code}; ${country}`;
};

/* ************************************************************************************************* */

export const checkIfAnySizeIsInvalid = (sizes: any, units: IUnit[]) => {
	return sizes && !Array.isArray(sizes) && Object.keys(sizes)?.length > 0
		? units.find(
				(unit) =>
					!unit.size.startsWith("1SZ") &&
					!Object.keys(sizes).find((sizeGroup: string) =>
						sizes[sizeGroup].includes(unit.size)
					)
		  )?.size
		: false;
};

/* ************************************************************************************************* */

export function getDifferenceBetweenArrays<T>(a: T[], b: T[]): T[] {
	return a.filter((element) => {
		return !b.includes(element);
	});
}

/* ************************************************************************************************* */

export const checkMissingTranslationByName = (
	initialValuesData: any,
	selectedItemsNames: any,
	selectedLanguages: any
) => {
	let selectedOption: IDefaultOption | undefined = undefined;
	let itemLanguages: string[] = [];
	let missingTranslationList: any = {};

	if (initialValuesData) {
		selectedItemsNames.forEach((element: any) => {
			selectedOption = initialValuesData.find(
				(option: IDefaultOption) => option.name === element
			);

			if (selectedOption) {
				itemLanguages = selectedOption.translatable_to;

				if (!selectedLanguages.every((r: any) => itemLanguages.includes(r))) {
					const missingLanguages = getDifferenceBetweenArrays(
						selectedLanguages,
						itemLanguages
					);

					missingTranslationList = {
						...missingTranslationList,
						[`${selectedOption.name}`]: [`${missingLanguages}`],
					};
				}
			} else {
				missingTranslationList = {
					...missingTranslationList,
					[`${element.name}`]: [`${selectedLanguages}`],
				};
			}
		});
	}

	return missingTranslationList;
};

/* ************************************************************************************************* */

export const checkMissingTranslationById = (
	initialValuesData: any,
	selectedItemsIds: any,
	selectedLanguages: any
) => {
	let selectedOption: IDefaultOption | undefined = undefined;
	let itemLanguages: string[] = [];
	let missingTranslationList: any = {};

	if (initialValuesData) {
		selectedItemsIds.forEach((element: any) => {
			selectedOption = initialValuesData.find(
				(option: IDefaultOption) => option.id === element
			);

			if (selectedOption) {
				itemLanguages = selectedOption.translatable_to;

				if (!selectedLanguages.every((r: any) => itemLanguages.includes(r))) {
					const missingLanguages = getDifferenceBetweenArrays(
						selectedLanguages,
						itemLanguages
					);

					missingTranslationList = {
						...missingTranslationList,
						[`${selectedOption.name}`]: [`${missingLanguages}`],
					};
				}
			} else {
				missingTranslationList = {
					...missingTranslationList,
					[`${element.name}`]: [`${selectedLanguages}`],
				};
			}
		});
	}
	return missingTranslationList;
};

/* ************************************************************************************************* */

export const splitContentCompositionTranslations = (
	selectedContentComposition: any,
	componentsList: any,
	fibersList: any,
	selectedLanguages: any
) => {
	let missingComponent = {};
	let missingFabric = {};
	let selectedComponentNames: any = [];
	let selectedFibersNames: any = [];

	let missingContentComposition = fabricContentStringToJSON(
		selectedContentComposition
	);

	missingContentComposition.forEach((missingElem: any) => {
		if (missingElem.component !== undefined) {
			selectedComponentNames.push(missingElem.component);
		}
		missingElem.fibers.forEach((elem: any) => {
			if (elem.fiber !== undefined) {
				selectedFibersNames.push(elem.fiber);
			}
		});
	});

	missingComponent = checkMissingTranslationByName(
		componentsList,
		selectedComponentNames,
		selectedLanguages
	);

	missingFabric = checkMissingTranslationByName(
		fibersList,
		selectedFibersNames,
		selectedLanguages
	);

	return { missingComponent, missingFabric };
};

/* ************************************************************************************************* */

export function monthsBackFrom(date: Date, numberOfMothsBack: number): Date {
	let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

	// mutate firstDayOfMonth Date so now it will be first day of the desired month in past
	firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - numberOfMothsBack);

	return firstDayOfMonth;
}

/* ************************************************************************************************* */

export const transformOrderItemForPost = (orderItem: IOrderItemState) => {
	const {
		orderItemId,
		styleNumber,
		styleDescription,
		season,
		customInformation,
		madeIn,
		units,
		fabricContent,
		selectedCareInstructions,
		selectedAdditionalComponents,
		selectedAdditionalInformation,
		allLabelLanguages,
		chosenLabelLanguages,
		translations,
		qrCodeId,
		includeQRCode,
		extraInfo: orderItemExtraInfo,
	} = orderItem;

	const {
		fabricContentTranslations,
		// additional information will pull translations directly from the db at drawing time
	} = translations;

	let orderItemForPost = {
		order_item_id: orderItemId,
		style_number: styleNumber,
		style_description: styleDescription,
		season: season,
		custom_information: customInformation,
		made_in: madeIn,
		units: units,
		fabric_content: fabricContent,
		care_instructions: selectedCareInstructions,
		all_label_languages: allLabelLanguages,
		chosen_label_languages: chosenLabelLanguages,
		translations: {
			fabricContent: fabricContentTranslations,
		},
		qr_code_id: includeQRCode ? qrCodeId : undefined,
	} as any;

	if (selectedAdditionalComponents?.length > 0) {
		orderItemForPost.additional_components = selectedAdditionalComponents;
	}

	const newlySelectedAdditionalInformation = {} as any;
	let hasSelectedAdditionalInformation = false;
	Object.keys(orderItem).forEach((key) => {
		if (key.includes("selectedAdditionalInformation---")) {
			const correctTypeKey = key as keyof typeof orderItem;
			hasSelectedAdditionalInformation = true;
			newlySelectedAdditionalInformation[key.split("---")[1]] =
				orderItem[correctTypeKey];
		}
	});

	if (hasSelectedAdditionalInformation) {
		orderItemForPost.additional_information = {
			...selectedAdditionalInformation,
			...newlySelectedAdditionalInformation,
		};
	}

	if (orderItemExtraInfo) {
		const {
			insulationWeight,
			sterilizationPermitNumber,
			furOrigin,
			careInstructionsSymbols,
		} = orderItemExtraInfo;

		if (
			insulationWeight ||
			sterilizationPermitNumber ||
			furOrigin ||
			careInstructionsSymbols
		) {
			orderItemForPost.extra_info = {};
		}

		if (insulationWeight) {
			orderItemForPost.extra_info.insulation_weight = insulationWeight;
		}

		if (sterilizationPermitNumber) {
			orderItemForPost.extra_info.sterilization_permit_number =
				sterilizationPermitNumber;
		}

		if (furOrigin) {
			orderItemForPost.extra_info.fur_origin = furOrigin;
		}

		if (careInstructionsSymbols) {
			orderItemForPost.extra_info.care_instructions_symbols =
				careInstructionsSymbols;
		}
	}

	return orderItemForPost;
};

/* ************************************************************************************************* */

export const transformOrderItemForUseOnFrontend = (orderItem: any) => {
	const {
		order_item_id,
		style_number,
		style_description,
		season,
		custom_information,
		made_in,
		units,
		fabric_content,
		care_instructions,
		additional_components,
		additional_information,
		all_label_languages,
		chosen_label_languages,
		translations,
		label_images,
		qr_code_id,
		extra_info,
	} = orderItem;

	let fabricContent;
	if (translations) {
		({ fabricContent } = translations);
	}

	let orderItemForState = {
		orderItemId: order_item_id,
		styleNumber: style_number,
		styleDescription: style_description,
		season: season,
		customInformation: custom_information,
		madeIn: made_in,
		units: units,
		fabricContent: fabric_content,
		selectedAdditionalComponents: additional_components || [],
		selectedAdditionalInformation: additional_information,
		selectedCareInstructions: care_instructions,
		allLabelLanguages: all_label_languages,
		chosenLabelLanguages: chosen_label_languages,
		translations: {
			fabricContentTranslations: fabricContent,
		},
		labelImages: label_images,
		qrCodeId: qr_code_id,
		includeQRCode: qr_code_id ? true : false,
	} as any;

	orderItemForState.extraInfo = {};

	if (extra_info) {
		if (extra_info.insulation_weight) {
			orderItemForState.extraInfo.insulationWeight =
				extra_info.insulation_weight;
		}
		if (extra_info.sterilization_permit_number) {
			orderItemForState.extraInfo.sterilizationPermitNumber =
				extra_info.sterilization_permit_number;
		}
		if (extra_info.fur_origin) {
			orderItemForState.extraInfo.furOrigin = extra_info.fur_origin;
		}
		if (extra_info.care_instructions_symbols) {
			orderItemForState.extraInfo.careInstructionsSymbols =
				extra_info.care_instructions_symbols;
		}
	}

	const localAdditionalInformation = {} as any;

	additional_information &&
		Object.keys(additional_information).forEach((key) => {
			if (
				!localAdditionalInformation[`selectedAdditionalInformation---${key}`]
			) {
				localAdditionalInformation[`selectedAdditionalInformation---${key}`] =
					[];
			}
			localAdditionalInformation[`selectedAdditionalInformation---${key}`] =
				additional_information[key];
		});

	orderItemForState = {
		...orderItemForState,
		...localAdditionalInformation,
	};

	return orderItemForState;
};

/* ************************************************************************************************* */

export const isDpp = (qrPhase: any) => {
	return !!qrPhase;
};

/* ************************************************************************************************* */

export const isBrandPrinting = (printFlag: any) => {
	return !!printFlag;
};

/* ************************************************************************************************* */

export const brandDppAppLabelTemplates = (labelTemplates: any[]) => {
	return labelTemplates.filter((labelTemplate: any) => {
		const { qr_phase } = labelTemplate?.settings?.default_values;
		return isDpp(qr_phase);
	});
};

/* ************************************************************************************************* */

export const brandPrintLabelTemplates = (labelTemplates: any[]) => {
	return labelTemplates.filter((labelTemplate: any) => {
		const { print_enabled, place_print_enabled } = labelTemplate?.settings;
		return (
			isBrandPrinting(print_enabled) || isBrandPrinting(place_print_enabled)
		);
	});
};

/* ************************************************************************************************* */

export const languagesForTranslationsCheck = (
	allLabelLanguages: string[],
	chosenLabelLanguages: string[],
	isDpp: boolean
) => {
	if (isDpp) {
		return allLabelLanguages;
	} else return chosenLabelLanguages;
};

export const isEllipsisActive = (ref: React.RefObject<HTMLElement>) => {
	if (ref.current) {
		return ref.current.offsetWidth < ref.current.scrollWidth;
	}
};
