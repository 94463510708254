interface LoadingBarProps {
	text?: string;
	classNameLoadingBar?: string;
	classNameLoadingBarText?: string;
	classNameLoadingBarContainer?: string;
}
const LoadingBar = ({
	text,
	classNameLoadingBar,
	classNameLoadingBarText,
	classNameLoadingBarContainer,
}: LoadingBarProps) => {
	return (
		<div className={`${classNameLoadingBarContainer}`}>
			<div className={`loading-bar ${classNameLoadingBar}`}></div>
			{text && (
				<p className={`loading-bar-text ${classNameLoadingBarText}`}>{text}</p>
			)}
		</div>
	);
};

export default LoadingBar;
