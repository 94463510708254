import React, { useEffect, useRef, useState } from "react";

import ImportStylesListItem from "./ImportStylesListItem";
import Loading from "Components/Shared/Loading";

interface IProps {
	styles: any;
	stylesListHeight: number;
	stylesListHeaderHeight: number;
	invalidInfo: any;
	invalidStylesArrayIndexes: any;
	isLoading: boolean;

	updateStyle: (styleNumber: string, updatedFields: any) => void;
	setInvalidStylesArrayIndexes: (invalidStylesArrayIndexes: any) => void;
	onFinalize?: () => void;
}

const ImportStylesList: React.FunctionComponent<IProps> = ({
	styles,
	stylesListHeight,
	stylesListHeaderHeight,
	invalidInfo,
	invalidStylesArrayIndexes,
	isLoading,

	updateStyle,
	setInvalidStylesArrayIndexes,
	onFinalize = () => {},
}) => {
	const TABLE_HEADER_MARGIN_BOTTOM = 20;

	// Calculate heading right padding depending on the thickness of the scroll bar  === START
	const stylesListRef = useRef(null) as any;

	const [listWidth, setListWidth] = useState<any>(0);

	const [scrollPadding, setScrollPadding] = useState(0);

	useEffect(() => {
		let scrollPadding = stylesListRef.current?.offsetWidth - 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
		// Return empty cleanup function apparently solved: " Warning: Can't perform a React state update on an unmounted component. ....."
		return () => {};
	}, [styles, listWidth]);

	// Calculate heading right padding depending on the thickness of the scroll bar  === END

	return (
		<div
			style={{
				height: stylesListHeight ? stylesListHeight : "100%",
			}}
		>
			<section
				className="listing-header listing-header--styles"
				style={{
					height: stylesListHeaderHeight ? stylesListHeaderHeight : "auto",
					paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
				}}
			>
				<span className="listing-header__label">Style Number</span>
				<span className="listing-header__label">Style Description</span>
				<span className="listing-header__label">Season</span>
				<span className="listing-header__label">Made In</span>
				<span className="listing-header__label">Content Composition</span>
				<span className="listing-header__label">Care Instructions</span>
				<span className="listing-header__label">Additional Components</span>
				<span className="listing-header__label">QR Code</span>
			</section>

			<section
				ref={stylesListRef}
				style={{
					height:
						stylesListHeight && stylesListHeaderHeight
							? stylesListHeight -
							  stylesListHeaderHeight -
							  TABLE_HEADER_MARGIN_BOTTOM
							: "90%",
					overflowY: "auto",
					overflowX: "hidden",
				}}
			>
				<>
					{styles.map((style: any, index: number) => {
						return (
							<ImportStylesListItem
								key={`${style.style_number}-${index}`}
								style={style}
								invalidInfo={invalidInfo}
								setListWidth={setListWidth}
								invalidStylesArrayIndexes={invalidStylesArrayIndexes}
								setInvalidStylesArrayIndexes={setInvalidStylesArrayIndexes}
								updateStyle={updateStyle}
							/>
						);
					})}
					{invalidInfo &&
						Object.keys(invalidInfo).length > 0 &&
						invalidStylesArrayIndexes.length === 0 && (
							<div className="center-xs mt--lg">
								<h3>All errors resolved, you can finalize the import.</h3>
								{isLoading ? (
									<div
										style={{ width: "186px", display: "inline-block" }}
										className="flex-center-primary-axis"
									>
										<Loading
											show={true}
											text={""}
											imgClass="imgLoading"
											divClass=""
										/>
									</div>
								) : (
									<button
										className={"button button--primary"}
										onClick={onFinalize}
									>
										Finalize import
									</button>
								)}
							</div>
						)}
				</>
			</section>
		</div>
	);
};

export default ImportStylesList;
