import { useTranslation } from "react-i18next";

const InterfaceLanguageChange: React.FC = () => {
	const { i18n } = useTranslation();
	const language = i18n.language;

	const toggleLanguage = () => {
		const newLang = language === "en" ? "zh-Hans" : "en";
		i18n.changeLanguage(newLang);
	};

	return (
		<div className="language-slider">
			<button
				className={`slider-option ${language === "en" ? "active" : ""}`}
				onClick={toggleLanguage}
			>
				EN
			</button>
			<button
				className={`slider-option ${language === "zh-Hans" ? "active" : ""}`}
				onClick={toggleLanguage}
			>
				中文
			</button>
			<div className="slider-highlight"></div>
		</div>
	);
};

export default InterfaceLanguageChange;
