import React, {
	useEffect,
	useContext,
	useState,
	useRef,
	useCallback,
} from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";
import useDataApi from "Hooks/fetchHook";
import {
	IOrderState,
	OrderStatusesFlow,
	ORDER_PREVIEW_STATES,
} from "Models/OrderModels";

import useOnClickOutside from "Hooks/outsideClickHook";

import { EndpointPrefix, isBrand, isFactory } from "Models/UserModels";
import Loading from "Components/Shared/Loading";
import { prepareErrorMessage } from "Utils/orderCheck";

import OrderPlaceButton from "./Components/OrderPlaceButton";
import OrderApproveButton from "./Components/OrderApproveButton";
import OrderPrintButton from "./Components/OrderPrintButton";
import OrderRequestApprovalButton from "./Components/OrderRequestApprovalButton";
import Icon from "Components/Shared/Icon";

import sprite from "images/icons.svg";
import { transformOrderItemForPost } from "Utils/utils";
import { useTranslation } from "react-i18next";

interface IProps {
	loadedOrder: IOrderState;
	isLoadingSaveOrder: boolean;
	isDirty: boolean;
	currentOrderItemIndex: number;

	saveOrder: (shouldShowSaveResultNotification: boolean) => void;
	setPrintData: (printData: any) => void;
	setShowPlaceWizard: (show: boolean) => void;
	setCurrentOrderItemIndex: (index: number) => void;
}

const OrderHeader: React.FunctionComponent<IProps> = ({
	loadedOrder,
	isLoadingSaveOrder,
	isDirty,
	currentOrderItemIndex,

	saveOrder,
	setPrintData,
	setShowPlaceWizard,
	setCurrentOrderItemIndex,
}) => {
	const useCancelOrder = useDataApi();
	const useRequestForApproval = useDataApi();

	const { t } = useTranslation();

	const {
		user: { account_type_id },
		fromOrders,
		factoryBrandsRequestApproval,
		setConfirmationProps,
	} = useContext(GlobalDispatch);

	const {
		order,
		setOrder,
		itemsWithMissingInfo,
		copyOrReorderWholeOrder,
		setShowPreviewOrder,
		setOrderItem,
		saveOrderFinishedSuccessfully,
	} = useContext(OrderDispatch);

	const navigate = useNavigate();
	const useCreatePrintData = useDataApi();
	const useApprove = useDataApi();

	const moreOptionsDropdownRef = useRef(null);
	const requestApprovalRef = useRef(false);
	const approveOrderRef = useRef(false);

	const { orderId, orderItems, brandId, status } = order;

	const [showOptions, setShowOptions] = useState(false);

	const { orderId: orderIdFromParams } = useParams() as any;

	useOnClickOutside(
		moreOptionsDropdownRef,
		useCallback(() => setShowOptions(false), [setShowOptions])
	);

	/* ******************** Handle Cancel Order API call ***************************/
	useEffect(() => {
		const { data } = useCancelOrder;
		if (data.status === "ok") {
			navigate("/orders");
		}
	}, [useCancelOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useCancelOrder;
		if (error) {
			toast.error(`Unable to cancel the order. ${error}`);
		}
	}, [useCancelOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	/* ******************** Handle Request for Approval API call ***************************/
	useEffect(() => {
		const { data } = useRequestForApproval;

		if (data.status === "ok") {
			toast.success("Approval request sent.");

			setConfirmationProps({
				message: "Approval request sent!",
				link: `../order/approve/${orderId}`,
			});
			navigate("/confirmation");
		}
	}, [useRequestForApproval.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useRequestForApproval;
		if (error) {
			toast.error(`Unable to request approval. ${error}`);
		}
	}, [useRequestForApproval.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	/* ******************************************************* */
	useEffect(() => {
		const { data } = useCreatePrintData;
		if (data && data.message) {
			setPrintData(data.message);
		}
	}, [useCreatePrintData.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useCreatePrintData;
		if (error) {
			toast.error(`Unable to create print data. ${error}`);
		}
	}, [useCreatePrintData.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *************************************************************************************** */

	/* *******************************************************************************/
	const onCancelOrder = () => {
		if (
			window.confirm("Are you sure you want to cancel the order?") &&
			orderId
		) {
			useCancelOrder.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/status`,
				{ order_ids: [orderId], status: OrderStatusesFlow.CANCELED.code },
				"PUT"
			);
		}
	};

	/* *******************************************************************************/
	const showPreview = () => {
		setShowPreviewOrder(ORDER_PREVIEW_STATES.GENERATING_LAYOUTS);
	};

	/* *******************************************************************************/

	const onPlaceOrder = () => {
		if (
			status === OrderStatusesFlow.APPROVED.code &&
			isFactory(account_type_id) &&
			isDirty
		) {
			if (
				window.confirm(
					`You have changed the order after it has been approved. If you want to discard the changes and continue with placing, click Ok. Otherwise, click Cancel to go back to the order.`
				)
			) {
				if (currentOrderItemIndex > loadedOrder.orderItems.length - 1) {
					setCurrentOrderItemIndex(0);
					setOrderItem(loadedOrder.orderItems[0]);
				} else {
					setOrderItem(loadedOrder.orderItems[currentOrderItemIndex]);
				}
				setOrder(loadedOrder);
			} else return;
		}

		const { message } = prepareErrorMessage(itemsWithMissingInfo);
		if (message.length > 0) {
			alert(message);
		} else {
			setShowPlaceWizard(true);
		}
	};

	/* *******************************************************************************/
	const onPrintOrder = () => {
		let localOrder = order;
		if (
			status === OrderStatusesFlow.APPROVED.code &&
			isFactory(account_type_id) &&
			isDirty
		) {
			if (
				window.confirm(
					t(
						"You have changed the order after it has been approved. If you want to discard the changes and continue with printing, click Ok. Otherwise, click Cancel to go back to the order."
					)
				)
			) {
				if (currentOrderItemIndex > loadedOrder.orderItems.length - 1) {
					setCurrentOrderItemIndex(0);
					setOrderItem(loadedOrder.orderItems[0]);
				} else {
					setOrderItem(loadedOrder.orderItems[currentOrderItemIndex]);
				}

				setOrder(loadedOrder);
				localOrder = loadedOrder;
			} else return;
		}

		const { message } = prepareErrorMessage(itemsWithMissingInfo);
		if (message.length > 0) {
			alert(message);
		} else {
			const localOrderItems = localOrder.orderItems.map((item: any) =>
				transformOrderItemForPost(item)
			);
			useCreatePrintData.doFetch(
				`/${EndpointPrefix[account_type_id]}/printer/createPrintData`,
				{
					orderItems: localOrderItems,
					lotNumber: localOrder.lotNumber,
					labelTemplateId: localOrder.labelTemplate.id,
					brandId: localOrder.brandId,
				},
				"POST"
			);
		}
	};

	/* *******************************************************************************/
	const onPreviewOrder = () => {
		const { message } = prepareErrorMessage(itemsWithMissingInfo);
		if (message.length > 0) {
			alert(message);
		} else {
			showPreview();
		}
	};

	/* *******************************************************************************/
	const onCopy = () => {
		if (
			(isDirty &&
				window.confirm(
					t(
						"You have unsaved changes that will be discarded. Do you want to proceed?"
					)
				)) ||
			!isDirty
		) {
			copyOrReorderWholeOrder();
		} else {
			setShowOptions(false);
		}
	};
	/* *******************************************************************************/

	// If the order was dirty, we had to save it first and then initiate Request approval/Approve order
	useEffect(() => {
		if (
			requestApprovalRef.current &&
			saveOrderFinishedSuccessfully.current &&
			!isDirty
		) {
			requestApproval();
			saveOrderFinishedSuccessfully.current = false;
			requestApprovalRef.current = false;
		} else if (
			approveOrderRef.current &&
			saveOrderFinishedSuccessfully.current &&
			!isDirty
		) {
			approveOrder();
			saveOrderFinishedSuccessfully.current = false;
			approveOrderRef.current = false;
		}
	}, [isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Handle Approval API call ***************************/

	useEffect(() => {
		const { data } = useApprove;

		if (data.status === "ok") {
			toast.success(t("Order approved"));
			setConfirmationProps({
				message: t("Order approved"),
				link: `../order/${orderIdFromParams}`,
			});
			navigate("/confirmation");
		}
	}, [useApprove.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useApprove;
		if (error) {
			toast.error(`${t("Unable to approve the order.")} ${error}`);
		}
	}, [useApprove.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	const saveOrderFirst = () => {
		saveOrder(false);
	};

	const onRequestApproval = () => {
		if (isDirty) {
			saveOrderFirst();
			requestApprovalRef.current = true;
		} else {
			requestApproval();
		}
	};

	const requestApproval = () => {
		const { message } = prepareErrorMessage(itemsWithMissingInfo);
		if (message.length > 0) {
			alert(message);
		} else {
			useRequestForApproval.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/status`,
				{
					status: OrderStatusesFlow.REVIEW_REQUESTED.code,
					order_ids: [orderId],
				},
				"PUT"
			);
		}
	};

	const onApprove = () => {
		if (isDirty) {
			saveOrderFirst();
			approveOrderRef.current = true;
		} else {
			approveOrder();
		}
	};

	const approveOrder = () => {
		const { message } = prepareErrorMessage(itemsWithMissingInfo);
		if (message.length > 0) {
			alert(message);
		} else {
			useApprove.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/status`,
				{
					status: OrderStatusesFlow.APPROVED.code,
					order_ids: [orderId],
				},
				"PUT"
			);
		}
	};

	const isPrint = order.labelTemplate?.settings?.print_enabled;
	const isPlacePrint = order.labelTemplate?.settings?.place_print_enabled;

	let placeApprovePrintButton;

	if (
		isFactory(account_type_id) &&
		brandId &&
		factoryBrandsRequestApproval?.includes(brandId.toString()) &&
		(status === OrderStatusesFlow.OPEN.code ||
			status === OrderStatusesFlow.CHANGE_REQUESTED.code)
	) {
		placeApprovePrintButton = (
			<OrderRequestApprovalButton
				isLoadingApproval={useRequestForApproval.isLoading}
				orderItems={orderItems}
				onRequestApproval={onRequestApproval}
			/>
		);
	} else if (
		isBrand(account_type_id) &&
		status === OrderStatusesFlow.CHANGE_REQUESTED.code
	) {
		placeApprovePrintButton = (
			<OrderApproveButton
				isLoadingApproval={useApprove.isLoading}
				orderItems={orderItems}
				onApprove={onApprove}
			/>
		);
	} else if (isPrint) {
		placeApprovePrintButton = (
			<div className="ml--lg">
				<OrderPrintButton
					orderItems={orderItems}
					onPrintOrder={onPrintOrder}
					isLoading={useCreatePrintData.isLoading}
				/>
			</div>
		);
	} else if (isPlacePrint) {
		placeApprovePrintButton = (
			<>
				<OrderPlaceButton sharePill onPlaceOrder={onPlaceOrder} />
				{orderItems?.length > 0 && (
					<div style={{ height: "48px", borderRight: "1px solid gray" }}></div>
				)}
				<OrderPrintButton
					sharePill
					orderItems={orderItems}
					onPrintOrder={onPrintOrder}
					isLoading={useCreatePrintData.isLoading}
				/>
			</>
		);
	} else {
		placeApprovePrintButton = (
			<>
				<OrderPlaceButton onPlaceOrder={onPlaceOrder} />
			</>
		);
	}

	return (
		<div className="row orderHeader">
			<div
				className="col-sm-12 col-md-4 col-lg-3"
				style={{
					paddingLeft: "35px",
				}}
			>
				<button
					className="link--back btn-no-style"
					onClick={() => {
						if (fromOrders.current) {
							navigate(-1);
							fromOrders.current = false;
						} else {
							navigate("/orders");
						}
					}}
				>
					<Icon name="arrow-left-rounded" />
					{t("Back to orders")}
				</button>
			</div>
			<div className="col-sm-12 col-md-12 col-lg-9">
				<div className="orderHeading row">
					<div className="flex between-xs col-xs">
						<div>
							<h1
								className="section__title"
								style={{ margin: 0 }}
								data-testid="orderHeader-orderFormTitle"
							>
								{orderId ? t("Edit Order") : t("New order")}
							</h1>
						</div>

						<div style={{ display: "flex" }}>
							{!isLoadingSaveOrder && (
								<>
									<div data-tooltip-id="saveOrder">
										<button
											className={
												orderItems?.length > 0
													? "button button--primary"
													: "button button--disabled"
											}
											type="button"
											form="orderItemForm"
											data-testid="orderHeader-saveOrder"
											onClick={() => saveOrder(true)}
										>
											{t("Save")}
										</button>
									</div>
								</>
							)}
							<Loading
								show={isLoadingSaveOrder}
								text={`Loading...`}
								imgClass="saveOrderLoading"
								divClass=""
							/>
							{placeApprovePrintButton}
						</div>
					</div>

					<div className="col-sm-8 col-md-8 col-lg-4">
						<div id="flexElement" style={{ justifyContent: "flex-start" }}>
							<div
								ref={moreOptionsDropdownRef}
								className={`dropdown ${showOptions && "show"}`}
								style={{ marginLeft: "25px" }}
							>
								<div
									className="dropdown__toggle text--quiet"
									style={{
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => setShowOptions(!showOptions)}
									data-testid="orderHeader-moreActions"
								>
									<Icon name="dots-hor" />
								</div>

								<ul
									className={`dropdown-menu dropdown-menu--right ${
										showOptions && `show`
									}`}
									style={{ top: "30px", fontSize: "14px", width: "125px" }}
								>
									<li
										data-tooltip-id="copyOrder"
										className={`${!orderId && "disabledOrderOption"} `}
									>
										<div
											className={`dropdown-menu__item orderOption ${
												!orderId && "button--disabled"
											}`}
											onClick={() => {
												fromOrders.current = false;
												onCopy();
											}}
											data-testid="orderHeader-copyOrder"
										>
											{t("Copy Order")}
										</div>
									</li>

									<li
										data-tooltip-id="cancelOrder"
										className={`${!orderId && "disabledOrderOption"} `}
									>
										<div
											className={`dropdown-menu__item orderOption ${
												!orderId && "button--disabled"
											}`}
											onClick={onCancelOrder}
											data-testid="orderHeader-cancelOrder"
											style={{ color: "#EB5757" }}
										>
											{t("Cancel Order")}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<span
					role="button"
					className={`preview-slider-button preview-slider-button-header`}
					data-testid="orderHeader-previewOrder"
					onClick={onPreviewOrder}
				>
					<svg
						style={{
							height: "15px",
							width: "15px",
							margin: "10px 2px",
							fill: "white",
						}}
					>
						<use xlinkHref={`${sprite}#icon-double-left-arrow`} />
					</svg>
					{t("PREVIEW")}
				</span>
			</div>
			{orderItems?.length === 0 && (
				<Tooltip
					id="saveOrder"
					className="reactTooltip box--shadowed"
					float
					offset={20}
				>
					<span>
						{t("You must add styles to the order before you can save it.")}
					</span>
				</Tooltip>
			)}

			{(orderItems?.length === 0 ||
				order.status === OrderStatusesFlow.CHANGE_REQUESTED.code) && (
				<Tooltip
					id="placeOrder"
					className="reactTooltip box--shadowed"
					float
					offset={20}
				>
					<span>
						{t(`You must add styles to the order before you can place it.`)}
					</span>
				</Tooltip>
			)}

			{orderItems?.length === 0 && (
				<Tooltip
					id="printOrder"
					className="reactTooltip box--shadowed"
					float
					offset={20}
				>
					<span>
						{t(`You must add styles to the order before you can print it.`)}
					</span>
				</Tooltip>
			)}

			{orderItems?.length === 0 && (
				<Tooltip
					id="requestApproval"
					className="reactTooltip box--shadowed"
					float
					offset={20}
				>
					<span>
						{t(
							`You must add styles to the order before you can request approval.`
						)}
					</span>
				</Tooltip>
			)}

			{!orderId && (
				<Tooltip
					id="copyOrder"
					className="reactTooltip box--shadowed"
					float
					offset={20}
				>
					<span>{t(`Order must be saved first.`)}</span>
				</Tooltip>
			)}

			{!orderId && (
				<Tooltip
					id="cancelOrder"
					className="reactTooltip box--shadowed"
					float
					offset={20}
					style={{ color: "black" }}
				>
					<span>{t(`Order must be saved first.`)}</span>
				</Tooltip>
			)}
		</div>
	);
};

export default OrderHeader;
