import React, { useEffect, useState } from "react";
import { IDefaultOption, IFiberItem } from "Models/OrderModels";
import Fiber from "./Fiber";
import { useTranslation } from "react-i18next";

interface IProps {
	fibersList: IDefaultOption[];
	fibers: IFiberItem[];
	compositionIndex: number;
	showPercentage: boolean;
	invalidMessages: any;

	onChangeAddedComposition: (composition: any, index: number) => void;
	changeFibersInvalidMessages: (
		itemPart: string,
		invalidMessage: string,
		compositionIndex: number,
		fiberItemIndex: number
	) => void;
}

const Fibers: React.FunctionComponent<IProps> = ({
	fibersList,
	compositionIndex,
	onChangeAddedComposition,
	changeFibersInvalidMessages,
	fibers,
	showPercentage,
	invalidMessages,
}) => {
	const { t } = useTranslation();
	const [localFibers, setLocalFibers] = useState([]) as any;

	useEffect(() => {
		// remove other fibers when percentage is hidden
		if (!showPercentage) {
			setLocalFibers([fibers[0]]);
		} else {
			setLocalFibers(fibers);
		}
	}, [showPercentage, fibers]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChangeAddedFiber = (
		fiberItem: IFiberItem,
		fiberItemIndex: number
	) => {
		let addedFibers = [...fibers];
		addedFibers[fiberItemIndex] = { ...fibers[fiberItemIndex], ...fiberItem };
		onChangeAddedComposition({ fibers: addedFibers }, compositionIndex);
	};

	const onDeleteFiber = (fiberText: string, fiberItemIndex: number) => {
		const newFibers = fibers.filter(
			(fiberItem: IFiberItem, index: number) =>
				fiberItem.fiber !== fiberText || fiberItemIndex !== index
		);
		onChangeAddedComposition({ fibers: newFibers }, compositionIndex);
		if (invalidMessages[compositionIndex]?.fibers?.[fiberItemIndex]) {
			invalidMessages[compositionIndex]?.fibers?.splice(fiberItemIndex, 1);
		}
	};

	const onAddNewFiber = () => {
		const newFibers = [...fibers, { fiber: "", percentage: 0 }];
		onChangeAddedComposition({ fibers: newFibers }, compositionIndex);
	};

	return (
		<div>
			{localFibers &&
				localFibers.length > 0 &&
				localFibers.map((fiberItem: IFiberItem, fiberItemIndex: number) => {
					return (
						<Fiber
							key={`${fiberItem.fiber}-${fiberItemIndex}`}
							fibersList={fibersList}
							fibers={fibers}
							fiberItem={fiberItem}
							compositionIndex={compositionIndex}
							fiberItemIndex={fiberItemIndex}
							showPercentage={showPercentage}
							onDeleteFiber={onDeleteFiber}
							onChangeAddedFiber={onChangeAddedFiber}
							changeFibersInvalidMessages={changeFibersInvalidMessages}
							invalidMessages={invalidMessages}
						/>
					);
				})}

			<div
				className="newFiber"
				style={{ visibility: showPercentage ? "visible" : "hidden" }}
			>
				<button onClick={onAddNewFiber} className="btn-no-style btn-link">
					{t("+ New fiber")}
				</button>
			</div>

			<div className="form-group" id="fabricContentFormInput">
				<div
					className="form-toggle mt--base mb--md"
					id="showPercentageContainer"
				>
					<input
						className="form-check__input"
						type="checkbox"
						checked={showPercentage ? true : false}
						name={`percentageCheckbox${compositionIndex}`}
						id={`percentageCheckbox${compositionIndex}`}
						onChange={() =>
							onChangeAddedComposition(
								{ showPercentage: !showPercentage },
								compositionIndex
							)
						}
					/>
					<label
						className="form-check__label"
						htmlFor={`percentageCheckbox${compositionIndex}`}
					>
						{t("Show percentage")}
					</label>
				</div>
			</div>
		</div>
	);
};

export default Fibers;
