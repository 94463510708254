import InterfaceLanguageChange from "Components/Shared/InterfaceLanguageChange";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const SettingsLanguage: React.FunctionComponent = () => {
	const { t, i18n } = useTranslation();
	const [language, setLanguage] = useState(i18n.language);

	const changeLanguage = (newLanguage: string) => {
		setLanguage(newLanguage);
		i18n.changeLanguage(newLanguage);
	};

	return (
		<div
			className="tab-pane fade in active show settingsTab"
			role="tabpanel"
			id="tab-language"
			aria-labelledby="tab-toggle-language"
		>
			<fieldset
				className="box box--light mb--md"
				style={{ padding: "40px 60px" }}
			>
				<h3 className="drawer__title">{t("Change Language")}</h3>
				<div className="form-text mb--sm mt--sm">
					{t("Select your preferred interface language.")}
				</div>

				<div className="form-group">
					<div className="mb--sm">
						<InterfaceLanguageChange />
					</div>
					{/* <select
						className="form-control"
						value={language}
						onChange={(e) => changeLanguage(e.target.value)}
						style={{ lineHeight: 2 }}
					>
						<option value="en">English</option>
						<option value="zh-Hans">中文 (简体)</option>
					</select> */}
				</div>

				<div
					className="form-actions mt--md"
					style={{ justifyContent: "flex-end" }}
				>
					<p className="form-text">
						{t("The language change will take effect immediately.")}
					</p>
				</div>
			</fieldset>
		</div>
	);
};

export default SettingsLanguage;
