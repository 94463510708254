import React from "react";

import additionalInformation from "./images/additional-information.png";
import usePageTitle from "Hooks/pageTitleHook";

const AdditionalInformationHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Additional information`);

	return (
		<>
			<h2 className="helpTitle">Additional information</h2>
			<article className="helpArticle">
				<p>
					If you have some additional and/or custom information that should show
					up on your label, you will use this option.
				</p>

				<img
					src={additionalInformation}
					className="helpImage"
					alt="Additional information"
				/>
				<p>
					It works the same way as other menus of adding information - click on
					the button and choose an option from the menu. You can type to filter
					the results.
				</p>
				<p>
					<b>Note:</b> This option requires custom setup.
				</p>
			</article>
		</>
	);
};

export default AdditionalInformationHelp;
