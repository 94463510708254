import { useContext, useState } from "react";
import QRCode from "qrcode";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import GlobalDispatch from "Dispatches/GlobalDispatch";

import {
	DEFAULT_STYLE_NAME,
	IOrderState,
	IUnit,
	OrderStatusesFlow,
} from "Models/OrderModels";
import { isLabelMaker } from "Models/UserModels";
import sprite from "images/icons.svg";

interface IProps {
	order: IOrderState;
	reorderStyleNumber: (styleNumber: string) => void;
}

const OrderPreviewSummary = ({ order, reorderStyleNumber }: IProps) => {
	const { t } = useTranslation(); // Destructure t from useTranslation
	const {
		user: { account_type_id },
		fromOrders,
	} = useContext(GlobalDispatch);

	const [qrCodeImage, setQRCodeImage] = useState("");

	const generateQR = async (hash: string) => {
		try {
			if (hash) {
				let url = `${import.meta.env.VITE_APP_DPP_SERVICE_URL}/${hash}`;
				return await QRCode.toDataURL(url);
			}
			return "";
		} catch (err) {
			console.error(err);
			return "";
		}
	};

	const { orderItems, status } = order;

	let showOrderItems = orderItems;

	let orderTotalQuantityWhite = 0;
	let orderTotalQuantityBlack = 0;

	return (
		<div className="mt--base">
			<div className="pretitle flex-center-secondary-axis">
				<span className="line-height-1">{t("STYLES SUMMARY")}</span>
			</div>
			{showOrderItems && showOrderItems.length > 0 && (
				<div className="orderPreviewSummaryContainer">
					{showOrderItems.map((item: any, index: number) => {
						const { units, styleNumber } = item;

						let totalQuantityWhite = 0;
						let totalQuantityBlack = 0;

						return (
							<div
								key={`${item.orderItemId}-${index}`}
								className={`orderPreviewSummaryContent ${
									index !== showOrderItems.length - 1 ? "mb--lg" : ""
								}`}
							>
								<div className="orderPreviewSummary">
									<div
										className="row orderPreviewSummaryInfoContainer"
										style={{ margin: "0" }}
									>
										<div
											className="pretitle col-sm-5 flex-column"
											id="reorderStyle"
										>
											<div>
												{styleNumber ? styleNumber : DEFAULT_STYLE_NAME}
												{status &&
													status >= OrderStatusesFlow.PLACED.code &&
													!isLabelMaker(account_type_id) && (
														<button
															className="reorderStyleButton btn-no-style btn-link"
															style={{ letterSpacing: "0.1em" }}
															onClick={() => {
																fromOrders.current = false;
																reorderStyleNumber(item.styleNumber);
															}}
														>
															{t("Reorder")}
														</button>
													)}
											</div>
											<div className="text--quiet text--xs">
												{item.styleDescription}
											</div>
										</div>

										<div className="col-sm-7" id="sizeInfoContainer">
											{units.map((unit: IUnit, i: number) => {
												const { size, quantity, blackLabel } = unit;
												if (blackLabel) {
													totalQuantityBlack += Number(quantity);
												} else {
													totalQuantityWhite += Number(quantity);
												}

												return (
													<div key={i}>
														<div id="flexElement">
															<div className="flex flex-center-secondary-axis">
																<div
																	className="flex-center-secondary-axis flex-1"
																	style={{ width: "47px" }}
																>
																	{unit.qrCodeId && (
																		<svg
																			className="icon ml--xs mr--sm"
																			data-tooltip-id={`qrCode`}
																			data-tooltip-content={unit.qrCodeId}
																			data-event="none"
																			data-iscapture="true"
																			style={{
																				width: "32px",
																				height: "32px",
																			}}
																		>
																			<use
																				xlinkHref={`${sprite}#icon-qrCode`}
																			/>
																		</svg>
																	)}
																</div>
																{size ? `${t("Size")}: ${size} ;` : ""}
																{`${
																	blackLabel
																		? `${t("Black ground label")}: `
																		: `${t("White ground label")}: `
																}`}
															</div>
															<div className="orderPreviewQuantity ml--xs">
																{quantity}
															</div>
														</div>
													</div>
												);
											})}
										</div>
										<div
											className={`totalQuantityContainer totalQuantityContainerWithSizes`}
										>
											<>
												<div className="totalQuantity" id="totalQuantityWhite">
													{t("Total quantity white")}: {totalQuantityWhite}
												</div>
												<div className="totalQuantity" id="totalQuantityBlack">
													{t("Total quantity black")}: {totalQuantityBlack}
												</div>
											</>
											<div
												className="totalQuantity strong"
												id="totalQuantityAll"
											>
												{t("Total quantity for style")}:{" "}
												{totalQuantityWhite + totalQuantityBlack}
											</div>
											<div style={{ display: "none" }}>
												{(orderTotalQuantityBlack += totalQuantityBlack)}
												{(orderTotalQuantityWhite += totalQuantityWhite)}
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
					<div className="mt--xl strong">
						<div className="mb--xs">{t("Order summary")}</div>
						<div className="orderTotalQuantityContainer">
							<div className="totalQuantity" id="totalQuantityWhite">
								{t("Total quantity white")}: {orderTotalQuantityWhite}
							</div>
							<div className="totalQuantity" id="totalQuantityBlack">
								{t("Total quantity black")}: {orderTotalQuantityBlack}
							</div>
							<div className="totalQuantity strong" id="totalQuantityAll">
								{t("Total quantity for order")}:{" "}
								{orderTotalQuantityWhite + orderTotalQuantityBlack}
							</div>
						</div>
					</div>
				</div>
			)}
			{/* QR code tooltip */}
			<Tooltip
				id={`qrCode`}
				className="reactTooltip whiteBackground"
				variant="light"
				offset={5}
				place="top"
				render={({ content }) => {
					if (content) {
						generateQR(content).then((image) => {
							setQRCodeImage(image);
						});
					}

					return (
						<img
							className="labelContainer"
							alt={t("Label")}
							src={qrCodeImage}
						/>
					);
				}}
			></Tooltip>
		</div>
	);
};

export default OrderPreviewSummary;
