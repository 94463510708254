import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import { IDefaultOption, IFiberItem } from "Models/OrderModels";
import Popup from "Components/Shared/Popup";
import { blockFloatingPointNumbers } from "Utils/utils";

interface IProps {
	fibersList: IDefaultOption[];
	fibers: IFiberItem[];
	fiberItem: IFiberItem;
	compositionIndex: number;
	fiberItemIndex: number;
	showPercentage: boolean;
	invalidMessages: any;

	onChangeAddedFiber: (fiberItem: any, fiberItemIndex: number) => void;
	onDeleteFiber: (fiberItemText: string, fiberItemIndex: number) => void;
	changeFibersInvalidMessages: (
		itemPart: string,
		invalidMessage: string,
		compositionIndex: number,
		fiberItemIndex: number
	) => void;
}

const Fiber: React.FunctionComponent<IProps> = ({
	fibersList,
	fibers,
	compositionIndex,
	fiberItemIndex,
	fiberItem,
	showPercentage,
	onDeleteFiber,
	onChangeAddedFiber,
	changeFibersInvalidMessages,
	invalidMessages,
}) => {
	const { t } = useTranslation();
	const { fiber, percentage } = fiberItem;
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);

	let invalidFiberMessage = "";
	let invalidPercentageMessage = "";
	const invalidFiberItem =
		invalidMessages[compositionIndex]?.fibers[fiberItemIndex];
	if (invalidFiberItem) {
		({ fiber: invalidFiberMessage, percentage: invalidPercentageMessage } =
			invalidFiberItem);
	}

	const incrementalSearchHookProps = useIncrementalSearchHook(
		fibersList,
		[],
		true
	);

	const { inputText, setInputText, setShowList } = incrementalSearchHookProps;

	useEffect(() => {
		setInputText(fiber);
	}, [fiber]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const existingFiber = fibersList.find(
			(fibersListItem: any) => fibersListItem.name === inputText
		);
		if (!existingFiber && inputText) {
			changeFibersInvalidMessages(
				"fiber",
				t("You must choose a valid fiber!"),
				compositionIndex,
				fiberItemIndex
			);
		} else {
			changeFibersInvalidMessages(
				"fiber",
				"",
				compositionIndex,
				fiberItemIndex
			);
		}
	}, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (percentage < 0 || percentage > 100) {
			changeFibersInvalidMessages(
				"percentage",
				t("You must choose a valid percentage!"),
				compositionIndex,
				fiberItemIndex
			);
		} else {
			changeFibersInvalidMessages(
				"percentage",
				"",
				compositionIndex,
				fiberItemIndex
			);
		}
		const sumOfPercentages = fibers
			.map((fibersListItem: any) => fibersListItem.percentage)
			.reduce((a: number, b: number) => a + b) as any;
		if (sumOfPercentages === 100) {
			for (let i = 0; i < fibers.length; i++) {
				changeFibersInvalidMessages("percentage", "", compositionIndex, i);
			}
		}
	}, [percentage]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChooseItemCallback = (item: any) => {
		if (item) {
			onChangeAddedFiber({ fiber: item.name }, fiberItemIndex);
			setInputText(item.name);
		}
	};

	const onChooseItemFromList = (item: any) => {
		onChooseItemCallback(item);
		setShowList(false);
	};

	const onSetItemOptionText = (text: string) => {
		if (!text) {
			onChangeAddedFiber({ fiber: "" }, fiberItemIndex);
		}
		setInputText(text);
	};

	incrementalSearchHookProps.setInputText = onSetItemOptionText;

	return (
		<div className="row" style={{ alignItems: "center" }}>
			<div className={`${showPercentage ? "col-sm-8" : "col-sm-12"}`}>
				<div
					className={`form-group ${invalidFiberMessage && "has-danger"}`}
					id="fabricContentFormInput"
				>
					<IncrementalSearchInput
						labelText={t("Fiber")}
						name={`fiber${compositionIndex}${fiberItemIndex}`}
						autoFocus={false}
						invalidMessage={invalidFiberMessage}
						testId="fabricAndCare-fibers"
						setFocusOnSearchList={setFocusOnSearchList}
						onInputBlur={onChooseItemCallback}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>
					<IncrementalSearchResults
						onChooseItemCallback={onChooseItemFromList}
						focusOnSearchList={focusOnSearchList}
						testId="fabricAndCare-fibers"
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>
				</div>
			</div>

			{showPercentage && (
				<div className="col-sm-4" style={{ display: "flex" }}>
					<div
						className={`form-group ${invalidPercentageMessage && "has-danger"}`}
						id="fabricContentFormInput"
					>
						<Popup
							show={invalidPercentageMessage.length > 0}
							text={invalidPercentageMessage}
							testId="fiber-percent"
						/>
						<input
							className="form-control"
							type="number"
							min="0"
							max="100"
							step="0.1"
							name={`percentage${compositionIndex}${fiberItemIndex}`}
							id={`percentage${compositionIndex}${fiberItemIndex}`}
							data-empty={!percentage}
							value={!percentage ? "" : percentage}
							onChange={(e) =>
								onChangeAddedFiber(
									{ percentage: Number(e.target.value) },
									fiberItemIndex
								)
							}
							onKeyDown={blockFloatingPointNumbers}
						/>
						<label
							className="form-control-label"
							htmlFor={`percentage${compositionIndex}${fiberItemIndex}`}
						>
							%
						</label>
					</div>
				</div>
			)}

			{fibers.length > 1 && (
				<button
					className="link--danger small remove-upc-block btn-no-style"
					id="deleteFiber"
					onClick={() => onDeleteFiber(fiber, fiberItemIndex)}
					data-testid="remove-unitInformation"
				>
					{t("Remove")}
				</button>
			)}
		</div>
	);
};

export default Fiber;
