import React, { useState, useEffect, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";

import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import FabricContentForm from "./FabricContent/FabricContentForm";
import IncrementalSearchComponent from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchComponent";
import FabricContentExtraInfo from "./FabricContent/FabricContentExtraInfo";

import { IDefaultOption } from "Models/OrderModels";
import { EndpointPrefix, isFactory } from "Models/UserModels";

import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import useDataApi from "Hooks/fetchHook";

import {
	checkMissingTranslationByName,
	isDpp,
	languagesForTranslationsCheck,
	splitContentCompositionTranslations,
} from "Utils/utils";

import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";

interface IProps {
	fabricContentList: IDefaultOption[];
	additionalComponentsList: IDefaultOption[];
	currentOrderItemIndex: number;
	componentsList: IDefaultOption[];
	fibersList: IDefaultOption[];

	setShowCareInstructionsModal: (showCareInstructionsModal: boolean) => void;
}

const FabricContent: React.FunctionComponent<IProps> = ({
	fabricContentList,
	additionalComponentsList,
	currentOrderItemIndex,
	componentsList,
	fibersList,

	setShowCareInstructionsModal,
}) => {
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);
	const [showFabricContentModalText, setShowFabricContentModalText] =
		useState("");
	const [showInput, setShowInput] = useState(false);
	const [invalidFabricContentMessage, setInvalidFabricContentMessage] =
		useState("");

	const {
		orderItem,
		setOrderItem,
		order: { labelTemplate, brandId },
	} = useContext(OrderDispatch);

	const {
		setMissingTranslations,
		user: { account_type_id },
		missingTranslations,
	} = useContext(GlobalDispatch);

	const { qr_phase } = labelTemplate?.settings?.default_values;

	const useSaveFabricContent = useDataApi();

	const {
		fabricContent,
		allLabelLanguages,
		chosenLabelLanguages,
		selectedAdditionalComponents,
		translations,
	} = orderItem;

	const incrementalSearchHookProps =
		useIncrementalSearchHook(fabricContentList);

	const { inputText, setInputText, setShowList } = incrementalSearchHookProps;

	const { t } = useTranslation();

	let languagesArrayForTranslationsCheck = useMemo(() => {
		return languagesForTranslationsCheck(
			allLabelLanguages,
			chosenLabelLanguages,
			isDpp(qr_phase)
		);
	}, [chosenLabelLanguages]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!inputText) {
			setShowInput(true);
		}
	}, [inputText]);

	useEffect(() => {
		// empty input with popup message - resolves reset values bug
		if (!fabricContent) {
			setInputText("");
			setOrderItem({ fabricContent: -1 });
		}
	}, [currentOrderItemIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// after reset fabric content to -1, reset input text to empty string
		if (fabricContent === -1) {
			setInputText("");
		}
	}, [currentOrderItemIndex, fabricContent]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (fabricContentList) {
			const fabricContentOption = fabricContentList.find(
				(fabricContentObject: IDefaultOption) =>
					fabricContentObject.id === fabricContent
			);
			if (fabricContentOption) {
				if (fabricContentOption.id !== 0) {
					setInputText(fabricContentOption.name);
					setShowInput(false);
					setInvalidFabricContentMessage("");
				}
			} else {
				if (fabricContent === undefined) {
					setInvalidFabricContentMessage(
						t("You must choose a valid content composition from the list!")
					);
				} else {
					setInvalidFabricContentMessage("");
				}
			}
		}
	}, [fabricContent, fabricContentList]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useSaveFabricContent;
		if (data.message) {
			const { missingTranslations: missingContentComposition } = data.message;

			if (missingContentComposition) {
				setMissingTranslations({
					...missingTranslations,
					missingContentComposition,
				});
			} else {
				queryClient.invalidateQueries({
					queryKey: [QUERY_KEYS.INITIAL_VALUES, account_type_id, brandId],
				});
			}
		}
	}, [useSaveFabricContent.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveFabricContent;
		if (error) {
			toast.error(
				t("Unable to get content composition translations. {{error}}", {
					error,
				})
			);
		}
	}, [useSaveFabricContent.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChooseItemCallbackFabricContent = (item: any) => {
		setShowList(false);

		let missingContentCompositionTranslations = checkMissingTranslationByName(
			fabricContentList,
			[item.name],
			languagesArrayForTranslationsCheck
		);
		if (Object.keys(missingContentCompositionTranslations).length > 0) {
			let {
				missingComponent: missingComponentTranslations,
				missingFabric: missingFabricTranslations,
			} = splitContentCompositionTranslations(
				item.name,
				componentsList,
				fibersList,
				languagesArrayForTranslationsCheck
			);

			if (
				Object.keys(missingComponentTranslations).length === 0 &&
				Object.keys(missingFabricTranslations).length === 0
			) {
				setShowCareInstructionsModal(true);
				setShowInput(true);
				onSaveOption(item);

				const brandIdForFactory = isFactory(account_type_id)
					? `?brandId=${brandId}`
					: "";
				useSaveFabricContent.doFetch(
					`/${EndpointPrefix[account_type_id]}/translations/fabricContent${brandIdForFactory}`,
					{
						fabricContent: item.name,
						languages: languagesArrayForTranslationsCheck,
					},
					"POST"
				);
			} else {
				setMissingTranslations({
					missingComponents: missingComponentTranslations,
					missingFibers: missingFabricTranslations,
				});
			}
		} else {
			setShowCareInstructionsModal(true);
			setShowInput(true);
			onSaveOption(item);
			setShowList(false);
		}
	};

	const onSaveOption = (item: any) => {
		if (item) {
			setOrderItem({ fabricContent: item.id });
			setInputText(item.name);
		}
	};

	const onShowInput = () => {
		setShowInput(true);
		setShowList(true);
	};

	const onChooseItemCallbackAdditionalComponents = (item: any) => {
		if (item) {
			let filteredListItems = selectedAdditionalComponents || [];
			if (!selectedAdditionalComponents.includes(item.id)) {
				filteredListItems = [...selectedAdditionalComponents, item.id];
				setOrderItem({ selectedAdditionalComponents: filteredListItems });

				if (languagesArrayForTranslationsCheck.length > 0) {
					let missingAdditionalComponentsTranslations =
						checkMissingTranslationByName(
							additionalComponentsList,
							[item.name],
							languagesArrayForTranslationsCheck
						);
					if (Object.keys(missingAdditionalComponentsTranslations).length > 0) {
						setMissingTranslations({
							missingAdditionalComponents:
								missingAdditionalComponentsTranslations,
						});
					}
				}
			}
		}
	};

	const onChangeOrderOfSelectedItems = (ids: any) => {
		setOrderItem({
			selectedAdditionalComponents: ids.map((id: string) => Number(id)),
		});
	};

	const onRemoveItem = (itemId: number) => {
		const filteredItems = selectedAdditionalComponents.filter(
			(chosenItemId: number) => chosenItemId !== itemId
		);
		if (translations.additionalComponentsTranslations) {
			delete translations.additionalComponentsTranslations[itemId];
		}
		setOrderItem({
			selectedAdditionalComponents: filteredItems,
			translations: {
				...translations,
				additionalComponentsTranslations:
					translations.additionalComponentsTranslations,
			},
		});
	};

	return (
		<>
			<div
				className={`form-group ${invalidFabricContentMessage && "has-danger"}`}
			>
				{showInput && (
					<IncrementalSearchInput
						labelText={t("Content Composition")}
						name="fabricContent"
						autoFocus={!inputText ? false : true}
						invalidMessage={invalidFabricContentMessage}
						setFocusOnSearchList={setFocusOnSearchList}
						onChangeInputText={() => {
							setOrderItem({ fabricContent: undefined });
						}}
						onInputBlur={onSaveOption}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>
				)}

				{!showInput && (
					<div
						className="form-control"
						data-testid="fabricContentChosenValue"
						onClick={onShowInput}
					>
						{inputText.split(";").map((composition: string, i: number) => {
							return (
								<div key={`composition${i}`} style={{ paddingRight: "30px" }}>
									{composition
										? composition
										: composition.length - 1 !== i
										? ";"
										: ""}
								</div>
							);
						})}
					</div>
				)}

				<button
					className="addNewFabricContent btn-no-style btn-link"
					onClick={() => setShowFabricContentModalText("Edit")}
				>
					{t("Edit")}
				</button>

				<IncrementalSearchResults
					focusOnSearchList={focusOnSearchList}
					testId="fabricContent"
					onChooseItemCallback={onChooseItemCallbackFabricContent}
					incrementalSearchHookProps={incrementalSearchHookProps}
				/>

				<div className="createNewFabricContent">
					<div className="text--quiet">
						{t("Can't find what you're looking for?")}
					</div>
					<button
						className="button button--outline-dark button--sm"
						id="blueBorderButton"
						data-testid="createNewComposition"
						onClick={() => setShowFabricContentModalText("New")}
					>
						{t("Create New Composition")}
					</button>
				</div>
			</div>

			{showFabricContentModalText.length > 0 && (
				<FabricContentForm
					setShowFabricContentModalText={setShowFabricContentModalText}
					fabricContentText={
						showFabricContentModalText === "Edit" && fabricContent
							? inputText
							: ""
					}
					setFabricContentText={setInputText}
				/>
			)}

			{!labelTemplate?.settings?.form_settings?.hide_additional_components && (
				<IncrementalSearchComponent
					list={additionalComponentsList}
					buttonName={t("Additional Components")}
					selectedItemsValues={selectedAdditionalComponents || []}
					fieldName="selectedAdditionalComponents"
					onChooseItemCallbackCustom={onChooseItemCallbackAdditionalComponents}
					onChangeOrderOfSelectedItems={onChangeOrderOfSelectedItems}
					onRemoveItem={onRemoveItem}
				/>
			)}

			{(labelTemplate?.id === 1 || labelTemplate?.id === 17) && (
				<FabricContentExtraInfo />
			)}
		</>
	);
};

export default FabricContent;
