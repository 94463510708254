export interface ITemplateFont {
	style: string;
	size: number;
	lineSpacing: number;
	blackGround: boolean;
}

export interface ITemplateMargins {
	top: number;
	left: number;
	right: number;
	bottom: number;
}

export interface ITemplateDimensions {
	width: number;
	height: number;
	multiplePages: boolean;
}

export interface IFormSettings {
	hideLanguagesDropDown: boolean;
	hideStyleDescription: boolean;
	hideAdditionalComponents: boolean;
	hideMadeIn: boolean;
	hideUpc: boolean;
	hideColor: boolean;
	hideSize: boolean;
	hideAddAnother: boolean;
	hideBlackLabel: boolean;
}

export interface DefaultTemplateSettings {
	capitalization: number;
	align: string;
	splitCharacter: number;
	separator: number;
}

export interface ITemplateState {
	name: string;
	fonts: ITemplateFont;
	languages: string[];
	margins: ITemplateMargins;
	dimensions: ITemplateDimensions;
	sections: any[];
	sectionDefaultSettings: DefaultTemplateSettings;
}

export const initialTemplate = {
	name: "",
	fonts: {
		style: "",
		size: 0,
		blackGround: false,
		lineSpacing: 0,
	},
	languages: [],
	margins: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	dimensions: {
		width: 0,
		height: 0,
		multiplePages: false,
	},
	sections: [],
	sectionDefaultSettings: {
		capitalization: -1,
		align: "",
		splitCharacter: -1,
		separator: 0,
	},
};
