import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom-v5-compat";
import { PrintStatusesFlow } from "Models/OrderModels";
import { capitalize, isEllipsisActive } from "Utils/utils";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { isFactory } from "Models/UserModels";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

interface IStateProps {
	printBatch: any;
	selectedPrintBatchesForBulkAction: any;
	refVar?: any;
	filteredPrinter: any;

	setSelectedPrintBatchesForBulkAction: (
		selectedOrdersForBulkAction: any
	) => void;
}

const OrdersListItem: React.FunctionComponent<IStateProps> = ({
	printBatch,
	selectedPrintBatchesForBulkAction,
	refVar,
	filteredPrinter,

	setSelectedPrintBatchesForBulkAction,
}) => {
	const { t } = useTranslation(); // Destructure t from useTranslation

	const {
		fromOrders,
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const refNumber = useRef<HTMLDivElement>(null);
	const styleNumbersRef = useRef<HTMLDivElement>(null);

	const {
		print_batch_id: id,
		batch_status_id: status,
		color,
		label_width: width,
		reference_number,
		style_number,
		sum_print_quantity,
		sum_quantity,
		template_name,
		brand_name,
	} = printBatch;

	// const togglePrintBatchSelected = () => {
	// let localSelectedOrdersForBulkAction = {
	// 	...selectedPrintBatchesForBulkAction,
	// };
	// if (localSelectedOrdersForBulkAction[id]) {
	// 	delete localSelectedOrdersForBulkAction[id];
	// } else {
	// 	localSelectedOrdersForBulkAction[id] = {
	// 		status: orderStatus,
	// 		billingAddress,
	// 		shippingAddress,
	// 		preferredShippingMethod,
	// 		lotNumber,
	// 		brandId,
	// 		brandName,
	// 	};
	// 	if (isBrand(accountTypeId)) {
	// 		localSelectedOrdersForBulkAction[id].factoryId = factoryId;
	// 	}
	// 	localSelectedOrdersForBulkAction.orderStatus = orderStatus;
	// };

	// 	setSelectedPrintBatchesForBulkAction(localSelectedOrdersForBulkAction);
	// };

	const changeFromOrders = () => {
		fromOrders.current = true;
	};

	const PRINT_BATCH_LINK = `../printBatch/${printBatch.print_batch_id}`;
	const PRINT_BATCH_STATE = { filteredPrinterId: filteredPrinter };

	return (
		<div style={{ borderBottom: "2px solid #f4f4f4" }} ref={refVar}>
			<div>
				<article className={`list-item ${"list-item--print-batches"}`}>
					{/* Bulk action checkbox  */}
					{/* <div className="list-item__details">
						<div className="form-check">
							<input
								className="form-check__input"
								style={{ marginRight: "25px" }}
								type="checkbox"
								id={`checkbox${id}`}
								checked={selectedPrintBatchesForBulkAction[id] ? true : false}
								onChange={() => togglePrintBatchSelected()}
							/>
							<label
								className="form-check__label"
								htmlFor={`checkbox${id}`}
							></label>
						</div>
					</div> */}

					{/* Reference number/Styles numbers */}
					<Link
						className="list-item__details"
						to={PRINT_BATCH_LINK}
						state={PRINT_BATCH_STATE}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div
							className="truncate mr--sm"
							ref={refNumber}
							data-tooltip-id={`refNumber${id}`}
							data-event="none"
							data-iscapture="true"
						>
							{reference_number}
							{/* Empty div added to prevent default title behavior on Safari with text-overflow: ellipsis  */}
							<div></div>
						</div>
						<div className="noteLink">
							<small
								className="truncate mr--sm"
								ref={styleNumbersRef}
								data-tooltip-id={`styleNumbersRef${id}`}
								data-event="none"
								data-iscapture="true"
							>
								{style_number}
								{/* Empty div added to prevent default title behavior on Safari with text-overflow: ellipsis  */}
								<div></div>
							</small>
						</div>
					</Link>

					{/* Template name/Brand */}
					<Link
						className="list-item__details"
						to={PRINT_BATCH_LINK}
						state={PRINT_BATCH_STATE}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div className="truncate mr--sm">{template_name}</div>
						{isFactory(account_type_id) && (
							<div className="noteLink">
								<small className="mr--sm">
									{brand_name}
									{/* Empty div added to prevent default title behavior on Safari with text-overflow: ellipsis  */}
									<div></div>
								</small>
							</div>
						)}
					</Link>

					{/* Color/Width */}
					<Link
						className="list-item__details"
						to={PRINT_BATCH_LINK}
						state={PRINT_BATCH_STATE}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div className="truncate">
							{capitalize(color)} / {width}mm
						</div>
					</Link>

					{/* Sum/Print sum */}
					<Link
						className="list-item__details"
						to={PRINT_BATCH_LINK}
						state={PRINT_BATCH_STATE}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div className="truncate">
							{sum_quantity} ({sum_print_quantity})
						</div>
					</Link>

					{/* Status */}
					<Link
						className="list-item__details ordersStatusListItem"
						style={{ alignItems: "flex-end" }}
						to={PRINT_BATCH_LINK}
						state={PRINT_BATCH_STATE}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div style={{ display: "flex" }}>
							{status === PrintStatusesFlow.GENERATING_LAYOUTS.code && (
								<span className="statusQuiet">
									{t(PrintStatusesFlow.GENERATING_LAYOUTS.name)}
								</span>
							)}

							{status === PrintStatusesFlow.GENERATING_LAYOUTS_FAILED.code && (
								<span className="statusQuiet voidOrder">
									{t("GEN. LAYOUTS FAILED")}
								</span>
							)}

							{status === PrintStatusesFlow.PRINT_READY.code && (
								<span className="statusQuiet">
									{t(PrintStatusesFlow.PRINT_READY.name)}
								</span>
							)}

							{status === PrintStatusesFlow.PRINTING.code && (
								<span className="statusQuiet">
									{t(PrintStatusesFlow.PRINTING.name)}
								</span>
							)}

							{status === PrintStatusesFlow.PRINTING_STOPPED.code && (
								<span className="statusQuiet">
									{t(PrintStatusesFlow.PRINTING_STOPPED.name)}
								</span>
							)}

							{status === PrintStatusesFlow.PRINTED.code && (
								<span className="statusQuiet statusCompleted">
									{t(PrintStatusesFlow.PRINTED.name)}
								</span>
							)}

							{status === PrintStatusesFlow.PRINTING_CANCELED.code && (
								<span className="statusQuiet voidOrder">
									{t(PrintStatusesFlow.PRINTING_CANCELED.name)}
								</span>
							)}
						</div>
					</Link>
				</article>
			</div>

			{/* Reference number tooltip */}
			{isEllipsisActive(refNumber) && (
				<Tooltip
					id={`refNumber${id}`}
					className="reactTooltip whiteBackground"
					variant="light"
					offset={5}
					clickable={true}
					place="bottom"
					delayShow={400}
				>
					<div>
						<div>{reference_number}</div>
					</div>
				</Tooltip>
			)}

			{/* Style Numbers tooltip */}
			{isEllipsisActive(styleNumbersRef) && (
				<Tooltip
					id={`styleNumbersRef${id}`}
					className="reactTooltip whiteBackground"
					variant="light"
					offset={5}
					clickable={true}
					place="bottom"
					delayShow={400}
				>
					<div style={{ maxWidth: "300px" }}>
						<div>{style_number}</div>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export default OrdersListItem;
