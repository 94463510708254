import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import OrderDispatch from "Dispatches/OrderDispatch";

const SterilizationPermitNumber: React.FunctionComponent = () => {
	const { t } = useTranslation();

	const { orderItem, setOrderItem } = useContext(OrderDispatch);
	const { extraInfo: orderItemExtraInfo } = orderItem;

	let sterilizationPermitNumber;

	if (orderItemExtraInfo) {
		({ sterilizationPermitNumber } = orderItemExtraInfo);
	}

	return (
		<div className="form-group">
			<input
				className="form-control"
				name="sterilizationPermitNumber"
				id="sterilizationPermitNumber"
				data-empty={!sterilizationPermitNumber}
				value={!sterilizationPermitNumber ? "" : sterilizationPermitNumber}
				onChange={(e) =>
					setOrderItem({
						extraInfo: {
							...orderItemExtraInfo,
							sterilizationPermitNumber: e.target.value,
						},
					})
				}
			/>
			<label className="form-control-label" htmlFor="sterilizationPermitNumber">
				{t("Sterilization permit number:")}
			</label>
		</div>
	);
};

export default SterilizationPermitNumber;
