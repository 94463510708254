import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";
import useDataApi from "Hooks/fetchHook";
import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";
import { EndpointPrefix, isFactory } from "Models/UserModels";
import { IDefaultOption } from "Models/OrderModels";
import {
	checkMissingTranslationByName,
	isDpp,
	languagesForTranslationsCheck,
} from "Utils/utils";

interface IProps {
	showCareInstructionsModal: boolean;
	fabricContentText: string;

	setShowCareInstructionsModal: (showCareInstructionsModal: boolean) => void;
}
interface IProps {
	careInstructionsList: IDefaultOption[];
}

const SuggestedCareInstructions: React.FunctionComponent<IProps> = ({
	careInstructionsList,
	showCareInstructionsModal,
	setShowCareInstructionsModal,
	fabricContentText,
}) => {
	const { t } = useTranslation();
	const useGetCareInstructions = useDataApi();
	const { orderItem, setOrderItem, order } = useContext(OrderDispatch);
	const orderBrandId = order.brandId;
	const {
		user: { account_type_id },
		setMissingTranslations,
	} = useContext(GlobalDispatch);
	const { qr_phase } = order.labelTemplate?.settings?.default_values;
	const { fabricContent, allLabelLanguages, chosenLabelLanguages } = orderItem;

	const suggestedCareInstructionsModalRef = useRef(null) as any;

	const [careInstructionsSuggestions, setCareInstructionsSuggestions] =
		useState([]);

	useEffect(() => {
		if (showCareInstructionsModal) {
			suggestedCareInstructionsModalRef.current?.focus();
		}
	}, [showCareInstructionsModal]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showCareInstructionsModal && fabricContent > 0) {
			const brandIdForFactory = isFactory(account_type_id)
				? `?brandId=${orderBrandId}`
				: "";
			useGetCareInstructions.doFetch(
				`/${EndpointPrefix[account_type_id]}/data/getCareInstructionsForFabricContent/${fabricContent}${brandIdForFactory}`
			);
		}
	}, [fabricContent, showCareInstructionsModal]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetCareInstructions;
		if (data && data.message) {
			setCareInstructionsSuggestions(data.message);
		}
		if (!data || (data && data.message && data.message.length === 0)) {
			setShowCareInstructionsModal(false);
		}
	}, [useGetCareInstructions.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetCareInstructions;
		if (showCareInstructionsModal && error) {
			toast.error(t("Unable to get care instructions. {{error}}", { error }));
		}
	}, [useGetCareInstructions.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const onUseThisOneClicked = (
		selectedCareInstructions: any,
		selectedCareInstructionNames: any
	) => {
		let languagesArrayForTranslationsCheck = languagesForTranslationsCheck(
			allLabelLanguages,
			chosenLabelLanguages,
			isDpp(qr_phase)
		);
		setOrderItem({ selectedCareInstructions });
		setShowCareInstructionsModal(false);
		if (
			selectedCareInstructionNames.length > 0 &&
			languagesArrayForTranslationsCheck.length > 0
		) {
			let missingCareInstructionTranslations = checkMissingTranslationByName(
				careInstructionsList,
				selectedCareInstructionNames,
				languagesArrayForTranslationsCheck
			);

			if (Object.keys(missingCareInstructionTranslations).length > 0) {
				setMissingTranslations({
					missingCareInstructions: missingCareInstructionTranslations,
				});
			}
		}
	};

	const onCloseModal = () => {
		setCareInstructionsSuggestions([]);
		setShowCareInstructionsModal(false);
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCloseModal();
		}
	};

	return (
		<>
			{showCareInstructionsModal && (
				<div className="modal flex-center-both-axis">
					<div
						className="modal-content show suggestedCareInstructionsModal"
						tabIndex={-1}
						style={{ overflowY: "hidden" }}
						ref={suggestedCareInstructionsModalRef}
						onKeyDown={onKeyDown}
					>
						<Loading
							show={useGetCareInstructions.isLoading}
							text={t("Loading...")}
							imgClass="block-center"
							divClass="main__content"
						/>
						{!useGetCareInstructions.isLoading && (
							<div>
								<div className="flex row reverse" style={{ height: "20px" }}>
									<button
										className="drawer__close btn-no-style pos-relative"
										data-dismiss="drawer"
										aria-label={t("Close")}
										onClick={onCloseModal}
										style={{ marginTop: "-10px" }}
									>
										<Icon name="cross-rounded" />
									</button>
								</div>
								<header
									className="drawer__header"
									style={{ paddingBottom: "20px" }}
								>
									<h3 className="drawer__title" style={{ width: "95%" }}>
										{t(
											"Suggested Care Instructions for {{fabricContentText}}",
											{ fabricContentText }
										)}
									</h3>
								</header>
								<div className="flex-center-both-axis text--base mb--base">
									<button
										className="link--ui modalLink btn-no-style btn-link"
										onClick={() => setShowCareInstructionsModal(false)}
									>
										{t("I will enter care instructions manually")}
									</button>
								</div>
								<div className="text--base center-lg mb--xs">
									{t("You can change this information any time.")}
								</div>

								<div style={{ overflowY: "auto", height: "330px" }}>
									{careInstructionsSuggestions.map((suggestion: any, index) => {
										const careInstructionIds = suggestion.map(
											(careInstructionsItem: any) => careInstructionsItem.id
										);
										const careInstructionNames = suggestion.map(
											(careInstructionsItem: any) => careInstructionsItem.name
										);
										return (
											<div className="box--default modalBox" key={index}>
												<div
													className="col-xs-8"
													data-testid={`careInstructionsSuggestions${index}`}
												>
													{suggestion.map((suggestion: any) => (
														<p
															key={suggestion.id}
															style={{ display: "inline" }}
														>
															{suggestion.name}.
														</p>
													))}
												</div>
												<button
													onClick={() =>
														onUseThisOneClicked(
															careInstructionIds,
															careInstructionNames
														)
													}
													id="useThisOneButton"
													className="col-xs-4 button button--primary button--block button--square button--sm"
													data-testid={`careInstructionsSuggestionsButton-${index}`}
												>
													{t("Use this one")}
												</button>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default SuggestedCareInstructions;
