import { FC } from "react";
import { Route, Routes } from "react-router-dom-v5-compat";

import "./styles/Help.css";

import HelpVideos from "Components/Help/HelpVideos";
import HelpArticles from "Components/Help/HelpArticles";
import HelpWhatIsNew from "Components/Help/HelpWhatIsNew";

const Help: FC = () => {
	return (
		<>
			<div className="main__content">
				<div className="container pd--base">
					<Routes>
						<Route path="videos/*" element={<HelpVideos />} />
						<Route path="articles/*" element={<HelpArticles />} />
						<Route path="what-is-new" element={<HelpWhatIsNew />} />
					</Routes>
				</div>
			</div>
		</>
	);
};

export default Help;
