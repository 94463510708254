import React, { useEffect, useRef, useCallback } from "react";

import Icon from "Components/Shared/Icon";
import useOnClickOutside from "Hooks/outsideClickHook";

import LabelSettingsHelp from "HelpArticles/LabelSettingsHelp";
import AddExistingStyleHelp from "HelpArticles/AddExistingStyleHelp";
import ImportStylesHelp from "HelpArticles/ImportStylesHelp";
import ContentCompositionHelp from "HelpArticles/ContentCompositionHelp";

interface IProps {
	currentHelpTopic: string;

	setCurrentHelpTopic: (currentHelpTopic?: string) => void;
}

const HelpModal: React.FunctionComponent<IProps> = ({
	currentHelpTopic,
	setCurrentHelpTopic,
}) => {
	const modalRef = useRef(null) as any;

	useEffect(() => {
		if (modalRef.current) {
			modalRef.current.focus();
		}
	}, [currentHelpTopic]); // eslint-disable-line react-hooks/exhaustive-deps

	const closeModal = () => {
		setCurrentHelpTopic(undefined);
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			setCurrentHelpTopic(undefined);
		}
	};

	useOnClickOutside(modalRef, useCallback(closeModal, [setCurrentHelpTopic])); // eslint-disable-line react-hooks/exhaustive-deps

	/* ****************************** */
	if (!currentHelpTopic) {
		return null;
	}
	/* ****************************** */

	let component = null;

	switch (currentHelpTopic) {
		case "labelSettings":
			component = <LabelSettingsHelp />;
			break;
		case "addingExistingStyle":
			component = <AddExistingStyleHelp />;
			break;
		case "importStyles":
			component = <ImportStylesHelp />;
			break;
		case "contentComposition":
			component = <ContentCompositionHelp />;
			break;
	}

	return (
		<div className="modal">
			<div
				className="modal-content-help block-center show"
				ref={modalRef}
				tabIndex={-1}
				onKeyDown={onKeyDown}
			>
				<div className="row">
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={closeModal}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>
				<div className="col-sm-12 mb--lg">{component}</div>
			</div>
		</div>
	);
};

export default HelpModal;
