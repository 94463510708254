import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import { IOrderState, OrderStatusesFlow } from "Models/OrderModels";
import { EndpointPrefix, isBrand, isFactory } from "Models/UserModels";

import Icon from "Components/Shared/Icon";

import useDataApi from "Hooks/fetchHook";
import Loading from "Components/Shared/Loading";
import { useTranslation } from "react-i18next";

interface IProps {
	showRejectNote: boolean;
	order: IOrderState;

	setShowRejectNote: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderApproveHeader = ({
	showRejectNote,
	order,
	setShowRejectNote,
}: IProps) => {
	const {
		user: { account_type_id },
		fromOrders,
		setConfirmationProps,
	} = useContext(GlobalDispatch);

	const { t } = useTranslation();

	const [rejectNote, setRejectNote] = useState<string>("");

	const { orderId: orderIdFromParams } = useParams() as any;

	const useApprove = useDataApi();
	const useRejectApproval = useDataApi();

	const navigate = useNavigate();

	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const { status: orderStatus } = order;

	/* ******************** Handle Approval API call ***************************/

	useEffect(() => {
		const { data } = useApprove;

		if (data.status === "ok") {
			toast.success(t("Order approved"));
			setConfirmationProps({
				message: t("Order approved"),
				link: `../order/${orderIdFromParams}`,
			});
			navigate("/confirmation");
		}
	}, [useApprove.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useApprove;
		if (error) {
			toast.error(`${t("Unable to approve the order.")} ${error}`);
		}
	}, [useApprove.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	/* ******************** Handle Reject Approval API call ***************************/
	useEffect(() => {
		const { data } = useRejectApproval;

		if (data.status === "ok") {
			toast.success(t("Order rejected"));

			setConfirmationProps({
				message: t("Order rejected"),
				link: `../order/${orderIdFromParams}`,
			});
			navigate("/confirmation");
		}
	}, [useRejectApproval.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useRejectApproval;
		if (error) {
			toast.error(`${t("Unable to reject the order.")} ${error}`);
		}
	}, [useRejectApproval.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	const onRejectApproval = () => {
		setShowRejectNote(true);
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	};

	const onSubmitRejectApproval = () => {
		const { orderId } = order;

		if (!rejectNote) {
			alert(t("Please enter a reason for rejecting the order."));
		} else {
			useRejectApproval.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/status`,
				{
					status: OrderStatusesFlow.CHANGE_REQUESTED.code,
					order_ids: [orderId],
					note: rejectNote,
				},
				"PUT"
			);
		}
	};

	/* **************************************************** */

	const onApprove = () => {
		const { orderId } = order;

		useApprove.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/status`,
			{
				status: OrderStatusesFlow.APPROVED.code,
				order_ids: [orderId],
			},
			"PUT"
		);
	};

	/* **************************************************** */

	return (
		<div
			className={`orderApproveContentHeader ${
				showRejectNote && "orderApproveContentHeaderWithNote"
			}`}
		>
			<div className="pos-relative">
				<div className="flex between-sm">
					<div>
						<button
							className="link--back btn-no-style pos-relative mt--xs"
							style={{ top: 0 }}
							onClick={() => {
								if (fromOrders.current) {
									navigate(-1);
									fromOrders.current = false;
								} else {
									navigate("/orders");
								}
							}}
						>
							<Icon name="arrow-left-rounded" />
							{t("Back to orders")}
						</button>
					</div>
					{/* isBrand and status Request for approval */}
					{isBrand(account_type_id) &&
						orderStatus === OrderStatusesFlow.REVIEW_REQUESTED.code &&
						// Reject Approval / Approve buttons
						(!showRejectNote ? (
							<div className="flex">
								<button
									className={"button button--primary ml--base"}
									type="button"
									form="orderItemForm"
									onClick={onRejectApproval}
									data-testid="orderApproveHeader-reject"
								>
									{t("Reject Approval")}
								</button>

								{!useApprove.isLoading ? (
									<button
										className={"button button--primary ml--base"}
										type="button"
										form="orderItemForm"
										onClick={onApprove}
										data-testid="orderApproveHeader-approve"
									>
										{t("Approve")}
									</button>
								) : (
									<Loading
										show
										text={t("Loading...")}
										imgClass="approveOrderLoading"
										divClass="approveOrderLoadingWrapper"
									/>
								)}
							</div>
						) : (
							// Cancel / Submit buttons
							<div className="flex">
								<button
									className={"button button--primary ml--base"}
									type="button"
									form="orderItemForm"
									onClick={() => {
										setShowRejectNote(false);
									}}
									data-testid="orderApproveHeader-cancel"
								>
									{t("Cancel")}
								</button>
								{!useRejectApproval.isLoading ? (
									<button
										className={"button button--primary ml--base"}
										type="button"
										form="orderItemForm"
										onClick={onSubmitRejectApproval}
										data-testid="orderApproveHeader-submit"
									>
										{t("Submit")}
									</button>
								) : (
									<Loading
										show
										text={t("Loading...")}
										imgClass="rejectOrderLoading"
										divClass="rejectOrderLoadingWrapper"
									/>
								)}
							</div>
						))}

					{/* isBrand and status Approved */}
					{isBrand(account_type_id) &&
						orderStatus === OrderStatusesFlow.APPROVED.code && (
							<h2>{t("Order approved")}</h2>
						)}

					{isFactory(account_type_id) && (
						<h2 className="mt--xs mb--sm">
							{t("Order is waiting approval from Brand")}
						</h2>
					)}
				</div>
				<textarea
					className={`rejectNote ${showRejectNote && "rejectNoteOpen"} `}
					ref={textAreaRef}
					rows={4}
					placeholder={t("Please enter the reason for rejecting the order.")}
					value={rejectNote}
					onChange={(e) => setRejectNote(e.target.value)}
				></textarea>
			</div>
		</div>
	);
};

export default OrderApproveHeader;
