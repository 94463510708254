import { FC } from "react";
import usePageTitle from "Hooks/pageTitleHook";

import ReleaseNov24 from "./HelpWhatIsNew/Nov2024/Nov24";
import ReleaseDec23 from "./HelpWhatIsNew/Dec2023/Dec23";

interface IProps {}

const HelpWhatIsNew: FC<IProps> = () => {
	usePageTitle("Cacotec Help - What’s new");
	return (
		<div style={{ maxWidth: "800px", margin: "auto", textAlign: "justify" }}>
			<h2 className="helpTitle">What's new</h2>
			<hr />
			<ReleaseNov24 />
			<br />
			<h1>Older changes:</h1>
			<br />
			<br />
			<ReleaseDec23 />
		</div>
	);
};

export default HelpWhatIsNew;
