import React, { useEffect } from "react";

import {
	NavigationType,
	useLocation,
	useNavigationType,
} from "react-router-dom-v5-compat";
import Icon from "Components/Shared/Icon";

interface IProps {
	error: any;

	setError: (error: any) => void;
}

const Error: React.FunctionComponent<IProps> = ({ error, setError }) => {
	const { title, text, primary, secondary } = error;

	const location = useLocation();
	const navType: NavigationType = useNavigationType();

	useEffect(() => {
		return () => {
			if (navType === NavigationType.Pop) {
				setError(undefined);
			}
		};
	}, [navType, location]); // eslint-disable-line react-hooks/exhaustive-deps

	const onPrimaryAction = () => {
		primary.action();
		setError(undefined);
	};

	const onSecondaryAction = () => {
		secondary.action();
		setError(undefined);
	};

	return (
		<div className="main__content">
			<div className="container">
				<div className="row" id="chooseOptionContainer">
					<fieldset
						className="box box--light box--shadowed mb--md box--lg"
						id="chooseOptionContent"
						style={{ alignItems: "center", textAlign: "center" }}
					>
						<Icon
							name="sadface"
							className="icon icon--xxl mb--sm icon--no-fill"
						/>

						<h1 className="text">{title}</h1>
						<div className="text" style={{ marginTop: "10px" }}>
							{text}
						</div>

						{primary.text && (
							<button
								className="button button--primary button--primary-error text"
								onClick={onPrimaryAction}
								type="button"
							>
								{primary.text}
							</button>
						)}

						{secondary.text && (
							<button
								className="small button--secondary-error btn-no-style btn-link"
								onClick={onSecondaryAction}
							>
								{secondary.text}
							</button>
						)}
					</fieldset>
				</div>
			</div>
		</div>
	);
};

export default Error;
