import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import useDataApi from "Hooks/fetchHook";
import { isBrand, isFactory } from "Models/UserModels";
import { EndpointPrefix } from "Models/UserModels";
import { removeItemsWithMissingTranslations } from "Utils/utils";
import OrderDispatch from "Dispatches/OrderDispatch";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import { languagesCodeFullname } from "Models/GlobalModels";

const MissingTranslationsModal: React.FunctionComponent = () => {
	const { t } = useTranslation();
	const { user, missingTranslations, setMissingTranslations } =
		useContext(GlobalDispatch);

	const { orderItem, setOrderItem, order } = useContext(OrderDispatch);

	const orderBrandId = order.brandId;

	const { initialValues } = useFetchInitialValues(order, order.brandId);

	const {
		translations,
		madeIn,
		fabricContent,
		selectedCareInstructions,
		selectedAdditionalComponents,
	} = orderItem;

	const useSendMissingTranslations = useDataApi();

	const [textOfTranslation, setTextOfTranslation] = useState("");

	const {
		missingMadeIn,
		missingCareInstructions,
		missingContentComposition,
		missingAdditionalComponents,
		missingComponents,
		missingFibers,
	} = missingTranslations;

	useEffect(() => {
		const {
			madeInTranslations,
			fabricContentTranslations,
			careInstructionsTranslations,
			additionalComponentsTranslations,
		} = translations;

		if (Object.values(missingTranslations).length > 0) {
			// Remove values from order item
			let newFabricContent = fabricContent;
			let newMadeIn = madeIn;
			let newAdditionalComponents = selectedAdditionalComponents;
			let newAdditionalComponentsTranslations =
				additionalComponentsTranslations;
			let newCareInstructions = selectedCareInstructions;
			let newCareInstructionsTranslations = careInstructionsTranslations;

			if (
				missingContentComposition &&
				Object.values(missingContentComposition).length > 0
			) {
				newFabricContent = -1;
			}

			if (missingMadeIn && Object.values(missingMadeIn).length > 0) {
				newMadeIn = -1;
			}

			if (
				missingAdditionalComponents &&
				Object.values(missingAdditionalComponents).length > 0
			) {
				({
					filteredItems: newAdditionalComponents,
					filteredItemsTranslations: newAdditionalComponentsTranslations,
				} = removeItemsWithMissingTranslations(
					additionalComponentsTranslations,
					missingAdditionalComponents,
					selectedAdditionalComponents,
					initialValues.additionalComponents
				));
			}

			if (
				missingCareInstructions &&
				Object.values(missingCareInstructions).length > 0
			) {
				({
					filteredItems: newCareInstructions,
					filteredItemsTranslations: newCareInstructionsTranslations,
				} = removeItemsWithMissingTranslations(
					careInstructionsTranslations,
					missingCareInstructions,
					selectedCareInstructions,
					initialValues.careInstructions
				));
			}

			if (
				newFabricContent === -1 ||
				newMadeIn === -1 ||
				(missingCareInstructions &&
					Object.values(missingCareInstructions).length > 0) ||
				(missingAdditionalComponents &&
					Object.values(missingAdditionalComponents).length > 0)
			) {
				setOrderItem({
					fabricContent: newFabricContent,
					madeIn: newMadeIn,
					selectedCareInstructions: newCareInstructions,
					selectedAdditionalComponents: newAdditionalComponents,
					translations: {
						fabricContentTranslations: !newFabricContent
							? {}
							: fabricContentTranslations,
						madeInTranslations: !newMadeIn ? {} : madeInTranslations,
						careInstructionsTranslations: newCareInstructionsTranslations,
						additionalComponentsTranslations:
							newAdditionalComponentsTranslations,
					},
				});
			}
		}
	}, [missingTranslations]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************** Send missing translations ************* */
	useEffect(() => {
		const { status } = useSendMissingTranslations.data;
		if (status === "ok") {
			setMissingTranslations({});
			setTextOfTranslation("");
			toast.success(t("Information sent successfully. Thank you!"));
		}
	}, [useSendMissingTranslations.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSendMissingTranslations;
		if (error) {
			toast.error(t(`Unable to send missing translations. ${error}`));
		}
	}, [useSendMissingTranslations.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *************************************************************************************** */

	const textToDisplay = Object.values(missingTranslations).map(
		(translation: any) => {
			return (
				translation &&
				Object.keys(translation).map((missingInfoString: string) => {
					// If missing translation is set on frontend it will be in a format
					// translation[missingInfoString]  =
					// 	ABACA: ["fr"],
					// 	ACETATE: ["zh-Hans, fr"],
					// };
					// TODO see if we can unify this by changing missing translation format on frontend
					//
					// If missing translation is send from backend when we create new Content composition it will be in a format
					// translation[missingInfoString]  =
					// 	ABACA: ["fr"],
					// 	ACETATE: ["zh-Hans", "fr"],
					// };
					// thats the reason we have a ternary operator, so we can process both cases

					let languagesCodesArray =
						translation[missingInfoString].length > 1
							? translation[missingInfoString]
							: translation[missingInfoString][0].split(",");

					translation[missingInfoString][0].split(",");
					let languagesFullNamesArray = languagesCodesArray.map(
						(code: string) => " " + languagesCodeFullname[code]
					);

					return ` ${missingInfoString}: ${languagesFullNamesArray}\r\n`;
				})
			);
		}
	);

	const onSubmit = () => {
		const { account_type_id } = user;
		if (isBrand(account_type_id) || isFactory(account_type_id)) {
			const content = `${textToDisplay}; ${textOfTranslation}`.trim();
			const brandIdForFactory = isFactory(account_type_id)
				? `?brandId=${orderBrandId}`
				: "";
			useSendMissingTranslations.doFetch(
				`/${EndpointPrefix[account_type_id]}/translations/reportMissing${brandIdForFactory}`,
				{ content },
				"POST"
			);
		}
	};

	const showModal =
		(missingContentComposition &&
			Object.values(missingContentComposition).length > 0) ||
		(missingCareInstructions &&
			Object.values(missingCareInstructions).length > 0) ||
		(missingMadeIn && Object.values(missingMadeIn).length > 0) ||
		(missingAdditionalComponents &&
			Object.values(missingAdditionalComponents).length > 0) ||
		(missingComponents && Object.values(missingComponents).length > 0) ||
		(missingFibers && Object.values(missingFibers).length > 0);

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			setMissingTranslations({});
		}
	};

	return (
		<>
			{showModal && (
				<div className="modal flex-center-both-axis">
					<div
						className="modal-content-missing-translations show suggestedCareInstructionsModal"
						tabIndex={-1}
						id="missingTranslationsModal"
						onKeyDown={onKeyDown}
					>
						<Loading
							show={useSendMissingTranslations.isLoading}
							text={t("Loading...")}
							imgClass="block-center"
							divClass="main__content"
						/>

						{!useSendMissingTranslations.isLoading && (
							<>
								<button
									className="drawer__close btn-no-style"
									data-dismiss="drawer"
									aria-label={t("Close")}
									onClick={() => setMissingTranslations({})}
								>
									<Icon name="cross-rounded" />
								</button>
								<div>
									<header className="drawer__header">
										<h3 className="drawer__title">
											{t("Detected missing translations:")}
										</h3>
									</header>
								</div>
								<div style={{ marginBottom: "40px", whiteSpace: "pre-line" }}>
									{textToDisplay}
								</div>
								<div>
									<p>
										{t(
											"If you have the translations, you can enter them here (leave empty if you don't):"
										)}
									</p>
									<div className="form-group">
										<textarea
											className="form-control"
											id="textToSend"
											name="textToSend"
											rows={5}
											data-empty={!textOfTranslation}
											autoFocus={true}
											value={textOfTranslation}
											onChange={(e) => setTextOfTranslation(e.target.value)}
										/>
									</div>
								</div>

								<div className="mb--md">
									<div className="missingTranslationsNotes">
										<p>
											{t(
												"Once you submit the request, someone from our team will contact you as soon as translations are processed."
											)}
										</p>
										<p>
											<b>{t("Note")}:</b>{" "}
											{t(
												"items with missing translations will be automatically removed from the order. If you submit the missing translations, we will notify you once they are added to the system so that you can go back and continue with the order."
											)}
										</p>
									</div>
									<div
										className="button button--primary button--sm"
										onClick={onSubmit}
										style={{ marginTop: "20px" }}
									>
										{t("Submit")}
									</div>
									<div
										className="button button--primary button--sm"
										onClick={() => setMissingTranslations({})}
										style={{ marginTop: "20px", marginLeft: "30px" }}
									>
										{t("Cancel")}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default MissingTranslationsModal;
