import { Draggable, Droppable } from "@hello-pangea/dnd";
import { t } from "i18next";
import { appEnvSymbolsStorageName } from "Models/GlobalModels";
import { capitalize } from "Utils/utils";

interface IProps {
	symbolGroupName: string;
	allSymbols: any;
	symbolSet: string;

	onClickSymbol: (symbol: any, index: number) => void;
}

let currentSymbolsStorageName =
	appEnvSymbolsStorageName[
		import.meta.env.VITE_APP_ENV as keyof typeof appEnvSymbolsStorageName
	];

const AllSymbolGroup = ({
	symbolGroupName,
	allSymbols,
	symbolSet,
	onClickSymbol,
}: IProps) => {
	return (
		<div>
			<div className="" style={{ color: "#999" }}>
				<div>{t(capitalize(symbolGroupName))}</div>
			</div>
			<Droppable droppableId={`${symbolGroupName}-all`} direction="horizontal">
				{(provided) => (
					<div
						className="flex flex-wrap"
						style={{ minHeight: "47px" }}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{allSymbols[symbolGroupName].map((symbol: any, index: number) => {
							let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
							return (
								<Draggable
									key={symbol.symbol_name + index}
									draggableId={`${symbol.symbol_id}`}
									index={index}
								>
									{(provided) => (
										<div
											className=""
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={{
												cursor: "move",
												...provided.draggableProps.style,
											}}
										>
											<img
												src={symbolUrlStorage}
												width={32}
												height={43}
												style={{ margin: "2px 5px" }}
												title={symbol.display_name}
												onClick={() => {
													onClickSymbol(symbol, index);
												}}
											/>
										</div>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	);
};

export default AllSymbolGroup;
