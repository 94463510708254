import React from "react";
import loadingGif from "images/spinner.gif";

interface IProps {
	show: boolean;
	text?: string;
	imgClass: string;
	divClass: string;
	imgStyle?: any;
}

const Loading: React.FunctionComponent<IProps> = ({
	show,
	text,
	imgClass,
	divClass,
	imgStyle,
}) => {
	if (!show) {
		return null;
	}

	return (
		<div className={divClass} data-testid="loading">
			<img
				className={imgClass}
				src={loadingGif}
				style={imgStyle}
				alt={text}
				width={"80px"}
			/>
		</div>
	);
};

export default Loading;
