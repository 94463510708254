import React from "react";

import contentCompositionAddNew from "./images/content-composition-add-new.png";
import contentCompositionEdit from "./images/content-composition-edit.png";
import contentComposition from "./images/content-composition.png";
import suggestedCareInstructions from "./images/suggested-care-instructions.png";
import chooseExistingContentComposition from "./images/choose-existing-content-composition.png";
import additionalComponents from "./images/additional-components.png";
import usePageTitle from "Hooks/pageTitleHook";

const ContentCompositionHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Content composition`);

	return (
		<>
			<h2 className="helpTitle">Content composition</h2>
			<article className="helpArticle">
				<p>
					For content composition you can either choose an existing or add a new
					one.
				</p>

				<p className="helpSubtitle">Choose existing</p>
				<p>
					If you already have the needed content composition in the database (if
					you used/added it before), you can search for it by simply typing:
				</p>
				<img
					src={chooseExistingContentComposition}
					className="helpImage"
					alt="Choose existing content composition"
				/>
				<p>
					Choosing a content composition will trigger a dialog offering care
					instructions suggestions for that composition:
				</p>
				<img
					src={suggestedCareInstructions}
					className="helpImage"
					alt="Suggested care instructions"
				/>
				<p>
					You can choose an option from the list by clicking <b>Use this one</b>
					, and care instructions will be auto populated with shown care
					instructions. If you wish to populate everything by yourself, you can
					choose <b>I will enter care instructions manually</b>.
				</p>

				<p className="helpSubtitle">Edit existing</p>
				<p>
					To edit an existing content composition, click on the edit button next
					to it:
				</p>
				<img
					src={contentCompositionEdit}
					className="helpImageLarge"
					alt="Edit content composition"
				/>
				<p>This will open the edit content composition form:</p>
				<img
					src={contentComposition}
					className="helpImage"
					alt="Content composition form"
				/>
				<p>
					In this form you can edit to content composition as you need.{" "}
					<b>You can drag and drop a component to rearrange the order.</b> There
					are some rules that apply:
				</p>
				<ul>
					<li>percentages in one component must always add up to 100</li>
					<li>
						if you uncheck the <b>Show percentage</b> option, you can only have
						one fiber in that component
					</li>
					<li>
						you can leave out one component name (e.g. you can have only one
						100% COTTON component)
					</li>
				</ul>

				<p className="helpSubtitle">Add new</p>
				<p>
					If you can't find the content you need, you can always add a new
					content composition, by clicking the
					<b> Create new Composition</b> button, as shown in the image above.
				</p>
				<img
					src={contentCompositionAddNew}
					className="helpImage"
					alt="Add new content composition"
				/>
				<p>
					It opens up the form explained above, so you can create a content
					composition you need easily.
				</p>

				<p className="helpSubtitle">Additional components</p>
				<p>
					You can add information to fabric content with additional components.
					Usually, this is used for components which don’t have fibers or whose
					usage requires additional actions.
				</p>
				<img
					src={additionalComponents}
					className="helpImage"
					alt="Additional components"
				/>
				<p>
					Click <b>Add Additional Components</b> to add additional components.
					If you choose a component that requires additional information to be
					entered, fields for entering those information will show up
					underneath.
				</p>
			</article>
		</>
	);
};

export default ContentCompositionHelp;
