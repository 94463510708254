import React from "react";
import { Link } from "react-router-dom-v5-compat";
import { utils, writeFile } from "xlsx";

import stylesPage from "./images/styles-page.png";
import importStyles from "./images/import-styles.png";
import importedStylesOverview from "./images/imported-styles-overview.png";
import importStylesErrors from "./images/import-styles-errors.png";
import importStylesSuccess from "./images/import-styles-success.png";

import { XLSX_STYLE_COLUMNS_NAMES } from "Models/OrderModels";
import usePageTitle from "Hooks/pageTitleHook";

const StylesPageHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Styles page`);

	const exportFile = () => {
		const ws = utils.aoa_to_sheet([Object.values(XLSX_STYLE_COLUMNS_NAMES)]);
		const wscols = [
			{ wch: 12 },
			{ wch: 20 },
			{ wch: 12 },
			{ wch: 25 },
			{ wch: 45 },
			{ wch: 45 },
			{ wch: 30 },
			{ wch: 7 },
		];

		ws["!cols"] = wscols;
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "SheetJS");
		/* generate  file and send to client */
		writeFile(wb, "ImportStylesExcelFormat.xlsx");
	};

	return (
		<>
			<h2 className="helpTitle">Styles page</h2>
			<article className="helpArticle">
				<p>
					In the styles page you will be able to see all styles previously
					imported by your brand or factory with all of the information.
				</p>
				<img src={stylesPage} className="helpImage" alt="Styles overview" />
				<p>
					By default, the information are collapsed. If you want to see all
					details for a style, click on it to expand.
				</p>

				<p className="helpSubtitle">Import styles</p>
				<p>
					To import new styles, click on the <b>Import styles</b> on top. New
					window appears:
				</p>
				<img src={importStyles} className="helpImage" alt="Import styles" />
				<p>
					You can drag &amp; drop your file directly onto the designated area or
					you can click <b>Select file </b>
					and then navigate to a file on your computer.
				</p>
				<p className="helpSubtitle">File format</p>
				<p>
					File needs to be in a format defined by Cacotec. To{" "}
					<b>download a file in a correct format</b> that you can start using
					straightaway, click{" "}
					<button className="btn-no-style btn-link" onClick={exportFile}>
						here
					</button>
					. You can also download a file with detailed explanations and examples{" "}
					<Link
						to="/files/ImportStylesExcelExample.xlsx"
						download
						target="_blank"
					>
						here
					</Link>
					.
				</p>

				<p className="helpSubtitle">Import process</p>
				<p>
					Once you select a file, it will be parsed and the result shown in a
					table:
				</p>
				<img
					src={importedStylesOverview}
					className="helpImage"
					alt="Imported styles overview"
				/>
				<p>
					If you see a mistake or you want to change something, click{" "}
					<b>Clear</b> in the bottom, update the file and try again. If you are
					satisfied with the result and want to continue, click{" "}
					<b>Finalize import</b> on the bottom right.
				</p>

				<p className="helpSubSubtitle">Overwriting styles</p>
				<p>
					If you are importing files that are already in the database, you will
					be given a choice to cancel, so you can go back, update the file and
					try again, or you can continue with the process and update the
					existing styles with new information.
				</p>

				<p className="helpSubSubtitle">Errors</p>
				<p>
					If there are some errors while you are importing files, you will get a
					general error message plus info about how many styles are ready to be
					added and how many have errors:
				</p>
				<img
					src={importStylesErrors}
					className="helpImage"
					alt="Import styles errors"
				/>
				<p>
					In the table you will see only the styles that have errors. Parts that
					are not correct are highlighted in red. If you hover your mouse over a
					highlighted item, you will get more information about the error
					itself. You can go through the list, correct the errors in your file
					and try again.
				</p>

				<p className="helpSubSubtitle">Success</p>
				<p>
					After the styles have been uploaded correctly, you will a summary of
					what happened:
				</p>
				<img
					src={importStylesSuccess}
					className="helpImage"
					alt="Import styles errors"
				/>
			</article>
		</>
	);
};

export default StylesPageHelp;
