import Chart, { ChartTypeRegistry } from "chart.js/auto";
import { useRef, useEffect, FC, CSSProperties } from "react";

interface IChartProps {
	type: keyof ChartTypeRegistry;
	data: any;
	options?: any;
	chartId: string; // unique, like key in react map
	wrapperClass?: string;
	wrapperStyle?: CSSProperties;
}

const ChartComponent: FC<IChartProps> = ({
	type,
	data,
	options,
	chartId,
	wrapperClass,
	wrapperStyle,
}) => {
	const chartRef = useRef<any>(null);

	useEffect(() => {
		if (chartRef.current) {
			new Chart(chartRef.current, {
				type,
				data,
				options,
			});
		}

		return () => {
			let chartStatus = Chart.getChart(chartId);
			if (chartStatus !== undefined) {
				chartStatus.destroy();
			}
		};
	}, [type, data, options, chartId]);

	return (
		<div className={`pos-relative ${wrapperClass}`} style={wrapperStyle}>
			<canvas id={chartId} ref={chartRef}></canvas>
		</div>
	);
};

export default ChartComponent;
