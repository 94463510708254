import React from "react";

import OrderItemsPreviewButtons from "Components/Order/OrderItemsPreview/components/OrderItemsPreviewButtons";
import Icon from "Components/Shared/Icon";

interface IProps {
	setShowAddExistingStyleModal: (modalState: boolean) => void;
}

const EmptyOrderForm: React.FunctionComponent<IProps> = ({
	setShowAddExistingStyleModal,
}) => {
	return (
		<div className="content-column__body">
			<div className="box box--sm box--bordered" id="emptyOrderBox">
				<h1 className="text--quiet" data-testid="noStyles">
					No Styles
				</h1>
				<Icon name="garment" className="icon icon--xxl mb--sm" />
				<div className="txtc text-sm text--quiet" data-testid="noStylesMessage">
					You haven’t added any <br /> styles to this order.
				</div>
				<OrderItemsPreviewButtons
					forPlaceholder={true}
					setShowAddExistingStyleModal={setShowAddExistingStyleModal}
				/>
			</div>
		</div>
	);
};

export default EmptyOrderForm;
