export const QUERY_KEYS = {
	ACCOUNT_EXECUTIVES: "accountExecutives",
	HELP_VIDEO_NAMES: "helpVideoNames",
	BRANDS: "brands",
	SHIPPING_DETAILS: "shippingDetails",
	SHIPPING_METHODS: "shippingMethods",
	INITIAL_VALUES: "initialValues",
	INITIAL_VALUES_LABEL_TEMPLATES: "initialValuesLabelTemplates",
	LABEL_TEMPLATES: "labelTemplates",
	PRINTERS: "printers",
};
