import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

import OrderDispatch from "Dispatches/OrderDispatch";
import HelpTooltip from "Components/Shared/HelpTooltip";
import { IUnit } from "Models/OrderModels";
import Popup from "Components/Shared/Popup";
import Icon from "Components/Shared/Icon";
import { blockFloatingPointNumbers, deepCopy } from "Utils/utils";
import { Tooltip } from "react-tooltip";
import useOnClickOutside from "Hooks/outsideClickHook";
import { useTranslation } from "react-i18next";

const UnitInformation: React.FunctionComponent = () => {
	const { t } = useTranslation();

	const quantityInputRef = useRef(null) as any;
	const upcRef = useRef(null) as any;
	const tooltipRef = useRef(null) as any;

	const [addAnotherUnitClicked, setAddAnotherUnitClicked] = useState(false);

	const [duplicatedUnitIndex, setDuplicatedUnitIndex] =
		useState<any>(undefined);

	const duplicatedUnitRef = useRef<HTMLDivElement>(null);

	const {
		orderItem,
		setOrderItem,
		order: { labelTemplate },
	} = useContext(OrderDispatch);
	const { minimum, step } = labelTemplate.settings.form_settings.quantity;

	let hideUnits: any;
	if (labelTemplate?.settings?.form_settings?.units) {
		hideUnits = labelTemplate.settings.form_settings.units;
	}

	let sizes = {} as any;
	if (labelTemplate?.additional_data) {
		const { data } = labelTemplate.additional_data;
		if (data) {
			({ sizes } = data);
		}
	}

	const { units } = orderItem;

	useEffect(() => {
		if (units.length > 1 && upcRef.current && addAnotherUnitClicked) {
			upcRef.current.focus();
		}
	}, [units]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (duplicatedUnitIndex >= 0) {
			if (duplicatedUnitRef && duplicatedUnitRef.current) {
				duplicatedUnitRef.current.scrollIntoView({
					block: "center",
					behavior: "smooth",
				});
			}
		}
	}, [duplicatedUnitIndex]);

	const onclickOutsideTooltip = () => {
		setDuplicatedUnitIndex(undefined);
	};

	useOnClickOutside(
		tooltipRef,
		useCallback(onclickOutsideTooltip, [setDuplicatedUnitIndex]) // eslint-disable-line react-hooks/exhaustive-deps
	);

	const changeOrderData = (unitData: any, index: number) => {
		const unitsArray = deepCopy(units);

		let changedUnitData = { ...unitsArray[index], ...unitData };

		let concatenatedChangedUnitValue = concatenateUnitValues(changedUnitData);

		let duplicatedUnitIndex = checkDuplicatedUnits(
			unitsArray,
			concatenatedChangedUnitValue
		);

		if (duplicatedUnitIndex !== null && !("quantity" in unitData)) {
			setDuplicatedUnitIndex(duplicatedUnitIndex);
		} else {
			unitsArray[index] = { ...unitsArray[index], ...unitData };
			setOrderItem({ units: unitsArray });
		}

		if (addAnotherUnitClicked) {
			setAddAnotherUnitClicked(false);
		}
	};

	const onRemoveUnit = (index: any) => {
		units.splice(index, 1);
		setOrderItem({ units });
	};

	const onAddUnit = () => {
		let newUnit = {
			upc: "",
			color: "",
			size: "",
			quantity: minimum,
			blackLabel:
				labelTemplate?.settings?.default_values?.default_black_label || false,
		};

		let newUnitConcatenated = `${newUnit.blackLabel}`;

		let duplicatedUnitIndex = checkDuplicatedUnits(units, newUnitConcatenated);

		if (duplicatedUnitIndex !== null) {
			setDuplicatedUnitIndex(units.length - 1);
		} else {
			let newUnits = [...units, newUnit];
			setOrderItem({
				units: newUnits,
			});
		}

		setAddAnotherUnitClicked(true);
	};

	const checkDuplicatedUnits = (units: any, concatenatedUnitValue: string) => {
		let concatenatedArray: string[] = units.map((unit: any) => {
			let concatenateUnitValue = concatenateUnitValues(unit);
			return concatenateUnitValue;
		});

		let indexOfDuplicate = concatenatedArray.findIndex(
			(element) => element === concatenatedUnitValue
		);

		if (indexOfDuplicate >= 0) {
			return indexOfDuplicate;
		} else return null;
	};

	const concatenateUnitValues = (unit: {
		blackLabel: string;
		color: string;
		size: string;
		upc: string;
	}) => {
		return (
			String(unit.blackLabel) +
			String(unit.color || "") +
			String(unit.size || "") +
			String(unit.upc || "")
		);
	};

	let totalQuantity = 0;
	if (orderItem.units.length > 0) {
		totalQuantity = orderItem.units
			.map((unit: IUnit) => unit.quantity)
			.reduce((prev: number, next: number) => Number(prev) + Number(next));
	}

	return (
		<>
			<div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>{t("Units")}</span>
				<HelpTooltip helpFor="UnitsInformation" />
			</div>
			<fieldset className="units box box--light box--shadowed mb--md">
				{units.map((oneUnit: IUnit, index: number) => {
					const { upc, color, size, quantity, blackLabel } = oneUnit;

					const invalidSize =
						!Array.isArray(sizes) && Object.keys(sizes)?.length > 0
							? size?.length > 0 &&
							  !size.startsWith("1SZ") &&
							  !Object.keys(sizes).find((sizeGroup: string) =>
									sizes[sizeGroup].includes(size)
							  )
							: false;
					return (
						<div
							key={index}
							className={`mb--md mb-sm--base ${
								duplicatedUnitIndex >= 0 &&
								duplicatedUnitIndex === index &&
								"units-duplicated"
							}`}
							style={{ padding: "15px 0" }}
							data-tooltip-place="top"
							data-tooltip-id={
								duplicatedUnitIndex >= 0 && duplicatedUnitIndex === index
									? "unitTooltip"
									: undefined
							}
							ref={
								duplicatedUnitIndex >= 0 && duplicatedUnitIndex === index
									? duplicatedUnitRef
									: null
							}
						>
							<div className="row" style={{ padding: "0 15px" }}>
								{(!hideUnits || (hideUnits && !hideUnits.hide_upc)) && (
									<div className="col-sm-6">
										<div className="form-group">
											<input
												ref={upcRef}
												className="form-control"
												type="text"
												name={`upc${index}`}
												id={`upc${index}`}
												value={upc ? upc : ""}
												data-empty={!upc}
												onChange={(e) =>
													changeOrderData({ upc: e.target.value.trim() }, index)
												}
												onBlur={(e) =>
													changeOrderData({ upc: e.target.value.trim() }, index)
												}
											/>
											<label
												className="form-control-label"
												htmlFor={`upc${index}`}
											>
												{t("UPC")}
											</label>
										</div>
									</div>
								)}

								<div className="col-sm-6">
									<div className="form-group">
										<Popup
											show={quantity < minimum || quantity % step !== 0}
											text={t(
												"Value must be greater than or equal to {{minimum}} and in increments of {{step}}.",
												{ minimum, step }
											)}
											testId="unitInformation-quantity"
										/>
										<input
											ref={quantityInputRef}
											className="form-control"
											type="number"
											min={minimum}
											step={step}
											name={`quantity${index}`}
											id={`quantity${index}`}
											data-empty={!quantity}
											value={!quantity ? "" : quantity}
											onInvalid={(e) => {
												e.preventDefault();
												quantityInputRef.current &&
													window.scrollTo(
														0,
														quantityInputRef.current.offsetTop
													);
											}}
											onChange={(e) =>
												changeOrderData(
													{ quantity: Number(e.target.value.trim()) },
													index
												)
											}
											onKeyDown={blockFloatingPointNumbers}
										/>
										<label
											className="form-control-label"
											htmlFor={`quantity${index}`}
										>
											{t("Qty.")}
										</label>
									</div>
								</div>

								{(!hideUnits || (hideUnits && !hideUnits.hide_color)) && (
									<div className="col-sm-6">
										<div className="form-group">
											<input
												className="form-control"
												type="text"
												name={`color${index}`}
												id={`color${index}`}
												data-empty={!color}
												value={color ? color : ""}
												onChange={(e) =>
													changeOrderData(
														{ color: e.target.value.trim() },
														index
													)
												}
												onBlur={(e) =>
													changeOrderData(
														{ color: e.target.value.trim() },
														index
													)
												}
											/>
											<label
												className="form-control-label"
												htmlFor={`color${index}`}
											>
												{t("Color")}
											</label>
										</div>
									</div>
								)}

								{(!hideUnits || (hideUnits && !hideUnits.hide_size)) && (
									<div className="col-sm-6">
										<div
											className={`form-group ${invalidSize && "has-danger"}`}
										>
											<Popup
												show={invalidSize}
												text={t("Size not valid.")}
												testId="unitInformation-size"
											/>
											{!Array.isArray(sizes) &&
											Object.keys(sizes)?.length > 0 &&
											(Object.keys(sizes).find((sizeGroup: string) =>
												sizes[sizeGroup].includes(size)
											) ||
												!size) &&
											!size?.startsWith("1SZ") ? (
												<div style={{ pointerEvents: "none" }}>
													<select
														value={size}
														className="form-control"
														id={`size${index}`}
														data-empty={!size}
														style={{
															paddingBottom: "13px",
															pointerEvents: "auto",
															cursor: "pointer",
														}}
														onChange={(e) =>
															changeOrderData(
																{ size: e.target.value.trim() },
																index
															)
														}
														data-testid="chooseSize-unitInformation"
													>
														<option value="" disabled hidden></option>

														{Object.keys(sizes).map((sizeGroup: string) => (
															<optgroup key={sizeGroup} label={sizeGroup}>
																{sizes?.[sizeGroup] &&
																	sizes[sizeGroup].map(
																		(value: any, index: number) => {
																			return (
																				<option
																					style={{ color: "#000" }}
																					key={`${value}-${index}`}
																					value={value}
																					data-testid={`unitInformation-sizes-${value}`}
																				>
																					{value}
																				</option>
																			);
																		}
																	)}
															</optgroup>
														))}
													</select>
													<label
														className="form-control-label"
														htmlFor={`size${index}`}
													>
														{t("Size")}
													</label>
												</div>
											) : (
												<figure
													id="incrementalSearchInput"
													className={size ? "uploaded-images__figure" : ""}
												>
													<input
														className="form-control"
														type="text"
														name={`size${index}`}
														id={`size${index}`}
														data-empty={!size}
														value={size ? size : ""}
														onChange={(e) =>
															changeOrderData(
																{ size: e.target.value.trim() },
																index
															)
														}
													/>
													<label
														className="form-control-label"
														htmlFor={`size${index}`}
													>
														{t("Size")}
													</label>
													<button
														className="uploaded-images__remove btn-no-style"
														style={{ top: "auto", right: "10px" }}
														id={"CloseInputSize"}
														onClick={() => changeOrderData({ size: "" }, index)}
													>
														<Icon
															name="cross-rounded-filled"
															className="searchClearIcon"
														/>
													</button>
												</figure>
											)}
										</div>
									</div>
								)}

								{(!hideUnits || (hideUnits && !hideUnits.hide_black_label)) && (
									<div className="col-sm-12">
										<div className="form-group mb--sm">
											<div className="form-check">
												<input
													className="form-check__input"
													type="checkbox"
													checked={blackLabel ? true : false}
													name={`blackLabel${index}`}
													id={`blackLabel${index}`}
													onChange={(e) =>
														changeOrderData(
															{ blackLabel: !units[index].blackLabel },
															index
														)
													}
												/>
												<label
													className="form-check__label"
													htmlFor={`blackLabel${index}`}
												>
													{t("Black ground label")}
												</label>
											</div>
										</div>
									</div>
								)}
							</div>

							{units.length > 1 &&
								(!hideUnits || (hideUnits && !hideUnits.hide_add_another)) && (
									<button
										className="link--danger small btn-no-style"
										style={{ padding: "0 15px" }}
										onClick={() => onRemoveUnit(index)}
										data-testid="remove-unitInformation"
									>
										{t("Remove")}
									</button>
								)}
						</div>
					);
				})}

				{(!hideUnits || (hideUnits && !hideUnits.hide_add_another)) && (
					<div className="row">
						<div className="col-sm-6">
							<button
								className="button button--outline-dark button--sm"
								id="blueBorderButton"
								onClick={onAddUnit}
								data-testid="addAnother-unitInformation"
							>
								{t("Add Another")}
							</button>
						</div>

						<div
							className="col-sm-6"
							data-testid="unitInformation-totalQuantity"
						>
							{t("Total quantity: {{totalQuantity}}", { totalQuantity })}
						</div>
					</div>
				)}
				{duplicatedUnitIndex >= 0 && (
					<Tooltip
						id="unitTooltip"
						className="react-tooltip-units"
						isOpen={duplicatedUnitIndex >= 0}
						clickable
					>
						<div ref={tooltipRef}>
							<span>
								{t("A unit with the same data has already been added.")}
							</span>
							<button
								className="button button--primary button--sm ml--base"
								onClick={() => {
									setDuplicatedUnitIndex(undefined);
								}}
							>
								{t("OK")}
							</button>
						</div>
					</Tooltip>
				)}
			</fieldset>
		</>
	);
};

export default UnitInformation;
