import { useRef, useCallback, FC, useEffect } from "react";

import Icon from "Components/Shared/Icon";
import useOnClickOutside from "Hooks/outsideClickHook";

interface IProps {
	showDrawer: boolean;
	orientation: "left" | "right";
	navigation?: boolean;
	isModalActive?: boolean;
	children: any;

	setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const Drawer: FC<IProps> = ({
	showDrawer,
	orientation,
	navigation,
	isModalActive,
	children,

	setShowDrawer,
}) => {
	const drawerRef = useRef(null) as any;
	const drawerContentRef = useRef(null) as any;

	const onCloseDrawer = () => {
		if (isModalActive) {
			return;
		}
		setShowDrawer(false);
	};

	useOnClickOutside(
		drawerContentRef,
		useCallback(onCloseDrawer, [onCloseDrawer])
	);

	useEffect(() => {
		if (!isModalActive && showDrawer) {
			setTimeout(() => {
				drawerRef.current.focus();
			}, 50);
		}
	}, [isModalActive, showDrawer]);

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCloseDrawer();
		}
	};

	return (
		<div>
			<aside
				className={`drawer--component drawer--component--${orientation} ${
					navigation && "navigation--menu"
				} ${showDrawer && `drawer--component--show`} flex`}
				id="printerInfoDrawer"
				role="menu"
				ref={drawerRef}
				aria-label="Preview"
				tabIndex={-1}
				onKeyDown={onKeyDown}
			>
				<div ref={drawerContentRef} className="drawer--component--content">
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={() => onCloseDrawer()}
						style={{ transition: "none" }}
					>
						<Icon name="cross-rounded" />
					</button>
					<div className="height-100">{children}</div>
				</div>
			</aside>
		</div>
	);
};

export default Drawer;
