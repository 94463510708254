import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import AdminFactories from "Components/Admin/AdminFactories";
import AdminTranslations from "Components/Admin/AdminTranslations";

import "./styles/Settings.css";
import usePageTitle from "Hooks/pageTitleHook";

const AdminSettings: React.FunctionComponent = () => {
	const location = useLocation();

	useEffect(() => {
		if (location.hash) {
			setTab(location.hash);
		}
	}, [location.hash]);

	usePageTitle("Settings");

	const [tab, setTab] = useState("#tab-factories");

	return (
		<React.Fragment>
			<div className="main__content" data-testid="defaultPageAfterLogin">
				<div className="container">
					<header>
						<h1 className="section__title">Settings</h1>
					</header>
					<div className="mb--base hidden--sm-up">
						<select
							className="js-tab-selector form-control"
							onChange={(event) => setTab(event.target.value)}
						>
							<option value="#tab-factories">Factories</option>
							<option value="#tab-translations">Translations</option>
						</select>
					</div>

					<ul className="tabs nav hidden--sm-down" role="tablist">
						<li>
							<a
								onClick={() => setTab("#tab-factories")}
								className={`tab ${tab === "#tab-factories" && "active show"}`}
								href="#tab-factories"
								data-toggle="tab"
								role="tab"
								aria-controls="tab-toggle-general"
								aria-selected="true"
								id="tab-toggle-general"
								data-testid="factoriesSettingsTab"
							>
								Factories
							</a>
						</li>

						<li>
							<a
								onClick={() => setTab("#tab-translations")}
								className={`tab ${
									tab === "#tab-translations" && "active show"
								}`}
								href="#tab-translations"
								data-toggle="tab"
								role="tab"
								aria-controls="tab-toggle-general"
								aria-selected="true"
								id="tab-toggle-general"
								data-testid="translationsSettingsTab"
							>
								Translations
							</a>
						</li>
					</ul>

					<div className="tab-content mt--base">
						{tab === "#tab-factories" && <AdminFactories />}
						{tab === "#tab-translations" && <AdminTranslations />}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AdminSettings;
