import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import { isBrand, isLabelMaker } from "Models/UserModels";

import SettingsAddresses from "Components/Settings/SettingsAdresses";
import SettingsAccount from "Components/Settings/SettingsAccount";

import "./styles/Settings.css";
import usePageTitle from "Hooks/pageTitleHook";
import DppCustomization from "Components/Settings/DppCustomization";
import { brandDppAppLabelTemplates } from "Utils/utils";
import { useFetchLabelTemplates } from "Hooks/queryHooks/useFetchLabelTemplates";
import { useTranslation } from "react-i18next";
import SettingsLanguage from "Components/Settings/SettingsLanguage";

const Settings: React.FunctionComponent = () => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
		setError,
	} = useContext(GlobalDispatch);

	const { labelTemplates } = useFetchLabelTemplates(account_type_id, setError);

	const location = useLocation();

	useEffect(() => {
		if (location.hash) {
			setTab(location.hash);
		}
	}, [location.hash]);

	usePageTitle(t("Settings"));

	const [tab, setTab] = useState("#tab-account");

	return (
		<>
			<div className="main__content" data-testid="defaultPageAfterLogin">
				<div className="container">
					<header>
						<h1 className="section__title">{t("Settings")}</h1>
					</header>
					<div className="mb--base hidden--sm-up">
						<select
							className="js-tab-selector form-control"
							onChange={(event) => setTab(event.target.value)}
						>
							<option value="#tab-account">{t("Account")}</option>
							{!isLabelMaker(account_type_id) && (
								<option value="#tab-addresses">{t("Addresses")}</option>
							)}
							<option value="#tab-language">{t("Language")}</option>
							{isBrand(account_type_id) &&
								brandDppAppLabelTemplates(labelTemplates).length > 0 && (
									<option value="#tab-dpp-customization">
										{t("Dpp Customization")}
									</option>
								)}
						</select>
					</div>

					<ul className="tabs nav hidden--sm-down" role="tablist">
						<li>
							<a
								onClick={() => setTab("#tab-account")}
								className={`tab ${tab === "#tab-account" && "active show"}`}
								href="#tab-account"
								data-toggle="tab"
								role="tab"
								aria-controls="tab-toggle-general"
								aria-selected="true"
								id="tab-toggle-general"
								data-testid="accountSettingsTab"
							>
								{t("Account")}
							</a>
						</li>

						{!isLabelMaker(account_type_id) && (
							<li>
								<a
									onClick={() => setTab("#tab-addresses")}
									className={`tab ${tab === "#tab-addresses" && "active show"}`}
									href="#tab-addresses"
									data-toggle="tab"
									role="tab"
									aria-controls="tab-toggle-general"
									aria-selected="true"
									id="tab-toggle-general"
									data-testid="addressesSettingsTab"
								>
									{t("Addresses")}
								</a>
							</li>
						)}
						<li>
							<a
								onClick={() => setTab("#tab-language")}
								className={`tab ${tab === "#tab-language" && "active show"}`}
								href="#tab-language"
								data-toggle="tab"
								role="tab"
								aria-controls="tab-toggle-language"
								aria-selected="true"
								id="tab-toggle-language"
								data-testid="languageSettingsTab"
							>
								{t("Language")}
							</a>
						</li>
						{isBrand(account_type_id) &&
							brandDppAppLabelTemplates(labelTemplates).length > 0 && (
								<li>
									<a
										onClick={() => setTab("#tab-dpp-customization")}
										className={`tab ${
											tab === "#tab-dpp-customization" && "active show"
										}`}
										href="#tab-dpp-customization"
										data-toggle="tab"
										role="tab"
										aria-controls="tab-toggle-general"
										aria-selected="true"
										id="tab-toggle-general"
										data-testid="dppCustomizationSettingsTab"
									>
										{t("Dpp Customization")}
									</a>
								</li>
							)}
					</ul>

					<div className="tab-content mt--base">
						{tab === "#tab-account" && <SettingsAccount />}
						{!isLabelMaker(account_type_id) && tab === "#tab-addresses" && (
							<SettingsAddresses />
						)}
						{tab === "#tab-language" && <SettingsLanguage />}
						{isBrand(account_type_id) &&
							brandDppAppLabelTemplates(labelTemplates).length > 0 &&
							tab === "#tab-dpp-customization" && <DppCustomization />}
					</div>
				</div>
			</div>
		</>
	);
};

export default Settings;
