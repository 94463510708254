import React from "react";

interface IProps {
	show: boolean;
	text?: string;
	testId: string;
}

const Popup: React.FunctionComponent<IProps> = ({ show, text, testId }) => {
	if (!show) {
		return null;
	}

	return (
		<span data-testid={`popup-${testId}`} className="popuptext">
			{text}
		</span>
	);
};

export default Popup;
