import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface IProps {
	selectedPrintBatchesForBulkAction: any;
	setSelectedPrintBatchesForBulkAction: any;
	forceRefreshPrintBatches: () => void;
}

const PrintBatchesHeaderActionsToolbar = ({
	selectedPrintBatchesForBulkAction,
	setSelectedPrintBatchesForBulkAction,
	forceRefreshPrintBatches,
}: IProps) => {
	const { t } = useTranslation(); // Destructure t from useTranslation

	const action = () => {};

	return (
		<div className="toolbar">
			{/* Refresh action */}
			<div
				className="orders-action mr--md left"
				onClick={() => forceRefreshPrintBatches()}
			>
				<div className="pill pill--outline-quiet pill--toolbar">
					<div className="toolbarIconContainer">
						<Icon name="refresh" className="toolbarIcon" />
					</div>
				</div>
			</div>
			{/* Actions available only when some orders are selected */}
			{Object.keys(selectedPrintBatchesForBulkAction).length > 1 && (
				<>
					<div className="left">
						<div className="flex-center-both-axis" style={{ width: "162px" }}>
							<Loading
								show={false}
								text={t("Loading...")}
								imgClass="orders-action-loading"
								divClass=""
							/>
						</div>
						<div
							className="orders-action mr--md left"
							title={t("Download PDF")}
							onClick={() => action()}
						>
							<div className="pill pill--outline-quiet pill--toolbar">
								<div className="toolbarIconContainer">
									<div className="toolbarText">{t("Bulk action")}</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default PrintBatchesHeaderActionsToolbar;
