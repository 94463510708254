import { FC } from "react";

import missingTranslations from "./missing-translations.png";
import factoryAccExec from "./factory-acc-exec.png";
import choosingLanguages from "./choosing-languages.png";
import shippingOldWay from "./shipping-old-way.png";
import shippingNewWay from "./shipping-new-way.png";

const ReleaseDec23: FC = () => {
	return (
		<>
			<article className="helpArticle">
				<h4>Choosing languages for the layout</h4>
				<img
					src={choosingLanguages}
					className="helpImageXSmall"
					alt="Order actions"
				/>
				<p>
					You can now choose which languages you want to see on your label. This
					is a feature that is not enabled by default, and was meant to be used
					for layouts that can be marketed to different markets, so languages
					need to be adjusted accordingly <b>each time</b>.
				</p>
				<hr />

				<h4>Missing languages shown with full name</h4>
				<img
					src={missingTranslations}
					className="helpImageXSmall"
					alt="Order actions"
				/>
				<p>
					If you run into a missing translation, you will now see the full name
					of the language for which the translations are missing, instead of
					just the language code.
				</p>
				<hr />

				<h4>Account executives for factories</h4>
				<img
					src={factoryAccExec}
					className="helpImageSmall"
					alt="Order actions"
				/>
				<p>
					You can now assign account executives to specific brands. This
					enhancement streamlines the order navigation process, providing a
					significant benefit to account executives. They can now efficiently
					filter orders based on their name and the brand they manage, leading
					to a substantial time-saving in their workflow.
				</p>
				<hr />

				<h4>Placing wizzard</h4>
				<p>
					Shipping and billing instructions used to cause a lot of confusion, so
					we decided to simplify the process.
				</p>
				<img
					src={shippingOldWay}
					className="helpImageXXSmall"
					alt="Order actions"
				/>
				<p>
					It's something that needs to be entered only when the order is placed,
					so it is now integrated in the confirmation window when placing the
					order - just click on the <b>Place</b> button to get the process
					started:
				</p>
				<img
					src={shippingNewWay}
					className="helpImageXSmall"
					alt="Order actions"
				/>

				<hr />

				<p>
					If you have any questions, feel free to contact us at{" "}
					<a href="mailto:support@cacotec.com">support@cacotec.com</a>.
				</p>
			</article>
		</>
	);
};

export default ReleaseDec23;
