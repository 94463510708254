import { FC, useContext } from "react";
import OrderDispatch from "Dispatches/OrderDispatch";
import { useTranslation } from "react-i18next";

interface IProps {
	sharePill?: boolean;

	onPlaceOrder: () => void;
}

const OrderPlaceButton: FC<IProps> = ({ sharePill, onPlaceOrder }) => {
	const {
		order: { orderItems },
	} = useContext(OrderDispatch);

	const { t } = useTranslation();

	let isEnabled = orderItems.length > 0;

	return (
		<div data-tooltip-id="placeOrder" className="ml--lg">
			<button
				className={` button ${
					isEnabled ? "button--primary" : "button--disabled"
				}
        ${sharePill && "button-custom-place-order"}
          
          `}
				type="button"
				form="orderItemForm"
				onClick={onPlaceOrder}
				data-testid="orderHeader-placeOrder"
			>
				{t("Place")}
			</button>
		</div>
	);
};

export default OrderPlaceButton;
