import React, { useEffect } from "react";

interface IProps {
	name: string;
	labelText: string;
	currentAddress: any;
	invalidAddress: any;
	maxLengthText: number;

	setCurrentAddress: (currentAddress: any) => void;
	setInvalidAddress: (invalidAddress: any) => void;
}

const AddressInput: React.FunctionComponent<IProps> = ({
	name,
	labelText,
	currentAddress,
	setCurrentAddress,
	invalidAddress,
	setInvalidAddress,
	maxLengthText,
}) => {
	const value = currentAddress[name];

	useEffect(() => {
		if (value) {
			const invalidAddressCopy = { ...invalidAddress };
			delete invalidAddressCopy[name];
			setInvalidAddress(invalidAddressCopy);
		}
	}, [value]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			className={`form-group ${invalidAddress?.[name] && "has-danger"}`}
			style={{ marginBottom: "0px" }}
		>
			<input
				type="text"
				className="form-control"
				name={name}
				id={name}
				value={value ? value : ""}
				data-empty={!value}
				maxLength={maxLengthText}
				onChange={(e) =>
					setCurrentAddress({ ...currentAddress, [name]: e.target.value })
				}
				onBlur={(e) =>
					setCurrentAddress({
						...currentAddress,
						[name]: e.target.value.trim(),
					})
				}
			/>
			<label
				className="form-control-label form-control-address-label"
				htmlFor={name}
			>
				{labelText}
			</label>
			<div className="text--danger message--invalid">
				{invalidAddress?.[name] && `Please enter ${labelText}`}
			</div>
		</div>
	);
};

export default AddressInput;
