import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import Loading from "../Shared/Loading";
import {
	languagesCodeFullname,
	dataTypesForTranslations,
} from "Models/GlobalModels";

import AdminTranslationsModal from "./AdminTranslationsModal";

const AdminTranslations: React.FunctionComponent = () => {
	const useGetTranslations = useDataApi();

	const [dataType, setDataType] = useState("madeIn");
	const [modalParams, setModalParams] = useState({} as any);
	const [dataNameForSearch, setDataNameForSearch] = useState("");
	const [foundTranslations, setFoundTranslations] = useState([] as any);

	const [showAdminTranslationsModal, setShowAdminTranslationsModal] =
		useState(false);

	/* *************************************************************************** */

	useEffect(() => {
		const { error } = useGetTranslations;
		if (error) {
			toast.error(`Unable to get translations. ${error}`);
		}
	}, [useGetTranslations.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetTranslations;

		if (data.status === "ok") {
			const foundTranslationslocal = data.message;

			const foundTranslationsGrouped = foundTranslationslocal.reduce(
				(acc: any, item: any) => {
					const {
						id,
						data_type_id,
						language_code,
						translation,
						source,
						added_by,
						name,
					} = item;

					if (!acc[data_type_id]) {
						acc[data_type_id] = [];
					}

					acc[data_type_id].push({
						id,
						data_type_id,
						language_code,
						translation,
						source,
						added_by,
					});

					if (!acc[data_type_id][0].name) {
						acc[data_type_id][0].name = name;
					}

					return acc;
				},
				{}
			);

			setFoundTranslations(foundTranslationsGrouped);
		}
	}, [useGetTranslations.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */

	const onSearchData = () => {
		useGetTranslations.doFetch(
			`/maker/admin/translations/${dataType}/${dataNameForSearch}`
		);
	};

	const onClickAddEditTranslations = (modalParams: any) => {
		setModalParams(modalParams);

		setShowAdminTranslationsModal(true);
	};

	return (
		<div
			className="tab-pane fade in active show settingsTab"
			role="tabpanel"
			id="tab-general"
			aria-labelledby="tab-toggle-general"
		>
			<fieldset
				className="box box--light mb--md form-group"
				style={{ padding: "40px 60px" }}
			>
				<h3 className="drawer__title">Choose Data Type</h3>
				<div className="form-text">
					Please select which type of data you want to work with:
				</div>
				<select
					value={dataType}
					onChange={(e) => {
						setDataType(e.target.value);
					}}
					className="form-control"
				>
					{Object.keys(dataTypesForTranslations).map((key: any) => {
						return (
							<option style={{ padding: "5px" }} key={key} value={key}>
								{dataTypesForTranslations[key]}
							</option>
						);
					})}
				</select>
			</fieldset>
			<fieldset
				className="box box--light mb--md form-group"
				style={{ padding: "40px 60px" }}
			>
				<h3 className="drawer__title">
					Search Translations For {dataTypesForTranslations[dataType]}
				</h3>
				<div className="form-text">
					Please enter the name of part of the name:
				</div>
				<input
					type="text"
					value={dataNameForSearch}
					onChange={(e) => setDataNameForSearch(e.target.value)}
					className="form-control"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onSearchData();
						}
					}}
				/>
				<div
					className="form-actions mt--md"
					style={{ justifyContent: "flex-end" }}
				>
					<div>
						{!useGetTranslations.isLoading && (
							<button className="button button--primary" onClick={onSearchData}>
								Search
							</button>
						)}
						<Loading
							show={useGetTranslations.isLoading}
							text={"Loading..."}
							imgClass="saveOrderLoading"
							divClass=""
						/>
					</div>
				</div>
			</fieldset>

			<fieldset className="pretitle flex-center-primary-axis">
				{foundTranslations &&
					!useGetTranslations.isLoading &&
					Object.keys(foundTranslations)?.map((data_type_id: any) => {
						const dataName = foundTranslations[data_type_id][0].name;
						let translationsDiv = foundTranslations[data_type_id]?.map(
							(item: any, index: number) => {
								const { id, language_code, translation, source, added_by } =
									item;

								return (
									<div
										key={`${language_code}-${index}`}
										className="flex between-xs mt--xs"
									>
										<div className="col-xs-2">
											{languagesCodeFullname[language_code]}
										</div>
										<div className="col-xs-9">
											{translation} ({source ? source : "initial data"} -{" "}
											{added_by ? added_by : "admin"})
										</div>
										<div className="col-xs-2">
											<div
												className="button button--primary button--xs ml--sm"
												onClick={() => {
													onClickAddEditTranslations({
														currentDataId: id,
														currentLanguageCode: language_code,
														currentTranslation: translation,
														dataName,
													});
												}}
											>
												Edit
											</div>
										</div>
									</div>
								);
							}
						);

						return (
							<div
								className="box box--light mb--md form-group"
								key={data_type_id}
							>
								<div className="flex mt--xs mb--base">
									<h3 className="drawer__title">
										{foundTranslations[data_type_id][0].name}
									</h3>
									<div
										className="button button--primary button--xs ml--sm"
										onClick={() => {
											onClickAddEditTranslations({
												dataName: foundTranslations[data_type_id][0].name,
											});
										}}
									>
										&nbsp;&nbsp;+ Add translations&nbsp;&nbsp;
									</div>
								</div>
								<div>{translationsDiv}</div>
							</div>
						);
					})}

				{useGetTranslations.isLoading && (
					<Loading
						show={useGetTranslations.isLoading}
						text={"Loading..."}
						imgClass="saveOrderLoading"
						divClass=""
					/>
				)}
			</fieldset>
			{showAdminTranslationsModal && (
				<AdminTranslationsModal
					currentDataType={dataType}
					editParams={modalParams}
					setShowAdminTranslationsModal={setShowAdminTranslationsModal}
					onSearchData={onSearchData}
				/>
			)}
		</div>
	);
};

export default AdminTranslations;
