import React, { useCallback, useEffect, useRef, useState } from "react";

import TemplatesListItem from "Components/Templates/TemplatesListItem";
import Loading from "../Shared/Loading";

interface IProps {
	templates: any;
	templatesListHeight?: number;
	templatesListHeaderHeight?: number;
	isLoadingTemplates?: boolean;
	offset?: number;
	limitTemplatesPerPage?: number;
	currentData?: any[];

	setOffset?: (offset: number) => void;
	refreshTemplates: (
		forceRefresh?: boolean,
		resetTemplatesList?: boolean,
		forceOffset?: number
	) => void;
}

const TemplatesList: React.FunctionComponent<IProps> = ({
	templates,
	templatesListHeight,
	templatesListHeaderHeight,
	isLoadingTemplates = false,
	offset = 0,
	limitTemplatesPerPage = 10,
	currentData = [],
	setOffset = () => {},
	refreshTemplates = () => {},
}) => {
	const TABLE_HEADER_MARGIN_BOTTOM = 20;

	// Calculate heading right padding depending on the thickness of the scroll bar  === START
	const templatesListRef = useRef(null) as any;

	const [listWidth, setListWidth] = useState<any>(0);

	const [scrollPadding, setScrollPadding] = useState(0);

	useEffect(() => {
		let scrollPadding = templatesListRef.current?.offsetWidth - 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
		// Return empty cleanup function apparently solved: " Warning: Can't perform a React state update on an unmounted component. ....."
		return () => {};
	}, [templates, listWidth]);

	// Calculate heading right padding depending on the thickness of the scroll bar  === END

	// Infinite scroll === START
	const observer = useRef<IntersectionObserver>();

	// we use useCallback so that we can detect when the ref element (node) is changed
	const lastStyleElementRef = useCallback(
		(node: Element) => {
			if (isLoadingTemplates) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					templates.length > offset &&
					currentData &&
					currentData.length === limitTemplatesPerPage
				) {
					// step_scroll_down
					setOffset(templates.length);
				}
			});

			if (node) observer.current.observe(node);
		},
		[
			isLoadingTemplates,
			setOffset,
			templates.length,
			offset,
			currentData,
			limitTemplatesPerPage,
		]
	);

	// Infinite scroll === END

	return (
		<div style={{ height: templatesListHeight ? templatesListHeight : "100%" }}>
			<section
				className="listing-header listing-header--templates"
				style={{
					height: templatesListHeaderHeight
						? templatesListHeaderHeight
						: "auto",
					paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
				}}
			>
				<span className="listing-header__label">Name</span>
				<span className="listing-header__label">Last Changed</span>
				<span className="listing-header__label">Last Used In Order</span>
				<span className="listing-header__label">Status</span>
				<span className="listing-header__label ordersStatusListItem">
					Actions
				</span>
			</section>

			<section
				ref={templatesListRef}
				style={{
					height:
						templatesListHeight && templatesListHeaderHeight
							? templatesListHeight -
							  templatesListHeaderHeight -
							  TABLE_HEADER_MARGIN_BOTTOM
							: "90%",
					overflowY: "auto",
				}}
			>
				<>
					{templates.map((template: any, index: number) => {
						return (
							<TemplatesListItem
								key={`${template.name}-${index}`}
								template={template}
								setListWidth={setListWidth}
								refVar={
									templates.length === index + 1 ? lastStyleElementRef : null
								}
								refreshTemplates={refreshTemplates}
								setOffset={setOffset}
							/>
						);
					})}
					{isLoadingTemplates && (
						<div>
							<Loading
								show={isLoadingTemplates}
								text={`Loading...`}
								imgClass=""
								divClass="center-lg"
								imgStyle={{ margin: "-15px 0" }}
							/>
						</div>
					)}
				</>
			</section>
		</div>
	);
};

export default TemplatesList;
