import React from "react";

import ordersPage from "./images/orders-page.png";
import bulkActions from "./images/bulk-actions.png";
import usePageTitle from "Hooks/pageTitleHook";

const OrdersPageHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Orders`);

	return (
		<>
			<h2 className="helpTitle">Orders</h2>
			<article className="helpArticle">
				<p>
					Orders Page shows a list of all orders with the following information:
				</p>
				<ul>
					<li>Reference number and Note (if present)</li>
					<li>Last Edit information - who did the last edit and when</li>
					<li>
						Ship to - name of the company that you chose to ship this order to
					</li>
					<li>
						Status - current status of the order; if the order is shipped, you
						will see a small truck icon next to the status - hovering it will
						bring up additional information about the shipping status
					</li>
				</ul>

				<p>
					<b>Note: </b>Refresh icon can be used to refresh the list of orders.
				</p>
				<img src={ordersPage} className="helpImage" alt="Orders page" />
				<p>
					<b>Note: </b>Orders added via API show up as Draft in the list.
				</p>
				<p>
					The status of the order changes depending on which step you are in:
					<i>
						Open, Review Requested, Change Requested, Approved, Placed,
						Canceled, In Production, Partially Shipped, Shipped and Completed.
					</i>
				</p>

				<p className="helpSubtitle">Searching and filtering</p>
				<p>You can search and filter the orders list.</p>
				<p>
					In the top right corner, you have an input field where you can search
					for a style number or reference number. The list of orders will
					refresh as you type.
				</p>
				<p>
					Underneath it you have filters - you can filter all orders by status.
				</p>
				<p>
					<b>Note: </b>Searching/filtering removes any selection made for bulk
					actions.
				</p>

				<p className="helpSubtitle">Bulk actions</p>
				<p>
					You can perform bulk actions on orders listed in the orders list.
					Those actions are
					<b> Download PDF</b> and <b>Change Status</b>.
				</p>
				<img src={bulkActions} className="helpImageLarge" alt="Bulk actions" />
				<p>
					<b>Note: </b>
				</p>
				<ul>
					<li>Bulk actions can not be done on orders which are placed</li>
				</ul>

				<p>
					<b>Download PDF: </b>Once the action is triggered, the download
					process will start. You can save and review the downloaded PDF.
				</p>
				<p>
					<b>Change status: </b>use this action to perform status change on
					multiple orders at the same time. This action can be done only on
					orders that are meant for the same factory and which have both billing
					and shipping address information entered.
				</p>
			</article>
		</>
	);
};

export default OrdersPageHelp;
