import React from "react";

import qrLabelExample from "./images/qr-label-example.png";
import usePageTitle from "Hooks/pageTitleHook";

const LabelSettingsHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Label Settings`);

	return (
		<>
			<h2 className="helpTitle">Label Settings</h2>
			<article className="helpArticle">
				<li className="list-inline mt--lg mb--base">
					<strong>Include QR code</strong>
				</li>
				<p>
					If you choose to add a QR code to your label, it will be added to the
					label along side your current content:
				</p>
				<img
					src={qrLabelExample}
					className="helpImageSmall"
					alt="Label with a QR code example"
				/>
				<p>
					Customers that scan it will be directed to the a webpage where they
					will be able to see the label in a digital form.
				</p>
			</article>
		</>
	);
};

export default LabelSettingsHelp;
