import React, { useContext, useRef, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import OrderDispatch from "Dispatches/OrderDispatch";
import {
	IOrderState,
	DEFAULT_STYLE_NAME,
	OrderStatusesFlow,
} from "Models/OrderModels";
import Icon from "Components/Shared/Icon";
import HelpTooltip from "Components/Shared/HelpTooltip";
import OrderItemsPreviewButtons from "./components/OrderItemsPreviewButtons";
import OrderNote from "./components/OrderNote";
import ReferenceNumber from "./components/ReferenceNumber";
import { ORDER_ITEMS_PREVIEW_TEXT_LENGTH } from "Models/OrderModels";
import { isEllipsisActive } from "Utils/utils";
import { isBrand } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import ChooseFactory from "./components/ChooseFactory";

interface IProps {
	order: IOrderState;
	currentOrderItemIndex: number;
	showNoteModal: boolean;

	setOrder: (order: any) => void;
	setShowAddExistingStyleModal: (modalState: boolean) => void;
	setCurrentOrderItemIndex: (currentOrderItemIndex: number) => void;
	setShowNoteModal: (showNoteModal: boolean) => void;
	setLocalNote: (localNote: string | undefined) => void;
}

const OrderItemsPreview: React.FunctionComponent<IProps> = ({
	order,
	currentOrderItemIndex,
	showNoteModal,

	setOrder,
	setShowAddExistingStyleModal,
	setCurrentOrderItemIndex,
	setShowNoteModal,
	setLocalNote,
}) => {
	const { user } = useContext(GlobalDispatch);

	const { orderItem, setOrderItem, itemsWithMissingInfo } =
		useContext(OrderDispatch);

	const { t } = useTranslation();

	const { lotNumber, status, extraInfo, brandId } = order;

	const changeRequestedNoteRef = useRef<HTMLDivElement>(null);

	let changeRequestedNote;

	if (
		status === OrderStatusesFlow.CHANGE_REQUESTED.code &&
		extraInfo &&
		"change_requested_note" in extraInfo
	) {
		changeRequestedNote = extraInfo.change_requested_note as string;
	}

	const lotNumberRef = useRef(null) as any;
	const orderStylesListRef = useRef(null) as any;

	useEffect(() => {
		if (lotNumberRef.current && !lotNumber) {
			lotNumberRef.current.focus();
		}
	}, [lotNumberRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************************** */

	const onEditOrderItem = (index: number, styleNumber: string) => {
		const chosenOrderItem = order.orderItems[index];
		setCurrentOrderItemIndex(index);
		setOrderItem(chosenOrderItem);
	};

	const onDeleteOrderItem = (index: number) => {
		if (window.confirm(t("Are you sure you want to delete this order item?"))) {
			const selectedOrderItem = order.orderItems[index];
			if (selectedOrderItem) {
				const items = order.orderItems.filter((item, i) => i !== index);
				setOrder({ ...order, orderItems: items });
				setOrderItem(items[0]);
				setCurrentOrderItemIndex(0);
			}
		}
	};

	const addedStyleNumbers =
		order && order.orderItems
			? order.orderItems.map((item: any) => item.styleNumber)
			: [];

	return (
		<div className="orderItemsPreview">
			<div ref={orderStylesListRef}>
				<div className="pretitle flex-center-secondary-axis">
					<span style={{ lineHeight: 1 }}>{t("Order Details")}</span>
					<HelpTooltip helpFor="OrderDetails" />
				</div>
				<fieldset
					className="box box--light box--shadowed mb--md"
					style={{ padding: "20px" }}
				>
					{order?.labelTemplate?.name && (
						<div className="orderInfoInput" style={{ border: "none" }}>
							<div
								className="upcase text--quiet"
								style={{
									fontSize: "10px",
									letterSpacing: 0,
									paddingTop: "2px",
								}}
							>
								{t("Label Layout")}
							</div>
							<small id="flexElement">
								<div className="orderInfoValue">{order.labelTemplate.name}</div>
							</small>
						</div>
					)}
					<ReferenceNumber
						order={order}
						lotNumberRef={lotNumberRef}
						lotNumber={lotNumber}
						maxLengthReferenceNumber={
							ORDER_ITEMS_PREVIEW_TEXT_LENGTH.REFERENCE_NUMBER
						}
						setOrder={setOrder}
					/>
					{isBrand(user.account_type_id) &&
						brandId &&
						(brandId === 18 || brandId === 1) && (
							<ChooseFactory order={order} setOrder={setOrder} />
						)}
					<OrderNote
						order={order}
						showNoteModal={showNoteModal}
						setShowNoteModal={setShowNoteModal}
						setLocalNote={setLocalNote}
					/>
					{status === OrderStatusesFlow.CHANGE_REQUESTED.code &&
						changeRequestedNote && (
							<>
								<div className="orderInfoInput" style={{ border: "none" }}>
									<div
										className="upcase text--quiet"
										style={{
											fontSize: "10px",
											letterSpacing: 0,
											paddingTop: "2px",
										}}
									>
										{t("Requested change")}
									</div>
									<small
										id="flexElement"
										data-tooltip-id={`changeRequestedNote`}
									>
										<div
											className="orderInfoValue truncate width-100"
											ref={changeRequestedNoteRef}
										>
											{changeRequestedNote}
										</div>
									</small>
								</div>
								{isEllipsisActive(changeRequestedNoteRef) && (
									<Tooltip
										className="reactTooltip whiteBackground"
										variant="light"
										id={`changeRequestedNote`}
										float
										style={{ width: "90%" }}
									>
										<div style={{ wordBreak: "break-word" }}>
											{changeRequestedNote}
										</div>
									</Tooltip>
								)}
							</>
						)}
				</fieldset>

				<div className="pretitle flex-center-secondary-axis">
					<span style={{ lineHeight: 1 }}>{t("Styles in This Order")}</span>
					<HelpTooltip helpFor="StylesInThisOrder" />
				</div>

				<OrderItemsPreviewButtons
					forPlaceholder={false}
					setShowAddExistingStyleModal={setShowAddExistingStyleModal}
					setCurrentOrderItemIndex={setCurrentOrderItemIndex}
				/>
			</div>

			{addedStyleNumbers.length > 0 && (
				<div className="orderItemsList">
					{addedStyleNumbers.map((styleNumber: string, index: number) => {
						return (
							<div
								className="mb-xs"
								style={{ display: "flex", marginTop: "10px", width: "230px" }}
								key={`${index}-${styleNumber}`}
							>
								<figure
									className={`uploaded-images__figure orderItemHover ${
										orderItem.styleNumber === styleNumber &&
										currentOrderItemIndex === index &&
										"currentOrderItem"
									}`}
									id="orderItemsPreviewListItem"
								>
									<div
										className={`orderItem ${
											itemsWithMissingInfo &&
											itemsWithMissingInfo[index] &&
											"link--danger"
										}`}
										onClick={() => onEditOrderItem(index, styleNumber)}
										role="button"
										data-tooltip-id={`error${index}`}
									>
										<Icon
											name={
												itemsWithMissingInfo && itemsWithMissingInfo[index]
													? "exclamation-mark-rounded"
													: "order-item"
											}
											className="icon valign-middle mr--xs"
										/>
										<span
											className="strong"
											style={{ width: "160px", overflowX: "auto" }}
										>
											{styleNumber ? styleNumber : t(DEFAULT_STYLE_NAME)}
										</span>
									</div>
									{itemsWithMissingInfo && itemsWithMissingInfo[index] && (
										<Tooltip
											className="reactTooltip errorTooltip"
											variant="light"
											id={`error${index}`}
											float
											style={{ width: "100%" }}
										>
											{t("Missing information")}:{" "}
											{itemsWithMissingInfo[index].errorInfo.join(", ")}
										</Tooltip>
									)}

									<div
										className="uploaded-images__remove pos-relative"
										style={{ marginRight: "10px" }}
										onClick={() => onDeleteOrderItem(index)}
										role="button"
									>
										<Icon name="cross-rounded-filled" />
									</div>
								</figure>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default OrderItemsPreview;
