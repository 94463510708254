import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import OrderDispatch from "Dispatches/OrderDispatch";
import { ISelectedSymbols, symbolsGroupArray } from "Models/OrderModels";
import { EndpointPrefix, isFactory } from "Models/UserModels";
import { appEnvSymbolsStorageName } from "Models/GlobalModels";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import { deepCopy } from "Utils/utils";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import useDataApi from "Hooks/fetchHook";
import LoadingBar from "Components/Shared/LoadingBar";
import CareInstructionsSymbolsEdit from "./Symbols/CareInstructionsSymbolsEdit";
import HelpTooltip from "Components/Shared/HelpTooltip";
import sprite from "images/icons.svg";
import Icon from "Components/Shared/Icon";
import Accordion from "Components/Shared/Accordion";

interface IProps {}

const Symbols: React.FunctionComponent<IProps> = () => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);
	const { t } = useTranslation();
	const { orderItem, setOrderItem, order } = useContext(OrderDispatch);

	const [showCareInstructionsSymbolsEdit, setShowCareInstructionsSymbolsEdit] =
		useState(false);

	const [symbolSuggestions, setSymbolSuggestions] = useState<any>(undefined);

	const [filteredSymbolSuggestions, setFilteredSymbolSuggestions] = useState<{
		[key: string]: number[][];
	}>({});

	const useGetSymbolSuggestions = useDataApi();

	const { labelTemplate } = order;
	const { symbol_set } = labelTemplate?.settings?.default_values;

	// TODO delete this when database is updated
	let symbolSetArray: string[] =
		typeof symbol_set === "string" ? [symbol_set] : symbol_set;

	const {
		initialValues: { careInstructions, allSymbolsById },
	} = useFetchInitialValues(order, order.brandId);

	const initialSelectedSymbols: ISelectedSymbols = {
		washing: [],
		bleaching: [],
		drying: [],
		ironing: [],
		"professional textile care": [],
	};

	const [selectedSymbols, setSelectedSymbols] = useState<ISelectedSymbols>(
		initialSelectedSymbols
	);

	const [symbolSet, setSymbolSet] = useState(symbolSetArray[0]);

	const [showAllSuggestions, setShowAllSuggestions] = useState(false);

	const { selectedCareInstructions, extraInfo } = orderItem;

	// for use in CareInstructionsSymbolsEdit modal
	const getSelectedSymbolsFromCareInstructionsInObjectGroupOrder = () => {
		let selectedSymbolsCopy = deepCopy(initialSelectedSymbols);
		selectedCareInstructions.map((selectedCareInstruction: any) => {
			// find care instruction by id, because we only have id in selectedCareInstructions
			let careInstruction = careInstructions.find((careInstruction: any) => {
				return selectedCareInstruction === careInstruction.id;
			});

			// check if care instruction has symbols
			if (Object.keys(careInstruction.symbols).length > 0) {
				// Handle multiple/single symbols for one care instruction
				return careInstruction?.symbols[symbolSet]?.map((symbol: any) => {
					selectedSymbolsCopy[symbol.symbol_type].push(symbol);
				});
			}
		});

		return selectedSymbolsCopy;
	};

	// Reset custom selected symbols to care instructions symbols
	const onResetSymbols = () => {
		let newOrderItem = deepCopy(orderItem);
		delete newOrderItem?.extraInfo?.careInstructionsSymbols?.[symbolSet];
		setOrderItem(newOrderItem);
	};

	const onSelectSuggestedSymbols = (suggestionsArray: number[]) => {
		let prevExtraInfo = deepCopy(orderItem.extraInfo);

		let newCareInstructionsSymbols = {
			...prevExtraInfo?.careInstructionsSymbols,
			[symbolSet]: suggestionsArray,
		};

		let newExtraInfo = {
			...prevExtraInfo,
			careInstructionsSymbols: newCareInstructionsSymbols,
		};

		setOrderItem({
			...orderItem,
			extraInfo: newExtraInfo,
		});
	};

	let customSelectedSymbolsFromExtraInfo =
		extraInfo?.careInstructionsSymbols &&
		extraInfo?.careInstructionsSymbols?.[symbolSet];

	const transformSymbolsRandomArrayToObjectGroupOrder = (
		symbolsRandomArray: number[]
	) => {
		const selectedSymbolsObject: ISelectedSymbols = deepCopy(
			initialSelectedSymbols
		);
		symbolsRandomArray.forEach((symbolId: number) => {
			const newSymbol = allSymbolsById[symbolSet][symbolId];
			if (newSymbol) {
				const newSymbolType: keyof ISelectedSymbols = newSymbol.symbol_type;
				selectedSymbolsObject[newSymbolType].push(newSymbol);
			}
		});
		return selectedSymbolsObject;
	};

	const getSymbolIdsArrayForOneSymbolSet = (
		selectedSymbols: ISelectedSymbols
	) => {
		return symbolsGroupArray
			.map((groupName: keyof ISelectedSymbols) => {
				return selectedSymbols[groupName].map(
					// Extract the symbol_id from each symbol object
					(symbol: any) => symbol.symbol_id
				);
			})
			.flat();
	};

	useEffect(() => {
		if (customSelectedSymbolsFromExtraInfo) {
			setSelectedSymbols(
				transformSymbolsRandomArrayToObjectGroupOrder(
					customSelectedSymbolsFromExtraInfo
				)
			);
		} else {
			setSelectedSymbols(
				getSelectedSymbolsFromCareInstructionsInObjectGroupOrder()
			);
		}
	}, [selectedCareInstructions, orderItem.extraInfo, symbolSet]);

	/* *********************************** GET SYMBOL SUGGESTIONS START **************************************** */

	useEffect(() => {
		if (selectedCareInstructions.length > 0) {
			const brandIdForFactory = isFactory(account_type_id)
				? `?brandId=${order.brandId}`
				: "";

			useGetSymbolSuggestions.doFetch(
				`/${EndpointPrefix[account_type_id]}/data/symbolsByCareInstructions${brandIdForFactory}`,
				{
					careInstructionIds: selectedCareInstructions,
					labelTemplateId: order.labelTemplate.id,
				},
				"POST"
			);
		} else if (selectedCareInstructions.length === 0) {
			setSymbolSuggestions(undefined);
		}
	}, [selectedCareInstructions.toString()]);

	useEffect(() => {
		const { error } = useGetSymbolSuggestions;
		if (error) {
			toast.error(`Unable to get symbol suggestions. ${error}`);
		}
	}, [useGetSymbolSuggestions.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetSymbolSuggestions;
		if (data.status === "ok") {
			setSymbolSuggestions(data.message.suggested_symbols);
		}
	}, [useGetSymbolSuggestions.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** GET SYMBOL SUGGESTIONS END **************************************** */

	/* *********************************** FILTER SYMBOL SUGGESTIONS START **************************************** */

	useEffect(() => {
		type CountryKeys = (typeof symbolSetArray)[number];
		let filteredSymbolSuggestions: {
			[key in CountryKeys]: number[][];
		} = {};

		symbolSetArray.forEach((symbolSet: string) => {
			filteredSymbolSuggestions[symbolSet] =
				symbolSuggestions &&
				symbolSuggestions[symbolSet as keyof typeof symbolSuggestions]?.filter(
					(suggestionArray: number[]) => {
						return (
							suggestionArray.toString() !==
								extraInfo?.careInstructionsSymbols?.[symbolSet]?.toString() &&
							suggestionArray.toString() !==
								getSymbolsIdsArrayFromCareInstructions(symbolSet).toString()
						);
					}
				);

			if (Object.keys(filteredSymbolSuggestions).length > 0) {
				setFilteredSymbolSuggestions(filteredSymbolSuggestions);
			} else {
				setFilteredSymbolSuggestions({});
			}
		});
	}, [
		symbolSuggestions,
		selectedCareInstructions.toString(),
		extraInfo?.careInstructionsSymbols,
	]);

	/* *********************************** FILTER SYMBOL SUGGESTIONS END **************************************** */

	const getSymbolsIdsArrayFromCareInstructions = (symbolSet: string) => {
		let careInstructionsSymbolsIds: number[] = [];

		selectedCareInstructions?.map((careInstructionId: number) => {
			// find care instruction by id, because we only have id in selectedCareInstructions
			let careInstruction = careInstructions?.find((careInstruction: any) => {
				return careInstructionId === careInstruction.id;
			});
			// check if care instruction has symbols
			if (Object.keys(careInstruction?.symbols).length > 0) {
				// Handle multiple/single symbols for one care instruction
				return careInstruction?.symbols[symbolSet]?.map((symbol: any) => {
					careInstructionsSymbolsIds.push(symbol.symbol_id);
				});
			}
		});
		return careInstructionsSymbolsIds;
	};

	let currentSymbolsStorageName =
		appEnvSymbolsStorageName[
			import.meta.env.VITE_APP_ENV as keyof typeof appEnvSymbolsStorageName
		];

	let currentCareInstructionsSymbolsIds: any[] =
		getSymbolsIdsArrayFromCareInstructions(symbolSet);

	const symbolScale = 0.8;

	return (
		<>
			<div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>{t("Symbols")}</span>
				<HelpTooltip helpFor="Symbols" />
			</div>
			<div className="mb--md">
				<fieldset className="box box--light box--shadowed">
					<>
						{/* Current symbols and set buttons */}
						<>
							<div className="flex-center-secondary-axis">
								<div className="symbol-label">{t("Current symbols")}</div>
								<div className="tooltip-container ml--xs">
									<HelpTooltip helpFor="CurrentSymbols" scaleIcon={"0.8"} />
								</div>
							</div>
							{/* Symbol set buttons, if there is more than one symbol set */}
							{symbolSetArray.length > 1 && (
								<div className="">
									<ul
										className="tabs nav hidden--sm-down mt--xs"
										role="tablist"
										style={{ marginBottom: "5px" }}
									>
										{symbolSetArray.map((singleSymbolSet: string) => {
											return (
												<li key={singleSymbolSet}>
													<a
														onClick={() => setSymbolSet(singleSymbolSet)}
														className={`tab tab-care-instructions mr--base  ${
															symbolSet === singleSymbolSet && "active show"
														}`}
														style={{
															cursor:
																symbolSet === singleSymbolSet
																	? "default"
																	: "pointer",
															padding: "5px 6px 4.5px",
															fontSize: "10px",
														}}
														data-toggle="tab"
														role="tab"
														aria-controls="tab-toggle-general"
														aria-selected="true"
														id="tab-toggle-general"
														data-testid="accountSettingsTab"
													>
														{t(singleSymbolSet)}
													</a>
												</li>
											);
										})}
									</ul>
								</div>
							)}
							{/* Current symbols images */}
							<div className="flex justify-between flex-wrap">
								<div className="flex flex-center-primary-axis">
									{/* No current symbols */}
									{!customSelectedSymbolsFromExtraInfo &&
										currentCareInstructionsSymbolsIds?.length === 0 && (
											<div
												className="text--xs no-items-in-symbols flex-center-secondary-axis"
												style={{ height: "44.4px" }}
											>
												{t("No matching symbols.")}
											</div>
										)}
									{/* Current symbols */}
									{customSelectedSymbolsFromExtraInfo
										? // selectedSymbols
										  customSelectedSymbolsFromExtraInfo?.map(
												(symbolId: string | number) => {
													let symbol = allSymbolsById[symbolSet][symbolId];

													let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
													return (
														<div key={symbol.symbol_name}>
															<img
																src={symbolUrlStorage}
																width={32 * symbolScale}
																height={43 * symbolScale}
																style={{
																	margin: "5px",
																}}
																title={symbol.display_name}
																alt={symbol.display_name}
															/>
														</div>
													);
												}
										  )
										: // selectedCareInstructions
										  currentCareInstructionsSymbolsIds?.map(
												(symbolId: any) => {
													let symbol = allSymbolsById[symbolSet][symbolId];
													let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
													return (
														<div key={symbol.symbol_name}>
															<img
																src={symbolUrlStorage}
																width={32 * symbolScale}
																height={43 * symbolScale}
																style={{
																	margin: "5px",
																}}
																title={
																	allSymbolsById[symbolSet][symbol.symbol_id]
																		.display_name
																}
																alt={
																	allSymbolsById[symbolSet][symbol.symbol_id]
																		.display_name
																}
															/>
														</div>
													);
												}
										  )}
								</div>
								<div className="flex-center-secondary-axis ml--md">
									<div
										className={`flex-center-secondary-axis  ${
											customSelectedSymbolsFromExtraInfo
												? "symbol-reset"
												: "disable-symbol-reset"
										} mr--md`}
										onClick={onResetSymbols}
										title={
											!customSelectedSymbolsFromExtraInfo
												? t("No symbols to reset")
												: t("Reset symbols")
										}
									>
										<svg
											style={{
												height: "24px",
												width: "24px",
											}}
										>
											<use
												style={{
													fill: !customSelectedSymbolsFromExtraInfo
														? "gray"
														: "#2F80ED",
												}}
												xlinkHref={`${sprite}#icon-reset`}
											/>
										</svg>
									</div>
									<div
										className="flex-center-secondary-axis btn-no-style btn-link symbol-edit"
										onClick={() => {
											setShowCareInstructionsSymbolsEdit(true);
										}}
										title={t("Edit symbols")}
									>
										<Icon name="edit" />
									</div>
								</div>
							</div>
						</>
						{/*Cacotec AI suggestions */}
						<>
							<div className="mt--base flex-center-secondary-axis">
								<div className="symbol-label">
									{t("Suggestions based on your activity")}
								</div>
								<div className="tooltip-container ml--xs">
									<HelpTooltip helpFor="SuggestionsSymbols" scaleIcon={"0.8"} />
								</div>
							</div>

							{useGetSymbolSuggestions.isLoading ? (
								<div className="mt--base">
									<LoadingBar
										classNameLoadingBarContainer="symbols-loading-bar-container"
										classNameLoadingBar="symbols-loading-bar"
									/>
								</div>
							) : (
								<>
									{Object.keys(filteredSymbolSuggestions).length > 0 &&
									filteredSymbolSuggestions?.[symbolSet]?.length > 0 ? (
										<>
											<div className="filtered-suggested-symbols-container">
												<div
													className="flex symbol-suggestion"
													onClick={() =>
														onSelectSuggestedSymbols(
															filteredSymbolSuggestions?.[symbolSet]?.[0]
														)
													}
													title={t("Click to use")}
												>
													{filteredSymbolSuggestions?.[symbolSet]?.[0].map(
														(symbolId: number) => {
															let symbol = allSymbolsById[symbolSet][symbolId];
															let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
															return (
																<div key={symbol.symbol_name}>
																	<img
																		src={symbolUrlStorage}
																		width={32 * symbolScale}
																		height={43 * symbolScale}
																		style={{
																			margin: "5px",
																		}}
																		title={symbol.display_name}
																		alt={symbol.display_name}
																	/>
																</div>
															);
														}
													)}
												</div>
												{filteredSymbolSuggestions?.[symbolSet]?.length > 1 && (
													<>
														<Accordion isOpen={showAllSuggestions} speed={150}>
															{filteredSymbolSuggestions?.[symbolSet]
																?.slice(1)
																.map(
																	(
																		suggestionsArray: number[],
																		index: number
																	) => {
																		return (
																			<div
																				key={
																					suggestionsArray.toString() + index
																				}
																				className="flex symbol-suggestion"
																				onClick={() =>
																					onSelectSuggestedSymbols(
																						suggestionsArray
																					)
																				}
																				title={t("Click to use")}
																			>
																				{suggestionsArray.map((symbolId) => {
																					let symbol =
																						allSymbolsById[symbolSet][symbolId];
																					let symbolUrlStorage = `https://storage.googleapis.com/${currentSymbolsStorageName}/${symbolSet}/black/300/${symbol.symbol_name}`;
																					return (
																						<div key={symbol.symbol_name}>
																							<img
																								src={symbolUrlStorage}
																								width={32 * symbolScale}
																								height={43 * symbolScale}
																								style={{
																									margin: "5px",
																								}}
																								title={symbol.display_name}
																								alt={symbol.display_name}
																							/>
																						</div>
																					);
																				})}
																			</div>
																		);
																	}
																)}
														</Accordion>
														<div
															onClick={() =>
																setShowAllSuggestions((prev) => !prev)
															}
															className="btn-no-style btn-link text--sm mt--sm"
														>
															{showAllSuggestions
																? t("Show less suggestions")
																: t("Show more suggestions")}
														</div>
													</>
												)}
											</div>
										</>
									) : (
										<div className="mt--base text--xs no-items-in-symbols">
											{t(
												"We didn’t find any suggestions for entered care instructions combination."
											)}
										</div>
									)}
								</>
							)}
						</>

						{showCareInstructionsSymbolsEdit && (
							<CareInstructionsSymbolsEdit
								setShowCareInstructionsSymbolsEdit={
									setShowCareInstructionsSymbolsEdit
								}
								selectedSymbols={selectedSymbols}
								symbolSet={symbolSet}
								getSymbolIdsArrayForOneSymbolSet={
									getSymbolIdsArrayForOneSymbolSet
								}
							/>
						)}
					</>
				</fieldset>
			</div>
		</>
	);
};

export default Symbols;
