import React from "react";

import reorder from "./images/reorder.png";
import usePageTitle from "Hooks/pageTitleHook";

const ReorderingHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Reordering`);

	return (
		<>
			<h2 className="helpTitle">Reordering</h2>
			<article className="helpArticle">
				<p>
					Once you place the order you will be able to see only the preview for
					the order.
				</p>
				<p>
					In the preview screen, you will have options to reorder the whole
					order or only one part of it.
				</p>
				<img src={reorder} className="helpImageSmall" alt="Reorder" />
				<p>
					Clicking the <b>Reorder</b> button on top will reorder the whole
					order.
				</p>
				<p>
					If you want to reorder only one style from the order, you can click
					the link <b>Reorder </b>
					under the style number which you want to reorder.
				</p>
				<p>
					<b>Note:</b>
				</p>
				<ul>
					<li>
						Reordering an order does not preserve the original note, instead the
						duplicate will have a note in the form of <b>Reorder of XXXXXX</b>{" "}
						where <b>XXXXXX</b> is the reference number of the original
					</li>
					<li>
						Reordering will not preserve the reference number, it will be left
						empty instead
					</li>
				</ul>
			</article>
		</>
	);
};

export default ReorderingHelp;
