import { useEffect, useContext, FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import GlobalDispatch from "Dispatches/GlobalDispatch";

import usePageTitle from "Hooks/pageTitleHook";
import Loading from "Components/Shared/Loading";
import PrintersListItem from "Components/Printers/PrintersListItem";
import Icon from "Components/Shared/Icon";
import Drawer from "Components/Shared/Drawer";
import PrintersListInfo from "Components/Shared/PrintersListInfo";
import PrinterEditModal from "Components/Shared/PrinterEditModal";
import useDataApi from "Hooks/fetchHook";
import { toast } from "react-toastify";
import { EndpointPrefix } from "Models/UserModels";

const Printers: FC = () => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const [showDrawer, setShowDrawer] = useState<any>(false);
	const [chosenPrinter, setChosenPrinter] = useState<any>(undefined);
	const [showPrinterEditModal, setShowPrinterEditModal] = useState(undefined);
	const [printers, setPrinters] = useState([]);

	const useGetPrinters = useDataApi();

	usePageTitle(t("Printers"));

	useEffect(() => {
		fetchPrinters();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetPrinters;
		if (data && data.message) {
			setPrinters(data.message.printers);
		}
	}, [useGetPrinters.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrinters;
		if (error) {
			toast.error(t("Unable to get Printers list."));
		}
	}, [useGetPrinters.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Align heading and columns in table START ************************ */

	const headerRef = useRef<any>(null);

	const [listWidth, setListWidth] = useState<any>(0);

	const resizeObserver = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;

			setListWidth(width);
		})
	);

	const [scrollPadding, setScrollPadding] = useState(0);

	const ordersListRef = useRef(null) as any;

	useEffect(() => {
		if (ordersListRef.current) {
			resizeObserver.current.observe(ordersListRef.current);
		}
		let el = ordersListRef.current;
		let obs = resizeObserver.current;
		return () => {
			if (el) {
				obs.unobserve(el);
			}
		};
	}, [ordersListRef, printers]);

	useEffect(() => {
		let scrollPadding = headerRef.current?.offsetWidth + 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
	}, [listWidth]);

	/* ******************** Align heading and columns in table END ************************ */

	useEffect(() => {
		let scrollPadding = headerRef.current?.offsetWidth + 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
	}, [listWidth]);

	const fetchPrinters = () => {
		useGetPrinters.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printersList`
		);
	};

	return (
		<>
			<div className="orders-main__content" data-testid="ordersScreen">
				<div className="orders-container">
					<header data-testid="ordersHeader" className="orders-header">
						<div className="row">
							<div className="col-sm-4">
								<h1 className="section__title">{t("Printers")}</h1>
							</div>
						</div>
						<div className="toolbarAndFiltersContainer"></div>
					</header>
					{/* Refresh printers */}
					<div className="orders-action mr--md mb--md left">
						<div
							className="pill pill--outline-quiet pill--toolbar"
							onClick={() => fetchPrinters()}
						>
							<div className="toolbarIconContainer">
								<Icon name="refresh" className="toolbarIcon" />
							</div>
						</div>
					</div>

					{useGetPrinters.isLoading && (
						<div style={{ height: "100%" }} className="flex-center-both-axis">
							<Loading
								show={true}
								text={t("Loading...")}
								imgClass=""
								divClass=""
							/>
						</div>
					)}

					<div className="flex flex-1 pos-relative">
						{!useGetPrinters.isLoading && printers && printers.length > 0 && (
							<div className="orders-list">
								<section
									className={`listing-header ${"listing-header--printers"}`}
									style={{
										paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
									}}
								>
									<span className="listing-header__label">
										{t("Printer name")}
									</span>

									<span className="listing-header__label ">
										{t("Printing type")}
									</span>
									<span className="listing-header__label ">
										{t("Paper info")}
									</span>
									<span className="listing-header__label ">
										{t("Ribbon info")}
									</span>
									<span className="listing-header__label ">
										{t("Edit printer")}
									</span>
									<span className="listing-header__label ordersStatusListItem">
										{t("Status")}
									</span>
								</section>
								<section className="overflow-y-auto flex-1">
									{printers.map((printer: any, index: number) => {
										return (
											<div
												key={printer.id}
												style={{ height: "105px" }}
												data-testid={`orders-row-${index}`}
											>
												<PrintersListItem
													printer={printer}
													setShowDrawer={setShowDrawer}
													setChosenPrinter={setChosenPrinter}
												/>
											</div>
										);
									})}
								</section>
							</div>
						)}

						{!useGetPrinters.isLoading && printers && printers.length === 0 && (
							<div className="emptyOrdersContainer flex-1">
								<div className="box box--sm box--bordered" id="emptyOrders">
									<h1 className="text--quiet">{t("No Printers")}</h1>
									<Icon name="printer" className="noOrdersIcon" />
									<div
										className="txtc text-sm text--quiet mt--base"
										style={{ whiteSpace: "pre-line" }}
									>
										{t("You have no Printers at the moment.")}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<Drawer
				showDrawer={showDrawer}
				orientation="left"
				navigation
				isModalActive={showPrinterEditModal}
				setShowDrawer={setShowDrawer}
			>
				<PrintersListInfo
					chosenPrinter={chosenPrinter}
					setShowPrinterEditModal={setShowPrinterEditModal}
					fetchPrinters={fetchPrinters}
					setShowDrawer={setShowDrawer}
				/>
			</Drawer>
			{showPrinterEditModal && (
				<PrinterEditModal
					showPrinterEditModal={showPrinterEditModal}
					setShowPrinterEditModal={setShowPrinterEditModal}
					setChosenPrinter={setChosenPrinter}
				/>
			)}
		</>
	);
};

export default Printers;
