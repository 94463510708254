import React, { FC } from "react";

const LegacyBrowsersErrors: FC<{}> = () => {
	return (
		<>
			<div className="modal flex-center-both-axis">
				<div
					className="modal-content-order-summary show flex-center-both-axis"
					style={{ height: "140px" }}
				>
					<div>
						You are using an older, unsupported version of your browser. Please
						update or switch to a newer version.
					</div>
				</div>
			</div>
		</>
	);
};

export default LegacyBrowsersErrors;
