import React from "react";

import apiIntegration from "./images/api-integration.png";
import usePageTitle from "Hooks/pageTitleHook";

const APIIntegrationHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - API Integration`);

	return (
		<>
			<h2 className="helpTitle">API Integration</h2>
			<article className="helpArticle">
				<p>
					Using the API completely removes unnecessary typing, spelling errors
					and logical errors thus speeding up the whole process. All the
					information needed for printing care labels are transferred directly
					from your internal system to Cacotec. The only remaining thing for you
					to do is to check the layout and place the order.
				</p>
				<p>
					Orders added via API will be added by a special user, so you can
					easily recognize them:
				</p>
				<img
					src={apiIntegration}
					className="helpImageSmall"
					alt="API Integration"
				/>
			</article>
		</>
	);
};

export default APIIntegrationHelp;
