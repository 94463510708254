import React from "react";

import sprite from "images/icons.svg";

interface IProps {
	password: string;
	showPassword: boolean;
	minLength?: number;
	passwordLabel: string;

	setPassword: (password: string) => void;
	setShowPassword: (showPassword: boolean) => void;
}

const PasswordInput: React.FunctionComponent<IProps> = ({
	password,
	showPassword,
	minLength,
	passwordLabel,
	setShowPassword,
	setPassword,
}) => {
	const onSetPassword = (e: any) => {
		if (!e.target.value) {
			setShowPassword(false);
		}
		setPassword(e.target.value);
	};
	return (
		<div className="form-group">
			<input
				className="form-control"
				type={showPassword ? "text" : "password"}
				id={passwordLabel}
				name={passwordLabel}
				minLength={minLength}
				value={password}
				onChange={onSetPassword}
				data-empty={!password}
				style={{ paddingRight: "28px" }}
			/>
			<label className="form-control-label" htmlFor={passwordLabel}>
				{passwordLabel}
			</label>
			{password.length > 0 && (
				<svg
					className="flex-center-secondary-axis pos-absolute hide-show-password"
					onClick={() => setShowPassword(!showPassword)}
					role="button"
				>
					<use
						xlinkHref={`${sprite}#icon-${
							showPassword ? "eye-hide" : "eye-view"
						}`}
					/>
				</svg>
			)}
		</div>
	);
};

export default PasswordInput;
