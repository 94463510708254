import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import useDataApi from "Hooks/fetchHook";
import Loading from "../Shared/Loading";
import { EndpointPrefix } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import Address from "../Shared/Address";
import { IAddress } from "Models/OrderModels";
import Icon from "../Shared/Icon";
import { useFetchShippingDetails } from "Hooks/queryHooks/useFetchShippingDetails";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";

const SettingsAddresses: React.FunctionComponent = () => {
	const { t } = useTranslation();
	const {
		user: { account_type_id },
		setError,
	} = useContext(GlobalDispatch);

	const useDeleteAddress = useDataApi();
	const useSetAsDefaultAddress = useDataApi();

	const [addresses, setAddresses] = useState([] as IAddress[]);
	const [showAddressForm, setShowAddressForm] = useState(false);
	const [addressIndex, setAddressIndex] = useState(0) as any;

	const { shippingDetails, isLoadingShippingDetails: isLoadingAddresses } =
		useFetchShippingDetails(account_type_id, setError);

	/* ******************** Delete Address API call ************************ */
	useEffect(() => {
		const { error } = useDeleteAddress;
		if (error) {
			toast.error(t(`Unable to delete address. ${error}`));
		}
	}, [useDeleteAddress.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useDeleteAddress;
		if (data.status === "ok") {
			toast.success(t("Address deleted successfully."));
			if (addressIndex !== undefined) {
				const copyOfAddresses = [...addresses];
				copyOfAddresses.splice(addressIndex, 1);
				setAddresses(copyOfAddresses);
			}
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.SHIPPING_DETAILS, account_type_id],
			});
		}
	}, [useDeleteAddress.data]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	/* ******************** Set As Default Address API call ************************ */
	useEffect(() => {
		const { error } = useSetAsDefaultAddress;
		if (error) {
			toast.error(t(`Unable to set as default address. ${error}`));
		}
	}, [useSetAsDefaultAddress.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useSetAsDefaultAddress;
		if (data.status === "ok") {
			if (addressIndex !== undefined) {
				const copyOfAddresses = [...addresses].map(
					(address: IAddress, index: number) =>
						addressIndex === index
							? { ...address, default: true }
							: { ...address, default: false }
				);
				// Move default address on top
				const defaultAddress = copyOfAddresses[addressIndex];
				copyOfAddresses.splice(addressIndex, 1);
				copyOfAddresses.splice(0, 0, defaultAddress);
				setAddresses(copyOfAddresses);
			}
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.SHIPPING_DETAILS, account_type_id],
			});
		}
	}, [useSetAsDefaultAddress.data]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	useEffect(() => {
		setAddresses(shippingDetails);
	}, [shippingDetails]); // eslint-disable-line react-hooks/exhaustive-deps

	const onEditAddress = (index?: number) => {
		setShowAddressForm(true);
		setAddressIndex(index);
	};

	const onDeleteAddress = (index: number) => {
		if (window.confirm(t("Are you sure you want to delete this address?"))) {
			const copyOfAddresses = [...addresses];
			copyOfAddresses.splice(index, 1);
			setAddressIndex(index);
			useDeleteAddress.doFetch(
				`/${EndpointPrefix[account_type_id]}/data/addresses`,
				{ addresses: copyOfAddresses },
				"POST"
			);
		}
	};

	const onSetAsDefault = (index: number) => {
		setAddressIndex(index);
		const copyOfAddresses = [...addresses].map(
			(address: any, addressIndex: number) =>
				addressIndex === index
					? { ...address, default: true }
					: { ...address, default: false }
		);
		// Move default address on top
		const defaultAddress = copyOfAddresses[index];
		copyOfAddresses.splice(index, 1);
		copyOfAddresses.splice(0, 0, defaultAddress);
		useSetAsDefaultAddress.doFetch(
			`/${EndpointPrefix[account_type_id]}/data/addresses`,
			{ addresses: copyOfAddresses },
			"POST"
		);
	};

	return (
		<div
			className="tab-pane fade in active show settingsTab"
			role="tabpanel"
			id="tab-general"
			aria-labelledby="tab-toggle-general"
		>
			{isLoadingAddresses && (
				<div style={{ height: "70vh" }}>
					<Loading
						show={isLoadingAddresses}
						text={t("Loading...")}
						imgClass="block-center"
						divClass="col-sm-12"
					/>
				</div>
			)}

			{!isLoadingAddresses && (
				<>
					<div
						className="button button-xs"
						id="addNewAddressButton"
						data-toggle="drawer"
						style={{ marginBottom: "10px", width: "180px" }}
						onClick={() => onEditAddress(undefined)}
					>
						<div className="blueIcon">
							<Icon className="icon mr--xs valign-middle" name="plus-rounded" />
						</div>
						<span className="strong">{t("Add New Address")}</span>
					</div>

					<fieldset className="box mb--md box--address">
						{addresses?.map((address: IAddress, index: number) => {
							const {
								company_name,
								full_name,
								phone_number,
								line1,
								line2,
								city,
								state,
								postal_code,
								country,
							} = address;
							return (
								<div
									key={`${full_name}-${index}`}
									style={{ width: "100%" }}
									data-testid={`${company_name}`}
								>
									<div
										className="list-item list-item--address"
										style={
											useDeleteAddress.isLoading && addressIndex === index
												? { justifyContent: "center" }
												: { justifyContent: "space-between" }
										}
									>
										<Loading
											show={
												useDeleteAddress.isLoading && addressIndex === index
											}
											text={t("Loading...")}
											imgClass="imgLoading"
											divClass=""
										/>

										{(!useDeleteAddress.isLoading ||
											addressIndex !== index) && (
											<>
												<div className="content--details-address">
													<div>
														<div>
															{company_name || ""}
															{address?.default && (
																<div
																	data-testid={`${company_name}-default`}
																	className="pill pill--info pill--sm"
																	style={{ marginLeft: "10px" }}
																>
																	{t("default")}
																</div>
															)}
														</div>
														<div>{full_name}</div>
														{phone_number && (
															<div>
																{t("Phone")}: {phone_number}
															</div>
														)}
														<div>{line1}</div>
														{line2 && <div>{line2}</div>}
														<div>
															{city}
															{state ? `, ${state}` : ""}, {postal_code}
														</div>
														<div>{country}</div>
													</div>
												</div>

												<div className="list-item--address-buttons">
													{!address.default &&
														(!useSetAsDefaultAddress.isLoading ||
															addressIndex !== index) && (
															<div
																data-testid={`${company_name}-set-as-default`}
																className="button"
																onClick={() => onSetAsDefault(index)}
																style={{ height: "50px" }}
															>
																<Loading
																	show={
																		useSetAsDefaultAddress.isLoading &&
																		addressIndex === index
																	}
																	text={t("Loading...")}
																	imgClass="img--loading-small"
																	divClass=""
																/>
																{t("Set as default")}
															</div>
														)}

													<div
														className="button"
														data-testid={`${company_name}-edit`}
														onClick={() => onEditAddress(index)}
													>
														{t("Edit")}
													</div>

													<div
														className="button link--danger"
														data-testid={`${company_name}-delete`}
														onClick={() => onDeleteAddress(index)}
													>
														{t("Delete")}
													</div>
												</div>
											</>
										)}
									</div>
								</div>
							);
						})}
					</fieldset>
				</>
			)}

			{showAddressForm && (
				<Address
					showAddressForm={showAddressForm}
					onCloseModal={() => setShowAddressForm(false)}
					addressIndex={addressIndex}
					addresses={addresses}
					setAddresses={setAddresses}
				/>
			)}
		</div>
	);
};

export default SettingsAddresses;
