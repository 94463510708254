import React, { useRef, useEffect, useState, useCallback } from "react";

import useOnClickOutside from "Hooks/outsideClickHook";

interface IProps {
	focusOnSearchList: boolean;
	testId: string;

	onChooseItemCallback: (item: any) => void;

	incrementalSearchHookProps: any;
}

const IncrementalSearchResults: React.FunctionComponent<IProps> = ({
	focusOnSearchList,
	testId,

	onChooseItemCallback,

	incrementalSearchHookProps,
}) => {
	const { filteredList, showList, setShowList } = incrementalSearchHookProps;
	const listRef = useRef(null) as any;
	const [selectedItem, setSelectedItem] = useState(0);

	useOnClickOutside(
		listRef,
		useCallback(() => setShowList(false), [setShowList])
	);

	useEffect(() => {
		if (focusOnSearchList) {
			listRef?.current?.firstElementChild?.focus();
			setSelectedItem(0);
		}
	}, [focusOnSearchList, listRef]);

	const onKeyDown = (e: any) => {
		e.preventDefault();
		const items = listRef.current.children;
		let focusedElement = items[0];

		if (e.key === "ArrowDown") {
			if (selectedItem < items.length - 1) {
				setSelectedItem(selectedItem + 1);
				focusedElement = items[selectedItem + 1];
			} else if (selectedItem === items.length - 1) {
				setSelectedItem(0);
				focusedElement = items[0];
			}
		} else if (e.key === "ArrowUp") {
			if (selectedItem > 0) {
				setSelectedItem(selectedItem - 1);
				focusedElement = items[selectedItem - 1];
			}
		}

		if (e.key === "Enter") {
			focusedElement = e.target;
			onChooseItemCallback({
				id: focusedElement.value,
				name: focusedElement.textContent,
			});
		}

		if (focusedElement) {
			focusedElement.focus();
		}
	};

	return (
		<>
			{showList && (
				<ul
					style={{ width: "100%" }}
					className="dropdown-menu dropdown-menu show"
					id={
						testId === "fabricAndCare-fibers"
							? "fibersDropdown"
							: "incrementalSearchResults"
					}
					ref={listRef}
					onKeyDown={(e) => onKeyDown(e)}
				>
					{filteredList.map((item: any, index: number) => (
						<li
							value={item.id}
							tabIndex={-1}
							key={item.id}
							className="dropdown-menu__item"
							role="button"
							onClick={() => onChooseItemCallback(item)}
							data-testid={`${testId}-incrementalSearchResults-${index}`}
						>
							{item.name}
						</li>
					))}
				</ul>
			)}
		</>
	);
};

export default IncrementalSearchResults;
