import React from "react";
import { Link } from "react-router-dom-v5-compat";

import orderActions from "./images/order-actions.png";
import orderActions2 from "./images/order-actions-2.png";
import previewOrderButton from "./images/preview-order-button.png";
import orderPreview from "./images/order-preview.png";
import orderActionsAdditional from "./images/order-actions-additonal.png";
import usePageTitle from "Hooks/pageTitleHook";

const OrderActionsHelp: React.FunctionComponent = () => {
	usePageTitle(`Cacotec Help Articles - Order actions`);

	return (
		<>
			<h2 className="helpTitle">Order actions</h2>
			<article className="helpArticle">
				<p>
					At the top of the order form, you will see actions that can be done on
					the order.
				</p>
				<img src={orderActions} className="helpImage" alt="Order actions" />
				<img src={orderActions2} className="helpImage" alt="Order actions 2" />
				<p>
					Most commonly used actions have shortcuts - <b>Save order</b> and{" "}
					<b>Place order/Request Approval</b>. If you have a permission to place
					an order, you will see the Place Order action, otherwise you will see
					the Request Approval button.
				</p>
				<p>
					There are some mandatory fields that you will have to populate before
					you are able to save or place the order. You will get a notification
					with more information if you try to perform an action on an order with
					some information missing.
				</p>

				<p>
					Other actions can be accessed by clicking on the … menu to the right:
				</p>
				<img
					src={orderActionsAdditional}
					className="helpImage"
					alt="Additional order actions"
				/>

				<p className="helpSubtitle">Copy order</p>
				<p>
					Sometimes you will need to create a copy of the order before it's
					placed (if you want to do that for the order that has already been
					placed, you can use{" "}
					<Link className="link" to="/help/articles/reordering">
						reorder
					</Link>
					). Use the <b>Copy</b> option to do this.
				</p>
				<p>
					<b>Note:</b> Copying an order does not preserve the original note,
					instead the duplicate will have a note in the form of Copy of XXXXXX
					where XXXXXX is the referenece number of the original.
				</p>

				<p className="helpSubtitle">Cancel order</p>
				<p>
					If you made a mistake that can't be corrected or you don't need an
					order anymore, you can use the
					<b> Cancel</b> option. You will still be able to see it in the orders
					list, you can use reorder option on it, but it won't be processed.
				</p>
				<p>
					<b>Note: </b>Canceling an order cannot be undone.
				</p>

				<p className="helpSubtitle">Preview order</p>

				<p>
					If you want to take a look at the generated layouts, click on the
					Preview button to the right:
				</p>
				<img
					src={previewOrderButton}
					className="helpImage"
					alt="Preview order button"
				/>
				<p>
					When clicked, it will open the preview window, with sumarized
					information and generated layout for the currently selected style. If
					you want to preview the whole order, click on the{" "}
					<b>Preview all styles</b> button.
				</p>
				<img
					src={orderPreview}
					className="helpImageSmall"
					alt="Preview order"
				/>
			</article>
		</>
	);
};

export default OrderActionsHelp;
